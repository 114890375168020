/**
 * @prettier
 * @flow
 */

import { ListItem as SUIRListItem } from 'semantic-ui-react';
import { Image, Popup, Icon } from 'liana-ui/components/';
import { VAlign } from 'liana-ui/types';
import ListList from './ListList';
import ListIcon from './ListIcon';
import ListContent from './ListContent';
import ListHeader from './ListHeader';
import ListDescription from './ListDescription';
import ListLink from './ListLink';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/list/ */
component ListItem(
	/** Transition support. */
	className?: string,
	/** Add item content. */
	content?: React.Node,
	/** Add item content floated to the right. */
	floatedContent?: React.Node,
	/** Item can have a header as text */
	header?: React.Node,
	/** Name or props object for Icon. */
	icon?: string | React.PropsOf<Icon>,
	/** Name or props object for Image. */
	image?: string | React.PropsOf<Image>,
	/** Item can be a link. Opens absolute links in new browser tab and internal links via router. */
	link?: string,
	/** Item link can have a target. Default _blank. */
	linkTarget?: string,
	/** Array of props for ListList. */
	items?: Array<React.PropsOf<ListItem>>,
	/** Props object for Popup */
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'ListItem',
	/** List item can be clicked. */
	onClick?: () => void
) {
	let itemImage = typeof image === 'string' ? { src: image } : image,
		itemIcon = typeof icon === 'string' ? { name: icon } : icon;

	let item = (
		<SUIRListItem className={className} data-testid={testID}>
			{floatedContent ? <ListContent floated='right'>{floatedContent}</ListContent> : null}
			{itemImage ? <Image avatar {...itemImage} /> : null}
			{itemIcon ? (
				<ListIcon verticalAlign={header && content ? VAlign.Middle : VAlign.Top} {...itemIcon} />
			) : null}
			{icon || image || header ? (
				<ListContent>
					{header ? (
						<ListHeader link={link} onClick={onClick ? onClick : undefined} popup={popup}>
							{header}
						</ListHeader>
					) : null}
					{header && content ? (
						<ListDescription>{content}</ListDescription>
					) : link || onClick ? (
						<ListLink link={link} linkTarget={linkTarget} onClick={onClick} popup={popup}>
							{content}
						</ListLink>
					) : (
						content
					)}
				</ListContent>
			) : link || onClick ? (
				<ListLink link={link} linkTarget={linkTarget} onClick={onClick} popup={popup}>
					{content}
				</ListLink>
			) : (
				content
			)}
			{/* $FlowIssue - Flow doesn't know what it wants */}
			{items ? <ListList items={items} /> : null}
		</SUIRListItem>
	);

	if (!link && !onClick) {
		// $FlowIssue - React statics; Attach popup
		item = Popup.attach(popup, item);
	}
	return item;
}

export default (React.memo(ListItem): React.AbstractComponent<React.PropsOf<ListItem>, mixed>);
