/**
 * @prettier
 * @flow
 */

import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Message } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/modal/ */
component SignedOutModal(
	/** Controls whether or not the Modal is displayed. Open when user is signed out and close when user is signed in again. */
	open?: boolean,
	/** Component must have a sign in url. */
	signinLink: string,
	/** Test ID for testing */
	testID: string = 'SignedOutModal',
	/** Function called when browser tab/window receives focus. */
	onFocus?: () => void,
	/** Function called when browser tab/window loses focus. */
	onBlur?: () => void
) {
	const handleBlur = () => {
		if (typeof onBlur === 'function') {
			onBlur();
		}
	};

	const handleFocus = () => {
		if (typeof onFocus === 'function') {
			onFocus();
		}
	};

	const handleVisibilityChange = () => {
		if (document.hidden === true) {
			handleBlur();
		} else if (document.hidden === false) {
			handleFocus();
		}
	};

	const addListeners = () => {
		document.addEventListener('visibilitychange', handleVisibilityChange);
		window.addEventListener('blur', handleBlur);
		window.addEventListener('focus', handleFocus);
	};

	const removeListeners = () => {
		document.removeEventListener('visibilitychange', handleVisibilityChange);
		window.removeEventListener('blur', handleBlur);
		window.removeEventListener('focus', handleFocus);
	};

	// Mount
	useEffect(() => {
		if (open) {
			addListeners();
		} else {
			removeListeners();
		}
	}, [open]);

	// Unmount
	useEffect(() => {
		return () => {
			removeListeners();
		};
	}, []);

	return (
		<Modal
			open={open}
			size={Size.Tiny}
			header={<FormattedMessage id='component.signedout-modal.modalHeader' />}
			testID={testID}
			content={
				<Message
					icon='fa-lock-keyhole'
					header={<FormattedMessage id='component.signedout-modal.messageHeader' />}
					content={<FormattedMessage id='component.signedout-modal.messageContent' />}
					warning
					layout='big'
					vertical
				/>
			}
			actions={[
				<Button
					key='signin'
					text={<FormattedMessage id='component.signedout-modal.signIn' />}
					color='primary'
					icon='fa-right-to-bracket'
					link={signinLink}
				/>
			]}
			closeIcon={false}
			closeOnDimmerClick={false}
		/>
	);
}

export default (React.memo(SignedOutModal): React.AbstractComponent<React.PropsOf<SignedOutModal>, mixed>);
