/**
 * @prettier
 * @flow
 */

import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Segment, Header, Image, Icon, Button, Label, Checkbox, Text, GridColumn } from 'liana-ui/components/';
import { Size } from 'liana-ui/types/';

type Item = {
	key?: React.Key,
	image?: string,
	icon: string,
	header?: React.Node,
	content?: React.Node,
	button?: React.PropsOf<Button>,
	checkbox: React.PropsOf<Checkbox>,
	selected?: boolean,
	testID?: string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message/ */
component SelectMenu(
	/** A select menu must have multiple items. */
	items: Array<Item>,
	/** A grid can preserve its vertical and horizontal gutters on first and last columns. */
	padded?: boolean | 'horizontally' | 'vertically',
	/** A grid can increase its gutters to allow for more negative space. */
	relaxed?: boolean | 'very',
	/**
		Represents column count per row in Grid.
		VALUES['equal', 1 - 12]
	*/
	columns?: string | number,
	/**
		A column can specify a width for a mobile device.
		VALUES[1 - 12]
	*/
	mobile?: number = 12,
	/**
		A column can specify a width for a tablet device.
		VALUES[1 - 12]
	*/
	tablet?: number = 12,
	/**
		A column can specify a width for a computer.
		VALUES[1 - 12]
	*/
	computer?: number = 4,
	/**
		A column can specify a width for a large screen device.
		VALUES[1 - 12]
	*/
	largescreen?: number = 4,
	/**
		A column can specify a width for a wide screen device.
		VALUES[1 - 12]
	*/
	widescreen?: number = 4,
	/** A segment can be different size. */
	size?: Size,
	/** Test ID for testing */
	testID: string = 'SelectMenu'
) {
	const checkboxRef = useRef<HTMLDivElement | null>(null);

	const handleCheckboxClick = (event: SyntheticEvent<>, item: any) => {
		// Required for Form onChange to trigger
		checkboxRef.current?.querySelector('input')?.dispatchEvent(new Event('change', { bubbles: true }));

		// Trigger onClick callback funtion
		if (typeof item.checkbox.onClick === 'function') {
			item.checkbox.onClick(event, {
				name: item.checkbox.name,
				value: item.checkbox.value,
				selected: !item.selected
			});
		}
	};

	const getImageSize = (size?: Size) => {
		if (!size) {
			return Size.Small;
		}
		switch (size) {
			case Size.Small:
				return Size.Tiny;
			case Size.Tiny:
				return Size.Mini;
			default:
				return Size.Small;
		}
	};

	const getIconSize = (size?: Size) => {
		if (!size) {
			return Size.Massive;
		}
		switch (size) {
			case Size.Small:
				return Size.Huge;
			default:
				return Size.Massive;
		}
	};

	const getItem = (item: Item, index: number) => {
		return (
			<Segment
				className='select-menu-item text-center'
				raised
				compressed
				selected={item.selected}
				onClick={item.checkbox ? (event) => handleCheckboxClick(event, item) : undefined}
				testID={item.testID || `SelectMenu::Item::${index}`}
			>
				{item.button && item.selected ? <Label corner='right' icon='check' color='green' size={size} /> : null}
				{item.checkbox ? (
					<div className='select-menu-item-checkbox' ref={checkboxRef}>
						<Checkbox {...item.checkbox} checked={item.selected} fitted readOnly />
					</div>
				) : null}
				<div className='select-menu-item-container'>
					<div className='select-menu-item-content'>
						{typeof item.image === 'string' ? (
							<Image size={getImageSize(size)} src={item.image} circular />
						) : typeof item.icon === 'string' ? (
							<Icon color='green' size={getIconSize(size)} name={item.icon} circular />
						) : null}
						{item.header ? <Header as='h2' size={size} text={item.header} /> : null}
						{item.content ? (
							<Text as='p' size={size}>
								{item.content}
							</Text>
						) : null}
					</div>
				</div>
				{item.button ? (
					<div className='select-menu-item-button'>
						<Button
							{...item.button}
							text={
								item.button.text ? (
									item.button.text
								) : item.selected ? (
									<FormattedMessage id='actions.selected' />
								) : (
									<FormattedMessage id='actions.select' />
								)
							}
							fitted
							size={size}
							fluid
							color={!item.selected ? 'primary' : undefined}
							disabled={item.selected}
						/>
					</div>
				) : null}
			</Segment>
		);
	};

	return (
		<div className='select-menu-wrapper' data-testid={testID}>
			<Grid stretched padded={padded} relaxed={relaxed} columns={columns} stackable={columns !== undefined}>
				{items && Array.isArray(items)
					? items.map((item, index) => (
							<GridColumn
								key={`emptyState{index}`}
								mobile={!columns ? mobile : undefined}
								tablet={!columns ? tablet : undefined}
								computer={!columns ? computer : undefined}
								largescreen={!columns ? largescreen : undefined}
								widescreen={!columns ? widescreen : undefined}
							>
								{getItem(item, index)}
							</GridColumn>
						))
					: null}
			</Grid>
		</div>
	);
}

export default (React.memo(SelectMenu): React.AbstractComponent<React.PropsOf<SelectMenu>, mixed>);
