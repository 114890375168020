/**
 * @prettier
 * @flow
 */

import { TransitionGroup as SUIRTransitionGroup } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/transition/#types-group */
component TransitionGroup(
	/** Primary content. */
	children: React.Node,
	/** An element type to render as (string or function). */
	as: any,
	/** Named animation event to used. Must be defined in CSS. Default: 'fade' */
	animation?:
		| 'fade'
		| 'fade up'
		| 'fade down'
		| 'fly right'
		| 'scale'
		| 'slide up'
		| 'slide down'
		| 'pulse'
		| 'glow' = 'fade',
	/** Duration of the CSS transition animation in milliseconds. Default: 500 */
	duration?: number = 500
) {
	return (
		<SUIRTransitionGroup as={as} animation={animation} duration={duration}>
			{children}
		</SUIRTransitionGroup>
	);
}

export default (React.memo(TransitionGroup): React.AbstractComponent<React.PropsOf<TransitionGroup>, mixed>);
