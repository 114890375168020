/**
 * @prettier
 * @flow
 */

import { ModalActions as SUIRModalActions } from 'semantic-ui-react';

component ModalActions(children: React.Node, testID: string = 'ModalActions') {
	return <SUIRModalActions data-testid={testID}>{children}</SUIRModalActions>;
}

export default (React.memo(ModalActions): React.AbstractComponent<React.PropsOf<ModalActions>, mixed>);
