/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Dimmer as SUIRDimmer } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dimmer/ */
component Dimmer(
	/** Content as children. */
	children: React.Node,
	/** A loader can be active or visible. */
	active?: boolean,
	/** A dimmer can be formatted to be fixed to the page. */
	page?: boolean,
	/** Dimmers can have their colors inverted. */
	inverted?: boolean,
	/** Test ID used for testing. */
	testID: string = 'Dimmer'
) {
	return !page ? (
		<SUIRDimmer active={active} page={page} inverted={inverted} data-testid={testID}>
			{children}
		</SUIRDimmer>
	) : (
		// Dimmer 'page' breaks LianaUI Modals for some reason. Probably because Modals are legacy and not compatible with new SUI-R compoments.
		// When Modals are refactored to use SUI-R try to use Dimmer component only here
		<div
			className={classNames('ui dimmer', {
				active: active,
				page: page
			})}
			data-testid={testID}
		>
			<div className='content'>{children}</div>
		</div>
	);
}

export default (React.memo(Dimmer): React.AbstractComponent<React.PropsOf<Dimmer>, mixed>);
