/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Validate } from 'liana-ui/definitions';
import { VAlign, Size, Float, Target, Device, Spacing, HAlign } from 'liana-ui/types';
import {
	Text,
	Image,
	Button,
	ContextMenu,
	List,
	Checkbox,
	Label,
	LabelGroup,
	Header,
	Table,
	Segment,
	NavLink,
	Item,
	ButtonGroup,
	LimitLabel,
	Responsive
} from 'liana-ui/components/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/item/#types-items */
component ItemGroup(
	/** An item group must have card items. */
	items?: Array<React.PropsOf<Item>>,
	/** Mapped over any data provided for items property. Should return Card object for items. */
	renderItem?: (data: any, index: number) => React.PropsOf<Item>,
	/** An item group can have different layouts. */
	layout?: 'small' | 'big' = 'big',
	/** An item group can vertically align items. */
	verticalAlign?: VAlign = VAlign.Middle,
	/** 
	  	An item group limit header to maximum amount of lines followed by ellipsis. 
	 	VALUES[1 - 10]
	*/
	limitHeader?: number,
	/** 
	    An item group limit description to maximum amount of lines followed by ellipsis. 
		VALUES[1 - 10]			
	*/
	limitDescription?: number,
	/** An item group can have different sizes. */
	size?: Size,
	/** An item group can be more fitted by removing left and right paddings */
	fitted?: boolean,
	/** Test ID for testing */
	testID: string = 'ItemGroup',
	/** Function called on item select. */
	onSelect?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			checked: boolean
		}
	) => void,
	/** Callback on when delete animation ends. */
	onAfterDeleteItems?: () => void
) {
	const navigate = useNavigate();

	// Factory function for transforming data
	let renderItems: Array<React.PropsOf<Item>> = [];

	if (items && items.length > 0) {
		if (typeof renderItem === 'function') {
			for (let i = 0; i < items.length; i++) {
				renderItems[i] = renderItem(items[i], i);
			}
		} else {
			renderItems = items;
		}
	}

	// Check if item has image or exta actios/item columns
	const hasImage = renderItems.filter((data: any) => data.image).length > 0,
		hasExtra =
			renderItems.filter((data: any) => data.actions || data.button || data.buttonGroup || data.labels).length >
			0;

	const handleContainerClick = (event: SyntheticEvent<>, item: React.PropsOf<Item>) => {
		// Trigger onClick callback funtion
		if (typeof item.onClick === 'function') {
			item.onClick(event);
		}

		// Get link type
		let itemLinkType = Validate.linkType(item.itemLink);

		// Trigger internal link
		if (itemLinkType && (itemLinkType === 'internal' || itemLinkType === 'anchor')) {
			event.preventDefault();
			if (itemLinkType === 'internal') {
				navigate(item.itemLink);
			}
			if (itemLinkType === 'anchor') {
				Safely.scroll(item.itemLink, () => {
					navigate(`${window.location.pathname}${item.itemLink || ''}`);
				});
			}
		}
	};

	let getExtra = (item: React.PropsOf<Item>) => {
		return (
			<div className='item-extra-wrapper'>
				<div className='item-actions'>{getActions(item)}</div>
				<div className='item-labels'>
					<LabelGroup size={Size.Tiny} labels={item.labels} />
				</div>
				<div className='item-buttons'>{getButtons(item)}</div>
			</div>
		);
	};

	let getmetaLabels = (item: React.PropsOf<Item>) => {
		let metaLabels: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>> = [];
		if (item.new) {
			metaLabels.push({ text: <FormattedMessage id='component.label.new' />, notification: true });
		}
		if (item.metaLabels) {
			metaLabels = metaLabels.concat(item.metaLabels);
		}
		return <LabelGroup size={Size.Tiny} labels={metaLabels} />;
	};

	let getButtons = (item: React.PropsOf<Item>) => {
		return (
			<>
				{item.button ? <Button {...item.button} size={Size.Mini} fitted /> : null}
				{item.buttonGroup ? <ButtonGroup {...item.buttonGroup} size={Size.Mini} fitted /> : null}
			</>
		);
	};

	const getActions = (item: React.PropsOf<Item>) => {
		let actions = [],
			button;
		if (Array.isArray(item.actions)) {
			for (let i = 0; i < item.actions.length; i++) {
				if (item.actions[i].button) {
					button = { ...item.actions[i].button };

					if (typeof button.popup === 'string' || React.isValidElement(button.popup)) {
						// $FlowIssue - Object vs. React.Node
						button.popup = {
							text: button.popup,
							delay: 500
						};
					}
					actions.push(
						<div className='column' key={`button-column-${i}`}>
							<Button {...button} basic fitted size={Size.Tiny} noWrap />
						</div>
					);
				} else if (item.actions[i].contextMenu) {
					actions.push(
						<div className='column'>
							<ContextMenu
								{...item.actions[i].contextMenu}
								fitted
								basic
								size={Size.Tiny}
								direction={Float.Left}
							/>
						</div>
					);
				}
			}
		}
		return actions.length > 0 ? (
			<div
				className={classNames('item-actions-wrapper', {
					one: actions.length === 1,
					two: actions.length === 2,
					three: actions.length === 3,
					four: actions.length === 4,
					five: actions.length === 5
				})}
			>
				{actions}
			</div>
		) : null;
	};

	const getHederWidth = (hasImage: boolean, hasExtra: boolean) => {
		return document.querySelector('html')?.classList.contains('tablet') || !hasImage || !hasExtra ? 4 : 3;
	};

	const getContent = (item: React.PropsOf<Item>, isParent: boolean = false, isChild: boolean = false): Array<any> => {
		let headerLinkType = Validate.linkType(item.headerLink);

		let rows: any = [],
			cells: any = [];

		// Assign classes
		let classes = classNames('item', {
			'link-item': item.itemLink,
			viewed: item.viewed
		});

		// Assign image classes
		let imageClasses = classNames('ui image image-wrapper', {
			'image-ratio': typeof item.image === 'object' && item?.image?.ratio,
			tiny: layout === 'big',
			mini: layout === 'small'
		});

		// Count fluid placeholder ratio
		let styles = {};
		if (
			typeof item.image === 'object' &&
			typeof item.image.ratio === 'string' &&
			item.image.ratio.indexOf('/') > -1
		) {
			// $FlowIssue- Already checked above
			let ratio: Array<number> = item.layout === 'big' ? item.image.ratio.split('/').map(Number) : [1, 1];
			styles = {
				paddingTop: (ratio[1] / ratio[0]) * 100 + '%'
			};
		}

		// Select
		if (!isChild && (item.onSelect || onSelect)) {
			cells.push({
				className: 'item-checkbox',
				verticalAlign: item.verticalAlign || verticalAlign,
				collapsing: true,
				content: (
					<Checkbox
						fitted
						checked={item.selected}
						name='item'
						value={item.id}
						/* $FlowIssue[prop-missing] - Why complain about Checbox here? */
						onChange={item.onSelect || onSelect}
					/>
				)
			});
		}

		if ((!isParent && !isChild) || (item.stacked && isChild)) {
			// Image
			if (item.image) {
				cells.push({
					className: 'item-image',
					verticalAlign: item.verticalAlign || verticalAlign,
					collapsing: true,
					minDevice: Device.Computer,
					content: (
						<div className={imageClasses} style={styles}>
							{typeof item.image === 'string' ? (
								<Image size={Size.Tiny} src={item.image} />
							) : (
								<Image size={Size.Tiny} {...item.image} />
							)}
						</div>
					)
				});
			}

			// Header and lebels

			cells.push({
				className: 'item-header',
				width: getHederWidth(hasImage, hasExtra),
				verticalAlign: item.verticalAlign || verticalAlign,
				content: (
					<>
						<Header
							as={size === Size.Small ? 'h4' : 'h3'}
							breakWord
							text={
								!isChild && item.headerLink ? (
									<NavLink
										to={item.headerLink}
										target={headerLinkType === 'external' ? Target.Blank : undefined}
										rel={headerLinkType === 'external' ? 'noopener noreferrer' : undefined}
										onClick={item.onHeaderClick}
									>
										<Text limitLines={item.limitHeader || limitHeader}>{item.header}</Text>
									</NavLink>
								) : (
									<Text limitLines={item.limitHeader || limitHeader}>{item.header}</Text>
								)
							}
						/>
						<List size={Size.Small} horizontal={true} divided={true} items={item.subinfo} />
						{!isChild ? getmetaLabels(item) : null}
					</>
				)
			});

			// Description and content
			if (item.description || item.extraDescription) {
				cells.push({
					className: 'item-description',
					verticalAlign: item.verticalAlign || verticalAlign,
					content: (
						<>
							{item.description ? (
								<Text
									as='p'
									size={item.size || size}
									breakWord
									limitLines={item.limitDescription || limitDescription}
								>
									{item.description}
								</Text>
							) : null}
							{item.extraDescription ? (
								<p className='extra-description'>{item.extraDescription}</p>
							) : null}
						</>
					)
				});
			}

			// Extra actions and labels
			if (!isChild && hasExtra) {
				cells.push({
					className: 'item-extra',
					collapsing: true,
					textAlign: HAlign.Right,
					verticalAlign: item.verticalAlign || verticalAlign,
					content: getExtra(item)
				});
			}
		} else {
			cells.push({
				colSpan: 4,
				className: 'item-stacked',
				verticalAlign: item.verticalAlign || verticalAlign,
				content: (
					<>
						{typeof item.stacked === 'object' ? (
							<div className='stacked-header-container'>
								<Header text={item?.stacked?.header?.text} onClick={item.stacked.onClick} />
								{item?.stacked?.header?.subinfo ? (
									Array.isArray(item?.stacked.header.subinfo) ? (
										// $FlowIssue[incompatible-type] - Impossible to distinguish between React.Node vs. object
										<List size={Size.Tiny} horizontal divided items={item.stacked.header.subinfo} />
									) : (
										<Text size={Size.Medium}>{item.stacked.header.subinfo}</Text>
									)
								) : null}
								{item.new ? (
									<span className='new-label'>
										<Label
											text={
												typeof item.new === 'number' ? (
													<FormattedMessage
														id='component.label.newAmount'
														values={{
															amount: item.new
														}}
													/>
												) : (
													<FormattedMessage id='component.label.new' />
												)
											}
											size={Size.Tiny}
											notification
											fitted
										/>
									</span>
								) : null}
								<LabelGroup as='span' size={Size.Tiny} labels={item.stacked.metaLabels} />
								<Responsive minDevice={Device.Tablet}>
									<div className='item-actions'>{getActions(item)}</div>
								</Responsive>
							</div>
						) : null}
						<Segment
							stacked
							removePaddings={Spacing.All}
							className='stacked-items-container'
							onClick={typeof item.stacked === 'object' ? item?.stacked?.onClick : undefined}
						>
							<Table
								className='items'
								basic={true}
								fitted={fitted}
								padded
								stackable
								removeMargins
								bodyRows={getContent(item, false, true)}
							/>
						</Segment>
						{typeof item.stacked === 'object' ? (
							<Responsive maxDevice={Device.Mobile}>
								<div className='item-actions'>{getActions(item)}</div>
							</Responsive>
						) : null}
					</>
				)
			});
		}

		// Set item row
		rows.push({
			reactKey: item.id,
			id: item.id,
			className: classNames(classes),
			added: item.added,
			deleted: item.deleted,
			active: item.selected,
			cells: cells,
			onClick: (event: SyntheticEvent<>) => handleContainerClick(event, item),
			onTopVisible: item.onTopVisible,
			onBottomVisible: item.onBottomVisible,
			onAfterDeleteRows: item.onAfterDeleteItems || onAfterDeleteItems
		});

		return rows;
	};

	let content: any = [];
	for (let i = 0; i < renderItems.length; i++) {
		let stackMode = typeof renderItems[i].stacked === 'object' ? 'nested' : 'flat';
		content = content.concat(
			stackMode === 'flat'
				? getContent(renderItems[i])
				: getContent(renderItems[i], renderItems[i].stacked !== undefined ? true : false, false)
		);
	}

	return (
		<Table
			className='items'
			basic={true}
			divided={true}
			fitted={fitted}
			padded
			stackable
			removeMargins
			bodyRows={content}
			testID={testID}
		/>
	);
}

export default (React.memo(ItemGroup): React.AbstractComponent<React.PropsOf<ItemGroup>, mixed>);
