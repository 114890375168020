/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Validate } from 'liana-ui/definitions';
// $FlowIssue[cannot-resolve-module] - No idea why this specific module can't be resolved
import { StickyTableHeader } from 'vh-sticky-table-header';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';
import Form from 'liana-ui/legacy/components/form/Form';
import Chart from 'liana-ui/legacy/components/chart/Chart';
import {
	Text,
	Icon,
	List,
	Divider,
	Segment,
	Message,
	EmptyState,
	ActionHeader,
	Grid,
	ChartInfo,
	Image,
	Checkbox,
	Label,
	LimitLabel,
	GridColumn,
	IconGroup,
	Header,
	Button,
	ContextMenuDetached,
	Breadcrumb,
	Popup
} from 'liana-ui/components/';

import { Size, Spacing, HAlign, VAlign, Device, Float } from 'liana-ui/types';

import TableRow from './src/TableRow';
import TableHeaderCell from './src/TableHeaderCell';
import TableBodyCell from './src/TableBodyCell';
import TableFooterCell from './src/TableFooterCell';
import TableUtils from './src/TableUtils';

import type { Option } from 'liana-ui/components/menu/ContextMenuDetached';

type Cell = {
	content: any,
	header: {
		icons?: Array<string> | Array<React.PropsOf<Icon>>,
		...React.PropsOf<Header>
	},
	subheader?: React.Node,
	icons?: Array<string> | Array<React.PropsOf<Icon>>,
	link: string,
	state: {
		text: string,
		color: string
	},
	contextMenu: React.PropsOf<ContextMenuDetached>,
	textAlign: HAlign,
	verticalAlign: VAlign,
	width: string,
	collapsing: boolean,
	success: boolean,
	warning: boolean,
	error: boolean,
	colSpan: number,
	rowSpan: number,
	singleLine: boolean,
	breakWord: boolean,
	noWrap: boolean,
	popup: React.Node | React.PropsOf<Popup>,
	onClick?: (event?: SyntheticEvent<>, cell: ColumnCell) => void
};

type Column = {
	name: string,
	width?: number,
	collapsing?: boolean,
	minDevice?: Device,
	textAlign?: HAlign,
	singleLine?: boolean,
	breakWord?: boolean,
	noWrap?: boolean
};

type ColumnCell = {
	...Column,
	...Cell
};

type ColumnFooterCell = {
	...Column,
	...React.PropsOf<TableFooterCell>
};

type ColumnHeaderCell = {
	...Column,
	...React.PropsOf<TableHeaderCell>
};

type Row = {
	id: number | string,
	name: string,
	size?: Size,
	active: boolean,
	activable: boolean,
	selectable: boolean,
	folder: boolean,
	folderItems: number,
	cells: Array<ColumnCell>
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
component ActionTable(
	/** An action table must be provided with the total amount of paginated rows. Folder rows should be included. */
	totalRows: number,
	/** If an action table has both selectable and unselectable rows, it must be provided with the total amount of selectable rows, without paging. Folder rows should be included. */
	totalSelectableRows?: number,
	/**
		An action table must have it's columns defined so whole columns can be cotnrolled.
		DATA[json/action-table/columns.json]
	*/
	columns: Array<Column>,
	/**
		An action table may have an action header with various buttons and filters.
		PROPS[ActionHeader=/components/containers/action-header/]
	*/
	actionHeader?: React.PropsOf<ActionHeader>,
	/**
		An action table can have a header row.
		DATA[json/action-table/header-row.json]
		PROPS[IntlComponent=/language/localisation/, React.PropsOf<Popup>=/components/modals/popup/]
	*/
	headerRow?: Array<ColumnHeaderCell>,
	/**
	 	An action table can have body rows. ID:s (also added ones) must always be unique.
		DATA[json/action-table/body-rows.json]
		PROPS[IntlComponent=/language/localisation/, Header=/components/texts/header/, ContextMenuDetachedProps=/components/menus/context-menu/#detached, React.PropsOf<Popup>=/components/modals/popup/]
	*/
	bodyRows: Array<Row>,
	/** Mapped over any data provided for bodyRows property. Should return body row object for body rows. */
	renderBodyRow?: (data: any) => Row,
	/**
		An action table can have a footer row.
		DATA[json/action-table/footer-row.json]
		PROPS[IntlComponent=/language/localisation/, React.PropsOf<Popup>=/components/modals/popup/]
	*/
	footerRow?: Array<ColumnFooterCell>,
	/** An action table can be in loading state. */
	loading?: boolean = false,
	/** An action table can be formatted to display complex structured data. */
	structured?: boolean = false,
	/** An action table can have its rows change background on hover. */
	hoverable?: boolean = true,
	/** An action table can stack columns on mobile. */
	stackable?: boolean = false,
	/** An action table can appear as a segment. */
	segment?: boolean = true,
	/**
		An action table can have a breadcrumb to navigate folders.
		PROPS[BreadcrumbProps=/components/menus/breadcrumb/]
	*/
	breadcrumb?: React.PropsOf<Breadcrumb>,
	/**
		An action table can have a chart and chart info between header and table.
		PROPS[ChartProps=/components/statistics/charts/, ChartInfo=/components/statistics/charts/filter]
	*/
	chart?:
		| {
				chart: any,
				chartInfo: React.PropsOf<ChartInfo>
		  }
		| React.Node,
	/**
		An action table can have any extra items or content between header and table.
	*/
	extra?: any,
	/** The visible totalRows amount in the header can be increased by the amount of extra items if there are some. */
	extraAmount?: number,
	/** 
	  	An action table can have it's items limited and show a limit label. 
	 	PROPS[LimitLabel=/components/labels/limit-label/]
	*/
	limit?: React.PropsOf<LimitLabel>,
	/** An action table can allow a user to sort contents by clicking on a table header. */
	sort?: {
		sortColumns: Array<string>,
		sortedColumn: string,
		sortedDirection: 'asc' | 'desc',
		onSort: (
			event: SyntheticEvent<>,
			data: {
				column: string,
				direction: string
			}
		) => void
	},
	/** An action table can allow a user to select rows. */
	select?: {
		enableSelectAll: boolean,
		selectedRows: Array<string>,
		onSelect: (
			event: SyntheticEvent<>,
			data: {
				name: string,
				value: string | number,
				checked: boolean,
				selected: Array<string>
			}
		) => void,
		allListSelected: boolean,
		onSelectAllList: (event: SyntheticEvent<>) => void,
		onClearAllList: (event: SyntheticEvent<>) => void
	},
	/** An action table can allow a user to select rows. PROPS[React.PropsOf<Popup>=/components/modals/popup/]  */
	activate?: {
		enableActivateAll: boolean,
		activateAllOn: boolean,
		popup: React.PropsOf<Popup>,
		onActive: (
			event: SyntheticEvent<>,
			data: {
				name: string,
				value: string | number,
				checked: boolean
			}
		) => void
	},
	/** An action table can allow to paginate rows. */
	paginate?: {
		currentPage: number,
		paginateAmount: number,
		loadingMore: boolean,
		onLoadMore: (
			event: SyntheticEvent<>,
			data: {
				currentPage: number
			}
		) => void
	},
	/** An action table must have an empty state. PROPS[...EmptyState=/components/feedback/empty-state/empty-state]  */
	emptyState: {
		displayEmptyState: boolean,
		...React.PropsOf<EmptyState>
	},
	/** An action table can override some default translation keys. */
	translations?: {
		totalItemsLabel: string,
		noResults: string,
		selectedOnPage: string,
		selectedOnList: string,
		selectAllAmount: string
	},
	/** An action table can be smaller in size. */
	size?: Size,
	/** Test ID for testing. */
	testID: string = 'ActionTable',
	/** An action table should show an animation (glow) to added rows. Must match the row IDs of the rows to be animated also by type. */
	addedRows?: Array<number | string>,
	/** An action table should show an animation (fly away) on deleted rows. Must match the row IDs of the rows to be animated also by type. */
	deletedRows?: Array<number | string>,
	/** Callback on when an delete animation (fly away) is complete. Must match the row IDs of the rows to be animated also by type. Call API to remove the item from database and and re-render table. Remember also to clear deletedRows in this callback. */
	onAfterDeleteRows?: () => void
) {
	// Variables, states and refs
	const scrollWrapperRef = useRef<HTMLDivElement | null>(null);
	const tableRef = useRef<HTMLTableElement | null>(null);
	const tableCloneRef = useRef<HTMLTableElement | null>(null);

	const [randomTableID] = useState(`actiontable-${Date.now()}-${Math.round(Math.random() * 9999)}`);
	const [stickyHeight, setStickyHeight] = useState(0);
	const [horizontalScrollActive, setHorizontalScrollActive] = useState(true);
	const [leftScrollActive, setLeftScrollActive] = useState(false);
	const [rightScrollActive, setRightScrollActive] = useState(true);

	// Merge translations
	const mergedTranslations = {
		totalItemsLabel: 'component.action-table.totalItemsLabel',
		noResults: 'component.action-table.noResults',
		selectedOnPage: 'component.action-table.selectedOnPage',
		selectedOnList: 'component.action-table.selectedOnList',
		selectAllAmount: 'component.action-table.selectAllAmount',
		...translations
	};

	// Merge empty state
	const { displayEmptyState = false, ...rest } = emptyState || {};
	const mergedEmptyState = {
		image: `${process.env.baseUrl || ''}img/empty-states/empty-default.png`,
		header: <FormattedMessage id='component.action-table.emptyStateHeader' />,
		content: <FormattedMessage id='component.action-table.emptyStateContent' />,
		...rest
	};

	// Internal variables and states
	let INTERNAL_addedRows = useRef(addedRows ? [...addedRows] : []),
		INTERNAL_selectedRows = select && select.selectedRows ? select.selectedRows.slice() : [];

	// Set and update sticky table header
	useLayoutEffect(() => {
		if (
			!document.querySelector('html')?.classList.contains('mobile') &&
			tableRef.current &&
			tableCloneRef.current
		) {
			// Initialize the sticky header.
			const sticky = new StickyTableHeader(tableRef.current, tableCloneRef.current, {
				max: stickyHeight ? stickyHeight : 0
			});
			// Make sure to destory the sticky header once the main table is unmounted.
			return () => sticky.destroy();
		}
	});

	// Set horizontal sticky and scrolling
	useEffect(() => {
		if (scrollWrapperRef.current && tableRef.current) {
			setHorizontalSticky();
			new ResizeSensor(scrollWrapperRef.current, setHorizontalSticky);
			new ResizeSensor(tableRef.current, setHorizontalSticky);

			scrollWrapperRef.current?.addEventListener('scroll', () => {
				let scrollLeft = scrollWrapperRef.current?.scrollLeft || 0,
					clientWidth = scrollWrapperRef.current?.clientWidth || 0;
				if (scrollLeft > 0) {
					setLeftScrollActive(true);
				} else {
					setLeftScrollActive(false);
				}
				if (
					// $FlowIssue[invalid-compare] - Already checked above
					scrollLeft + clientWidth <
					scrollWrapperRef.current?.scrollWidth
				) {
					setRightScrollActive(true);
				} else {
					setRightScrollActive(false);
				}
			});
		}
	}, []);

	// Clear sensors on unmount
	useEffect(
		() => () => {
			if (scrollWrapperRef.current) {
				ResizeSensor.detach(scrollWrapperRef.current);
			}
			if (tableRef.current) {
				ResizeSensor.detach(tableRef.current);
			}
		},
		[]
	);

	const setHorizontalSticky = () => {
		const scrollWrapperWidth = scrollWrapperRef.current?.getBoundingClientRect().width || 0,
			tableWidth = tableRef.current?.getBoundingClientRect().width || 0;
		if (tableWidth > scrollWrapperWidth) {
			setHorizontalScrollActive(true);
			if (scrollWrapperRef.current) {
				TableUtils.enableHorizontalDrag(scrollWrapperRef.current);
			}
		} else {
			setHorizontalScrollActive(false);
			if (scrollWrapperRef.current) {
				TableUtils.disableHorizontalDrag(scrollWrapperRef.current);
			}
		}
	};

	// Factory function for transforming data
	// TODO: Why is this needed? This is currently done twice each render; very slow
	let body: any = [];
	if (bodyRows && bodyRows.length > 0) {
		if (typeof renderBodyRow === 'function') {
			for (let i = 0; i < bodyRows.length; i++) {
				body[i] = renderBodyRow(bodyRows[i]);
			}
		} else {
			body = bodyRows;
		}
	}

	const renderHeaderRow = () => {
		// Check if exists
		if (!headerRow || body.length === 0) {
			return null;
		}

		let cells = [];

		if (select) {
			cells.push(
				<TableHeaderCell
					key='tableheadercell-select'
					content={
						select.enableSelectAll ? (
							<Checkbox
								fitted
								name='select-all'
								checked={hasAllVisibleRowsSelected()}
								indeterminate={hasSomeVisibleRowsSelected()}
								value='all'
								disabled={totalRows == 0 || body.filter((row) => row.selectable !== false).length === 0}
								size={size}
								onChange={handleSelect}
							/>
						) : null
					}
					collapsing
					textAlign={HAlign.Center}
				/>
			);
		}

		if (activate) {
			cells.push(
				<TableHeaderCell
					key='tableheadercell-active'
					content={
						activate && activate.enableActivateAll ? (
							<Checkbox
								checked={activate.activateAllOn}
								fitted
								toggle
								name='active'
								value='all'
								disabled={totalRows === 0}
								size={size}
								onChange={handleActive}
								popup={activate.popup}
							/>
						) : (
							<FormattedMessage id='component.action-table.active' />
						)
					}
					minDevice={Device.Tablet}
					collapsing
					textAlign={HAlign.Center}
				/>
			);
		}

		headerRow.map((cell: ColumnHeaderCell, cellIndex: number) => {
			let sortable, sortedColumn, sortedDirection;

			if (Array.isArray(columns) && columns.length > 0) {
				// $FlowIgnore - Flow can't handle spread attributes
				cell = { ...columns[cellIndex], ...cell };
				if (sort) {
					sortable = Array.isArray(sort.sortColumns) ? sort.sortColumns.indexOf(cell.name) !== -1 : undefined;
					sortedColumn = sort.sortedColumn;
					sortedDirection = sort.sortedDirection;
				}
			}

			// Set header and context menu columns sticky
			let sticky = classNames({
				'sticky-left': body[0].cells[cellIndex]?.header && horizontalScrollActive,
				'sticky-right': body[0].cells[cellIndex]?.contextMenu && horizontalScrollActive,
				'sticky-left-shadow': body[0].cells[cellIndex]?.header && leftScrollActive,
				'sticky-right-shadow': body[0].cells[cellIndex]?.contextMenu && rightScrollActive
			});

			cells.push(
				<TableHeaderCell
					key={`tableheadercell-${cellIndex}`}
					content={cell.content}
					colSpan={cell.colSpan}
					collapsing={cell.collapsing}
					width={cell.width}
					textAlign={cell.textAlign}
					verticalAlign={cell.verticalAlign}
					sorted={cell.name === sortedColumn ? sortedDirection : undefined}
					sortable={sortable}
					minDevice={cell.minDevice}
					popup={cell.popup}
					sticky={sticky}
					onClick={
						sortable
							? (event) => {
									handleSort(event, {
										column: cell.name,
										direction: cell.name === sortedColumn ? sortedDirection : undefined
									});
								}
							: undefined
					}
				/>
			);
		});

		return (
			<Table.Header key='tableheaderrow'>
				<Table.Row>{cells}</Table.Row>
			</Table.Header>
		);
	};

	const renderBodyRows = (br: Array<Row>) => {
		// Check if exists
		if (!br || !Array.isArray(br)) {
			return null;
		}

		let rows = [];
		let fullColspan = (Array.isArray(columns) ? columns.length : 0) + (select ? 1 : 0) + (activate ? 1 : 0);
		if (br.length > 0) {
			if (select?.enableSelectAll && hasSelectAllListEnabled()) {
				rows.push(
					<TableRow
						key='tablebodyrow-select-all-info'
						className={classNames('select-all-info', {
							hidden: !hasAllVisibleRowsSelected()
						})}
					>
						<TableBodyCell
							key='tablebodycell-select-all-info'
							colSpan={columnsAmount()}
							content={
								select?.allListSelected ? (
									<List
										horizontal={true}
										divided={true}
										items={[
											{
												content: (
													<FormattedMessage
														id={mergedTranslations.selectedOnList}
														values={{
															amount: (
																<Text bold>
																	{totalSelectableRows && countUnSelectableRows()
																		? totalSelectableRows
																		: totalRows}
																</Text>
															)
														}}
													/>
												)
											},
											{
												content: (
													<a href='#' onClick={handleClearAllList}>
														<FormattedMessage id='component.action-table.clearSelected' />
													</a>
												)
											}
										]}
									/>
								) : (
									<List
										horizontal={true}
										divided={true}
										items={[
											{
												content: (
													<FormattedMessage
														id={mergedTranslations.selectedOnPage}
														values={{
															amount: <Text bold>{select?.selectedRows?.length}</Text>
														}}
													/>
												)
											},
											{
												content: (
													<a href='#' onClick={handleSelectAllList}>
														<FormattedMessage
															id={mergedTranslations.selectAllAmount}
															values={{
																amount: (
																	<Text bold>
																		{totalSelectableRows && countUnSelectableRows()
																			? totalSelectableRows
																			: totalRows}
																	</Text>
																)
															}}
														/>
													</a>
												)
											}
										]}
									/>
								)
							}
						/>
					</TableRow>
				);
			}

			br.map((row: Row) => {
				let cells = [];

				if (select) {
					cells.push(
						<TableBodyCell
							ignoreOff
							key={`tablebodycell-${row.folder ? 'folder' : 'item'}-${row.id}-select`}
							content={
								row.selectable !== false ? (
									<Checkbox
										fitted
										name='selected'
										value={row.id}
										checked={
											select && Array.isArray(select.selectedRows)
												? select.selectedRows.indexOf(row.id) !== -1
													? true
													: false
												: undefined
										}
										size={size}
										onChange={handleSelect}
									/>
								) : null
							}
							collapsing
							textAlign={HAlign.Center}
						/>
					);
				}

				if (activate) {
					cells.push(
						<TableBodyCell
							ignoreOff
							key={`tablebodycell-${row.folder ? 'folder' : 'item'}-${row.id}-active`}
							content={
								row.activable !== false ? (
									<Checkbox
										fitted
										toggle
										name='active'
										value={row.id}
										checked={row.active}
										size={size}
										onChange={handleActive}
										popup={activate.popup}
									/>
								) : null
							}
							collapsing
							minDevice={Device.Tablet}
							textAlign={HAlign.Center}
						/>
					);
				}

				if (Array.isArray(row.cells)) {
					row.cells.map((cell: ColumnCell, cellIndex: number) => {
						let sortedColumn, sortedDirection;

						if (Array.isArray(columns) && columns.length > 0) {
							cell = { ...columns[cellIndex], ...cell };
							if (sort) {
								sortedColumn = sort.sortedColumn;
								sortedDirection = sort.sortedDirection;
							}
						}

						// Set header and context menu columns sticky
						let sticky = classNames({
							'header-cell': cell.header,
							'contextmenu-cell': cell.contextMenu,
							'sticky-left': cell.header && horizontalScrollActive,
							'sticky-right': cell.contextMenu && horizontalScrollActive,
							'sticky-left-shadow': cell.header && leftScrollActive,
							'sticky-right-shadow': cell.contextMenu && rightScrollActive
						});

						cells.push(
							<TableBodyCell
								key={`tablebodycell-${row.folder ? 'folder' : 'item'}-${row.id}-${cellIndex}`}
								ignoreOff={Boolean(cell.contextMenu)}
								content={getBodyCellContent(row, cell, cellIndex)}
								colSpan={cell.colSpan}
								collapsing={cell.collapsing}
								width={cell.width}
								textAlign={cell.contextMenu ? HAlign.Right : cell.textAlign}
								verticalAlign={cell.verticalAlign}
								selectable={Boolean(cell.link || typeof cell.onClick === 'function')}
								sorted={Boolean(
									cell.name && sortedColumn && cell.name === sortedColumn && sortedDirection
								)}
								singleLine={!cell.header ? cell.singleLine : false}
								breakWord={!cell.header ? cell.breakWord : false}
								noWrap={!cell.header ? cell.noWrap : false}
								minDevice={cell.minDevice}
								popup={cell.popup}
								sticky={sticky}
							/>
						);
					});
				}

				rows.push(
					<TableRow
						key={`tablebodyrow-key-${row.id}`}
						reactKey={`tablebodyrow-${row.id}`}
						added={
							Array.isArray(addedRows) &&
							addedRows.indexOf(row.id) !== -1 &&
							INTERNAL_addedRows.current.indexOf(row.id) === -1
						}
						deleted={Array.isArray(deletedRows) && deletedRows.indexOf(row.id) !== -1}
						active={
							select && Array.isArray(select.selectedRows) && select.selectedRows.indexOf(row.id) !== -1
						}
						off={row.active === false}
						onAfterDeleteRows={onAfterDeleteRows}
					>
						{cells}
					</TableRow>
				);

				// Disable added row glow animation after it has completed
				if (Array.isArray(addedRows) && addedRows.indexOf(row.id) !== -1) {
					setTimeout(() => INTERNAL_addedRows.current.push(row.id), 1000);
				}
			});
		} else {
			rows.push(
				<TableRow key='tablebodyrow-empty'>
					<TableBodyCell
						content={
							<Segment basic compressed='very'>
								<Message
									info
									content={<FormattedMessage id={mergedTranslations.noResults} />}
									icon='fa-info-circle'
								/>
							</Segment>
						}
						colSpan={fullColspan}
						className='no-results'
						key='tablebodycell-empty'
					/>
				</TableRow>
			);
		}
		return <Table.Body>{rows}</Table.Body>;
	};

	const renderFooterRow = () => {
		// Check if exists
		if (!footerRow || totalRows === 0) {
			return null;
		}

		let cells = [];

		if (select) {
			cells.push(<TableFooterCell key='tablefootercell-select' collapsing textAlign={HAlign.Center} />);
		}

		if (activate) {
			cells.push(
				<TableFooterCell
					key='tablefootercell-active'
					collapsing
					minDevice={Device.Tablet}
					textAlign={HAlign.Center}
				/>
			);
		}

		footerRow.map((cell: ColumnFooterCell, cellIndex: number) => {
			let sortedColumn;

			if (Array.isArray(columns) && columns.length > 0) {
				cell = { ...columns[cellIndex], ...cell };
				if (sort) {
					sortedColumn = sort.sortedColumn;
				}
			}

			// Set header and context menu columns sticky
			let sticky = classNames({
				'sticky-left': body[0].cells[cellIndex].header && horizontalScrollActive,
				'sticky-right': body[0].cells[cellIndex].contextMenu && horizontalScrollActive,
				'sticky-left-shadow': body[0].cells[cellIndex].header && leftScrollActive,
				'sticky-right-shadow': body[0].cells[cellIndex].contextMenu && rightScrollActive
			});

			cells.push(
				<TableFooterCell
					key={`tablefootercell-${cellIndex}`}
					content={cell.content}
					colSpan={cell.colSpan}
					collapsing={cell.collapsing}
					width={cell.width}
					textAlign={cell.textAlign}
					verticalAlign={cell.verticalAlign}
					sorted={Boolean(cell.name && sortedColumn && cell.name === sortedColumn)}
					minDevice={cell.minDevice}
					popup={cell.popup}
					sticky={sticky}
				/>
			);
		});

		return (
			<Table.Footer key='tablefooterrow'>
				<Table.Row>{cells}</Table.Row>
			</Table.Footer>
		);
	};

	const getBodyCellContent = (row: Row, cell: ColumnCell, cellIndex: number): React.Node => {
		let content: React.Node,
			subheader = cell.subheader ? [cell.subheader] : [];

		// Get link type
		let linkType = Validate.linkType(cell.link);

		if (typeof cell.header === 'object') {
			if (cell.header.subheader) {
				subheader.push(
					<Text
						key={`text-${cellIndex}`}
						as='div'
						className={cell.singleLine || cell.header.singleLine ? 'text-lines-1' : undefined}
					>
						{cell.header.subheader}
					</Text>
				);
			}
			if (cell.state && cell.state.color && cell.state.text) {
				subheader.push(
					<Label
						key={`label-${cellIndex}`}
						textual={true}
						text={cell.state.text}
						icon={{
							color: cell.state.color,
							name: 'fa-circle',
							solid: true
						}}
					/>
				);
			}

			content = (
				<Grid
					columns={2}
					compact
					verticalAlign={VAlign.Middle}
					singleRow
					breakWord={cell.breakWord}
					singleLine={cell.singleLine}
				>
					{row.folder ? (
						<GridColumn collapsing>
							<Icon name='fa-folder' size={Size.Big} number={row.folderItems} />
						</GridColumn>
					) : null}
					{cell.header.image !== undefined ? (
						<GridColumn collapsing>
							{typeof cell.header.image === 'object' ? (
								<Image
									src={cell.header.image?.src}
									size={size !== Size.Small ? Size.Mini : undefined}
									squared={cell.header.image?.squared}
									avatar={cell.header.image?.avatar ? cell.header.image?.avatar : true}
								/>
							) : (
								<Image
									src={cell.header.image || ''}
									size={size !== Size.Small ? Size.Mini : undefined}
									avatar
								/>
							)}
						</GridColumn>
					) : null}
					{cell.header.icon !== undefined ? (
						<GridColumn collapsing>
							{typeof cell.header.icon === 'object' ? (
								<Icon
									name={cell.header.icon?.name}
									size={size}
									circular={cell.header.icon?.circular}
									squared={cell.header.icon?.squared}
								/>
							) : (
								<Icon name={cell.header.icon} size={size !== Size.Small ? Size.Mini : undefined} />
							)}
						</GridColumn>
					) : null}
					{cell.header.icons !== undefined ? (
						<GridColumn collapsing>
							{typeof cell.header.icons === 'object' ? (
								<IconGroup icons={cell.header.icons} size={size} />
							) : null}
						</GridColumn>
					) : null}
					<GridColumn fluid verticalAlign={!subheader ? VAlign.Middle : undefined}>
						{/* $FlowFixMe[prop-missing] - Don't mix/switch types like this */}
						<Header
							{...cell.header}
							key={cellIndex}
							image={false}
							text={
								linkType !== 'external' ? (
									cell.header.text
								) : (
									<>
										{cell.header.text}{' '}
										<Icon name='fa-arrow-up-right-from-square' size={Size.Small} />
									</>
								)
							}
							subheader={subheader.length > 0 ? subheader : undefined}
							size={Size.Small}
							singleLine={cell.singleLine || cell.header.singleLine}
							icon={undefined}
						/>
					</GridColumn>
				</Grid>
			);
		} else if (cell.contextMenu) {
			if (React.isValidElement(cell.contextMenu)) {
				// $FlowIssue[incompatible-cast] - Impossible to distiguish between object vs. React.Node
				content = cell.contextMenu as React.Node;
			} else if (typeof cell.contextMenu === 'object') {
				let options: Array<Option> = [];

				// If does not have separate menu option expect the object to be menu options
				let ctxOptions: Array<Option> = cell.contextMenu.options
					? cell.contextMenu.options.slice()
					: cell.contextMenu;

				// Automatically add a active toggle in the context menu for mobile
				if (activate && row.activable !== false) {
					if (Array.isArray(ctxOptions) && ctxOptions[0].header) {
						options.push(ctxOptions[0]);
						ctxOptions.shift();
					}
					options.push({
						checkbox: {
							toggle: true,
							label: <FormattedMessage id='component.action-table.active' />,
							name: 'active',
							value: row.id,
							checked: row.active,
							size: row.size,
							onChange: handleActive
						}
					});
					options.push({ divider: true });
				}
				options = options.concat(ctxOptions);
				content = <ContextMenuDetached fitted options={options} size={size} direction={Float.Left} />;
			}
		} else {
			if (stackable && Array.isArray(headerRow) && headerRow.length > 0) {
				content = (
					<Form.Field>
						<Text as='label' size={Size.Small} maxDevice={Device.Mobile}>
							{headerRow[cellIndex].content}:
						</Text>
						{cell.content}
					</Form.Field>
				);
			} else {
				content = cell.content;
			}
		}

		if (typeof cell.onClick === 'function') {
			content = (
				<a href={cell.link ? cell.link : '#'} onClick={() => handleClick(undefined, cell)}>
					{content}
				</a>
			);
		} else if (cell.link) {
			if (linkType === 'external') {
				content = (
					<a href={cell.link} target='_blank' rel='noopener noreferrer'>
						{content}
					</a>
				);
			} else {
				content = <Link to={cell.link}>{content}</Link>;
			}
		}

		return content;
	};

	const handleClick = (event?: SyntheticEvent<>, cell: ColumnCell) => {
		if (event) {
			event.preventDefault();
		}
		if (typeof cell.onClick === 'function') {
			cell.onClick(event, cell);
		}
	};

	const handleSort = (event: SyntheticEvent<>, data: any) => {
		// Trigger onSort callback funtion
		if (typeof sort?.onSort === 'function') {
			sort.onSort(event, {
				...data,
				column: data.direction !== 'descending' ? data.column : undefined,
				direction: !data.direction ? 'asc' : data.direction === 'asc' ? 'desc' : undefined
			});
		}
	};

	const handleActive = (event: SyntheticEvent<>, data: any) => {
		if (typeof activate?.onActive === 'function') {
			activate.onActive(event, data);
		}
	};

	const handleLoadMore = (event: SyntheticEvent<>) => {
		// Trigger onLoadMore callback funtion
		if (typeof paginate?.onLoadMore === 'function') {
			paginate.onLoadMore(event, {
				currentPage: paginate.currentPage + 1
			});
		}
	};

	const handleSelect = (event: SyntheticEvent<>, data: any) => {
		if (data.value === 'all') {
			INTERNAL_selectedRows = data.checked
				? body.filter((row) => row.selectable !== false).map((row) => String(row.id))
				: [];
		} else if (data.value === 'clear') {
			INTERNAL_selectedRows = [];
		} else {
			if (data.checked) {
				INTERNAL_selectedRows = [...INTERNAL_selectedRows, ...[data.value]];
			} else {
				INTERNAL_selectedRows.splice(INTERNAL_selectedRows.indexOf(data.value), 1);
			}
		}

		// Trigger onSelect callback funtion
		if (select && typeof select.onSelect === 'function') {
			select.onSelect(event, { ...data, selected: INTERNAL_selectedRows });
		}
	};

	const getHeaderLabels = () => {
		let labels: any = [],
			headerLabels = actionHeader?.header?.labels,
			deepItems = countItems('deep');

		if (extraAmount) {
			deepItems = deepItems + extraAmount;
		}

		if (limit?.limit) {
			// Use translations.totalItemsLabel for limit label if limit.tranlations.amount not provided
			let limitExtra = limit;
			if (!limit.translations?.amount) {
				limitExtra = { ...limit, translations: { amount: mergedTranslations.totalItemsLabel } };
			}
			labels.push({ ...limitExtra, amount: deepItems });
		} else {
			labels.push({
				text: <FormattedMessage id={mergedTranslations.totalItemsLabel} values={{ amount: deepItems }} />
			});
		}

		if (headerLabels) {
			labels = Array.isArray(headerLabels) ? [...labels, ...headerLabels] : [...labels, ...[headerLabels]];
		}

		return labels;
	};

	const countItems = (mode?: 'deep') => {
		let totalItems = 0;
		totalItems = totalRows;

		if (mode === 'deep') {
			let folders = body.filter((row) => row.folder);
			folders.map((folder) => {
				totalItems = totalItems + folder.folderItems;
			});
			totalItems = totalItems - folders.length;
		}

		return totalItems;
	};

	const countColumns = () => {
		let columns = 0,
			columnNames = {
				1: 'one',
				2: 'two',
				3: 'three',
				4: 'four',
				5: 'five',
				6: 'six',
				7: 'seven',
				8: 'eight',
				9: 'nine',
				10: 'ten',
				11: 'eleven',
				12: 'twelve',
				13: 'thirteen',
				14: 'fourteen',
				15: 'fifteen',
				16: 'sixteen'
			};
		if (Array.isArray(headerRow) && headerRow.length > 0) {
			columns = headerRow.length;
			if (select) {
				columns++;
			}
			if (activate) {
				columns++;
			}
		}
		// $FlowFixMe[prop-missing] - Already checked above
		return columns > 0 ? columnNames[columns] : '';
	};

	const columnsAmount = () => {
		let count = headerRow?.length || 0;
		count = select ? count + 1 : count;
		count = activate ? count + 1 : count;
		return count;
	};

	const handleSelectAllList = (event: SyntheticEvent<>) => {
		event.preventDefault();
		handleSelect(event, { value: 'all', checked: true });
		// Trigger select.onSelectAllList callback funtion
		if (select && typeof select.onSelectAllList === 'function') {
			select.onSelectAllList(event);
		}
	};

	const handleClearAllList = (event: SyntheticEvent<>) => {
		event.preventDefault();
		handleSelect(event, { value: 'clear' });
		// Trigger select.onClearAllList callback funtion
		if (select && typeof select.onClearAllList === 'function') {
			select.onClearAllList(event);
		}
	};
	const countSelectableRows = () => {
		return body.filter((row) => row.selectable !== false).length;
	};

	const countUnSelectableRows = () => {
		return body.filter((row) => row.selectable === false).length;
	};

	const hasAllVisibleRowsSelected = () => {
		let selectableRowsCount = countSelectableRows();
		return select && Array.isArray(select.selectedRows)
			? selectableRowsCount === select.selectedRows.length && selectableRowsCount > 0
				? true
				: false
			: undefined;
	};

	const hasSomeVisibleRowsSelected = () => {
		let selectableRowsCount = countSelectableRows();
		return select && Array.isArray(select.selectedRows)
			? select.selectedRows.length > 0 && selectableRowsCount > select.selectedRows.length
				? true
				: false
			: undefined;
	};

	const hasSelectAllListEnabled = () => {
		return (
			typeof select?.onSelectAllList === 'function' &&
			typeof select?.onClearAllList === 'function' &&
			totalRows > (paginate?.paginateAmount || 0) &&
			body.length < totalRows
		);
	};

	const getTableClasses = () => {
		// Assign classes
		const columns = countColumns();
		const classes = classNames('ui table', {
			[`${columns} column`]: columns,
			unstackable: stackable === false ? true : false,
			selectable: body.length > 0 && hoverable,
			structured: structured,
			sortable: sort && totalRows > 0,
			'has-select-all': hasSelectAllListEnabled(),
			// $FlowFixMe[invalid-computed-prop] - Invalid computed property
			[size]: size
		});
		return classes;
	};

	const getTable = (br: Array<Row>) => {
		let table = (
			<table ref={tableRef} className={getTableClasses()}>
				{renderHeaderRow()}
				{renderBodyRows(br)}
				{renderFooterRow()}
			</table>
		);
		return table;
	};

	const getMassOptions = () => {
		let options = actionHeader?.massOptions?.concat({ divider: true });
		if (hasAllVisibleRowsSelected() && !select?.allListSelected && hasSelectAllListEnabled()) {
			options = options?.concat({
				text: (
					<FormattedMessage
						id='component.action-table.selectAllAmount'
						values={{ amount: totalSelectableRows || totalRows }}
					/>
				),
				icon: 'fa-check',
				onClick: handleSelectAllList
			});
		}
		options = options?.concat([
			{
				text: <FormattedMessage id='component.action-table.clearSelected' />,
				icon: 'fa-remove',
				onClick: (event) => handleSelect(event, { value: 'clear' })
			}
		]);
		return options;
	};

	let totalItems = countItems(),
		currentPage = paginate ? paginate.currentPage : undefined,
		paginateAmount = paginate ? paginate.paginateAmount : undefined,
		nextMin = 0,
		max = 0,
		nextMax = 0,
		showMore = false;

	if (typeof currentPage == 'number' && typeof paginateAmount === 'number') {
		nextMin = currentPage * paginateAmount;
		max = (currentPage + 1) * paginateAmount;
		nextMax = max > totalItems ? totalItems : max;
		showMore = totalItems > nextMin;
	}

	// Assign classes
	const tableWrapperClasses = classNames('actiontable-table-wrapper', {
		'has-header': totalRows || actionHeader || breadcrumb || chart || extra,
		'has-button': showMore
	});

	// Define ActionTable
	let actionTable = displayEmptyState ? (
		<>
			{extra ? (
				<Segment compressed removeMargins={Spacing.All} attached='top'>
					{extra}
				</Segment>
			) : null}
			<Segment raised padded='very' attached={extra ? 'bottom' : undefined}>
				<EmptyState {...mergedEmptyState} />
			</Segment>
		</>
	) : (
		<>
			<Segment
				basic={!segment}
				raised={segment}
				removeMargins={!segment ? Spacing.All : showMore ? Spacing.Bottom : undefined}
				removePaddings={Spacing.All}
				className='actiontable-segment'
			>
				<div className='actiontable-wrapper' id={randomTableID} data-testid={testID}>
					{actionHeader || !isNaN(totalRows) ? (
						<ActionHeader
							{...actionHeader}
							massContextMenu={
								// $FlowIgnore[react-rule-unsafe-ref] - undefined is safe to use
								INTERNAL_selectedRows.length > 0 || (select?.allListSelected && totalSelectableRows)
									? {
											text: (
												<FormattedMessage
													id='component.action-table.amountSelected'
													values={{
														amount: select?.allListSelected
															? totalSelectableRows && countUnSelectableRows()
																? totalSelectableRows
																: totalRows
															: // $FlowIgnore[react-rule-unsafe-ref] - undefined is safe to use
																INTERNAL_selectedRows.length
													}}
												/>
											),
											options: getMassOptions()
										}
									: undefined
							}
							sticky={!document.querySelector('html')?.classList.contains('mobile')}
							bottomBorder
							header={{
								...actionHeader?.header,
								labels: getHeaderLabels()
							}}
							size={size}
							scrollTo={`#${randomTableID}`}
							onSizeChange={(element) => setStickyHeight(element.offsetHeight)}
						/>
					) : null}
					{breadcrumb ? (
						<>
							<Breadcrumb icon='fa-folder' size={Size.Small} {...breadcrumb} />
							<Divider removeMargins={Spacing.All} />
						</>
					) : null}
					{React.isValidElement(chart) ? (
						<>
							<Segment basic compressed removeMargins={Spacing.All}>
								{/** $FlowIssue - Impossible to refine object vs. React.Node */}
								{chart}
							</Segment>
							<Divider removeMargins={Spacing.All} />
						</>
					) : /** $FlowIssue - Impossible to refine object vs. React.Node */
					chart?.chart ? (
						<>
							<Segment basic compressed removeMargins={Spacing.All}>
								{chart.chartInfo ? (
									<Grid stackable stretched>
										<GridColumn width={9}>
											<Chart isAnimated isBoxed {...chart.chart} />
										</GridColumn>
										<GridColumn width={3}>
											<ChartInfo {...chart.chartInfo} />
										</GridColumn>
									</Grid>
								) : (
									<Chart isAnimated isBoxed {...chart.chart} />
								)}
							</Segment>
							<Divider removeMargins={Spacing.All} />
						</>
					) : null}
					{extra ? (
						<>
							<Segment basic compressed removeMargins={Spacing.All}>
								{extra}
							</Segment>
							<Divider removeMargins={Spacing.All} />
						</>
					) : null}
					<Segment
						basic
						removePaddings={Spacing.All}
						removeMargins={Spacing.All}
						className={tableWrapperClasses}
						loading={loading ? 'scrolling' : undefined}
					>
						<div
							className={`actiontable-table-scroll-wrapper${horizontalScrollActive ? ' scrollable' : ''}`}
							ref={scrollWrapperRef}
						>
							{getTable(body)}
						</div>
					</Segment>
					{headerRow && body.length > 0 ? (
						<div className={classNames('actiontable-table-clone-wrapper', tableWrapperClasses)}>
							<table className={getTableClasses()} ref={tableCloneRef} />
						</div>
					) : null}
				</div>
			</Segment>
			{showMore ? (
				<Segment
					basic
					compressed
					textAlign={HAlign.Center}
					removeMargins={Spacing.Top}
					className='actiontable-pagination-wrapper'
				>
					<Button
						text={
							<FormattedMessage
								id='component.action-table.showMore'
								values={{ min: nextMin, max: nextMax, total: totalItems }}
							/>
						}
						circular
						icon={{ name: 'fa-chevron-down', solid: true }}
						loading={paginate && paginate.loadingMore}
						onClick={handleLoadMore}
					/>
				</Segment>
			) : null}
		</>
	);

	return actionTable;
}

export default (React.memo(ActionTable): React.AbstractComponent<React.PropsOf<ActionTable>, mixed>);
