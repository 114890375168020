/**
 * @prettier
 * @flow
 */

import { Link } from 'react-router-dom';
import { Gainsight } from 'liana-ui/definitions';
import Validate from 'liana-ui/definitions/component/Validate';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { Header, List, Image, Popup, Segment, Responsive } from 'liana-ui/components/';
import { Size, Spacing, Device } from 'liana-ui/types';
import type { User } from '../TopPanel';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
component UserMenu(
	/** If is open */
	open: boolean = false,
	/** User menu data. User object: DATA[json/user/user.json] */
	user: User,
	/** Position, with and heigh of dropdown menu */
	style?: { [string]: string | number },
	/** Object of properties for Popup */
	popup?: React.PropsOf<Popup>,
	/** Test ID used for testing. */
	testID: string = 'UserMenu',
	/** Called on organization change. Returns organization id. */
	onOrganizationChange?: (string) => void,
	/** Called on user logout. Returns logout url. */
	onLogout?: (?string) => void,
	/** Called on menu open. */
	onOpen?: (event: SyntheticEvent<>, data: { id: string }) => void,
	/** Called on menu close. */
	onClose?: (event: Event, data: { id: string }) => void
) {
	// Render nothing if empty
	if (!user) {
		return null;
	}

	const ID = 'user';

	const isMobile =
		document.querySelector('html')?.classList.contains('mobile') ||
		document.querySelector('html')?.classList.contains('tablet');

	const getUserName = () => {
		return typeof user.name !== 'undefined' && user.name !== '' ? user.name : user.email;
	};

	const getOrganizationID = () => {
		let organizationID = user.selectedOrganization;
		// If organization not selected pick first
		if (!organizationID && user.organizations && user.organizations.length > 0) {
			organizationID = user.organizations[0].id;
		}
		return organizationID;
	};

	const getOrganizationName = () => {
		let organizationName,
			organizationID = getOrganizationID();

		if (user.organizations && user.organizations.length > 0) {
			user.organizations.map((option) => {
				if (option.id === organizationID) {
					organizationName = option.name;
				}
			});
		}

		return organizationName;
	};

	const getUserLinks = () => {
		// Render nothing if empty
		if (!user.links) {
			return null;
		}

		let userLinkType, organizationLinkType;

		// Get link types
		if (user.links.userSettings) {
			userLinkType = Validate.linkType(user.links.userSettings);
		}
		if (user.links?.organizationSettings) {
			organizationLinkType = Validate.linkType(user.links.organizationSettings);
		}

		return (
			<>
				{user.links?.userSettings ? (
					<Dropdown.Item
						as={userLinkType === 'internal' ? Link : 'a'}
						href={userLinkType === 'internal' ? undefined : user.links.userSettings}
						to={userLinkType === 'internal' ? user.links.userSettings : false}
						icon='fa-user'
						className='text-hyphenate'
						text={<FormattedMessage id='component.user-menu.userAccount' />}
					/>
				) : null}
				{user.links?.organizationSettings ? (
					<Dropdown.Item
						as={organizationLinkType === 'internal' ? Link : 'a'}
						href={organizationLinkType === 'internal' ? undefined : user.links.organizationSettings}
						to={organizationLinkType === 'internal' ? user.links.organizationSettings : false}
						icon='fa-building'
						className='text-hyphenate'
						text={<FormattedMessage id='component.user-menu.organizationAccount' />}
					/>
				) : null}
				{user.links?.logout ? (
					<Dropdown.Item
						icon='fa-arrow-right-from-bracket'
						className='text-hyphenate'
						text={<FormattedMessage id='component.user-menu.logOut' />}
						onClick={handleLogout}
					/>
				) : null}
			</>
		);
	};

	const getOrganizations = () => {
		// Render nothing if empty
		if (
			!user ||
			!user.organizations ||
			user.organizations.length < 2 ||
			typeof onOrganizationChange !== 'function'
		) {
			return null;
		}

		let organizationID = getOrganizationID();

		let menu = [
			<Dropdown.Header content={<FormattedMessage id='component.user-menu.organization' />} key='header' />,
			<Dropdown.Divider key='divider' />
		];

		let previousSection = null;
		if (user.organizations) {
			user.organizations.map((organization, i) => {
				if (i > 0 && organization.section && organization.section !== previousSection) {
					menu.push(<Dropdown.Divider key={`divider${i}`} />);
				}
				menu.push(
					<Dropdown.Item
						key={`item${i}`}
						className='organization text-hyphenate'
						id={organization.id}
						image={<Image src={organization.image} squared avatar='building' size={Size.Favicon} />}
						text={organization.name}
						active={organization.id === organizationID}
						onClick={handleChangeOrganization}
					/>
				);
				previousSection = organization.section;
			});
		}

		return menu;
	};

	const getContractLinks = () => {
		// Render nothing if empty
		if (!user || !user.links || (!user.links.privacyPolicy && !user.links.termsOfService)) {
			return null;
		}

		return (
			<>
				<Dropdown.Divider key='divider' />
				<Segment basic compressed removeMargins={Spacing.All}>
					<List
						size={Size.Tiny}
						items={[
							user.links.privacyPolicy
								? {
										icon: 'external',
										content: <FormattedMessage id='component.user-menu.privacyPolicy' />,
										link: user.links.privacyPolicy
									}
								: null,
							user.links.termsOfService
								? {
										icon: 'external',
										content: <FormattedMessage id='component.user-menu.termsOfService' />,
										link: user.links.termsOfService
									}
								: null
						]}
					/>
				</Segment>
			</>
		);
	};

	const handleOpen = (event: SyntheticEvent<>, data: any) => {
		if (typeof onOpen === 'function') {
			onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: Event, data: any) => {
		if (typeof onClose === 'function') {
			onClose(event, handleCallbackData(data));
		}
	};

	const handleChangeOrganization = (event: SyntheticEvent<>, data: any) => {
		if (typeof onOrganizationChange === 'function') {
			onOrganizationChange(data.id);
		}
	};

	const handleLogout = () => {
		Gainsight.reset();
		if (typeof onLogout === 'function') {
			onLogout(user.links?.logout);
		}
	};

	// Handle data returned by onClose callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	let menu = (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={open}
			item
			icon={
				<span>
					<Image
						fitted
						avatar={isMobile && open ? 'close' : 'user'}
						src={isMobile && open ? undefined : user.image}
						alt='Avatar'
						lazyLoad={false}
						maxDevice={Device.Tablet}
					/>
				</span>
			}
			text={
				<Header
					text={getUserName()}
					subheader={getOrganizationName()}
					size={Size.Small}
					image={{
						avatar: true,
						src: user.image,
						alt: 'Avatar',
						lazyLoad: false
					}}
					minDevice={Device.Computer}
				/>
			}
			testID={testID}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<Dropdown.Menu id={`${ID}Dropdown`} style={style}>
				{open ? (
					<>
						<Responsive maxDevice={Device.Tablet}>
							<div
								className='header'
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								<Header
									dividing
									text={getUserName()}
									subheader={getOrganizationName()}
									image={{
										avatar: true,
										src: user.image,
										circular: true
									}}
								/>
							</div>
						</Responsive>
						{getUserLinks()}
						{getOrganizations()}
						{getContractLinks()}
					</>
				) : null}
			</Dropdown.Menu>
		</Dropdown>
	);

	// $FlowIssue - React statics; Attach popup
	return !open ? Popup.attach({ text: <FormattedMessage id='component.user-menu.name' />, ...popup }, menu) : menu;
}

export default (React.memo(UserMenu): React.AbstractComponent<React.PropsOf<UserMenu>, mixed>);
