/**
 * @prettier
 * @flow
 */

import { orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { Dropdown, Popup } from 'liana-ui/components/';
import { Size } from 'liana-ui/types/';
import type { Params } from './Dropdown';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ */
component CountryDropdown(
	/** A country dropdown must have an input name */
	name: string,
	/** A country dropdown can have countries as options. Array of standard Liana®Cloud country objects. DATA[json/countries/countries.json] */
	countries: Array<{
		name: string,
		isoAlpha2: string
	}>,
	/** A country dropdown can have specific countries to place on top of the dropdown. */
	topCountries?: Array<string>,
	/** Initial value or value array if multiple. Use for uncontrolled components only. */
	defaultValue?: string | Array<string>,
	/** Current value or value array if multiple. Use for controlled components only. */
	value?: string | Array<string>,
	/**
			An dropdown can have a placeholder text.
			PROPS[React.Node=/localization/]
		*/
	placeholder?: React.Node,
	/** A country dropdown can allow multiple selections. */
	multiple?: boolean = false,
	/** A country dropdown can be searchable. */
	search?: boolean = true,
	/** A country dropdown can be clearable. */
	clearable?: boolean = false,
	/** A country dropdown can take the full width of its parent. */
	fluid?: boolean = true,
	/** A country dropdown can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean = false,
	/** A country dropdown can be  disabled. */
	disabled?: boolean = false,
	/** A country dropdown can be different size. */
	size?: Size,
	/**
			Popup text or, react-intl component or object of properties for Popup component.
			PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
		*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'CountryDropdown',
	/** Function called on input change. */
	onChange?: (event: SyntheticEvent<>, data: Params) => void
) {
	const intl = useIntl();

	// get options
	const getOptions = () => {
		let top = [];
		let options = [];

		if (topCountries) {
			for (let i = 0; i < topCountries.length; i++) {
				let isocode = topCountries[i];
				let name = countries.find((element) => element.isoAlpha2 === isocode);
				if (typeof name === 'string') {
					top.push(makeObject(getCountryName(isocode, name), isocode));
				}
			}
		}

		for (let i = 0; countries.length > i; i++) {
			let isocode = countries[i].isoAlpha2;
			let name = countries[i].name;
			if (!topCountries || (topCountries && !topCountries.includes(isocode))) {
				options.push(makeObject(getCountryName(isocode, name), isocode));
			}
		}

		options = orderBy(options, ['text'], ['asc']);
		options = top.concat(options);
		return options;
	};

	const getCountryName = (isocode: string, name: string) =>
		intl.formatMessage({ id: `country.${isocode}`, defaultMessage: name });

	const makeObject = (name: string, isokey: string) => ({
		text: name,
		value: isokey,
		flag: isokey.toLowerCase()
	});

	let dropdown = (
		<Dropdown
			name={name}
			defaultValue={defaultValue}
			value={value}
			placeholder={placeholder}
			options={getOptions()}
			fluid={fluid}
			multiple={multiple}
			search={search}
			clearable={clearable}
			locked={locked}
			disabled={disabled}
			size={size}
			popup={popup}
			testID={testID}
			onChange={onChange}
		/>
	);

	return dropdown;
}

export default (React.memo(CountryDropdown): React.AbstractComponent<React.PropsOf<CountryDropdown>, mixed>);
