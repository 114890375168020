/**
 * @prettier
 * @flow
 */

import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Breadcrumb, ContextMenu } from 'liana-ui/components';
import { Size } from 'liana-ui/types';
import TrailTools from './src/TrailTools';
import TrailContext from './src/TrailContext';

type SectionConfig = {
	page?: React.Node,
	link?: string,
	contextMenu?: React.PropsOf<ContextMenu>,
	testID?: string
};

/* Note: Do NOT use React.Memo for this component */
/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
component Trail(
	/** A trail must have sections for page navigation. */
	sections: Array<SectionConfig>,
	/** A trail van have extra actions placed on the right. */
	tools?: Array<React.Node>,
	/** A trail can display application name in browser page title. */
	appName?: string,
	/** A trail can have an icon before it. DATA[https://react.semantic-ui.com/elements/icon/] */
	icon?: string,
	/** A trail can be different size. */
	size?: Size,
	/** A trail can automatically update browser page title based on trail. */
	updateTitle?: boolean = true,
	/** Test ID for testing. */
	testID: string = 'Trail'
) {
	const intl = useIntl();
	let key = null,
		title = [];

	if (Array.isArray(sections) && sections.length > 0) {
		for (let i = 0; i < sections.length; i++) {
			key = sections[i].page; // Convenience
			title.push(
				// $FlowIssue - isValidElement doesn't properly refine React Elements
				React.isValidElement(key) && key.props?.id
					? intl.formatMessage({ id: key.props?.id }, key.props?.values) // Translate a <FormatMessage> component
					: key // Basic text
			);
		}

		if (updateTitle) {
			title.reverse();
			if (appName) {
				title.push(appName);
			}
			document.title = title.join(' - ');
		}
	}

	return (sections && sections.length > 0) || tools ? (
		<div className={classNames('trail', size)} data-testid={testID}>
			<Breadcrumb sections={sections} size={size} icon={icon} />
			<TrailTools tools={tools} />
		</div>
	) : null;
}

const MemoComponent = (React.memo(Trail): React.AbstractComponent<React.PropsOf<Trail>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Context = TrailContext;

export default MemoComponent;
