/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Responsive, Popup } from 'liana-ui/components/';
import { Table } from 'semantic-ui-react';
import { Device, VAlign, HAlign } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
component TableBodyCell(
	/** TODO: Undocumented prop */
	sticky?: any,
	/** TODO: Undocumented prop */
	hideTouch?: boolean,
	/** TODO: Undocumented prop */
	className?: string,
	/** Key */
	key?: string,
	/** Cell content. */
	content?: React.Node,
	/** Represents colspan of cell. */
	colSpan?: number,
	/** Represents rowspan of cell. */
	rowSpan?: number,
	/** A cell can be collapsing so that it only uses as much space as required. */
	collapsing: boolean = false,
	/** A cell can be selectable. */
	selectable?: boolean,
	/** A cell can appear sorted. */
	sorted?: boolean,
	/** A cell may call attention to a success or a positive value. */
	success?: boolean,
	/** A cell may call attention to a warning. */
	warning?: boolean,
	/** A cell may call attention to an error or a negative value. */
	error?: boolean,
	/** A cell may call attention as active. */
	active?: boolean,
	/** A cell may apper to be in off/inactive state. */
	off?: boolean,
	/** A cell may apper to be completally disabled . */
	disabled?: boolean,
	/** Keep cell opacity even if row is off . */
	ignoreOff?: boolean,
	/** Represents width of cell. Possible values: 1 - 12 */
	width?: string | number,
	/** A table cell can adjust its text alignment. */
	textAlign?: HAlign,
	/** A table cell can adjust its text alignment. */
	verticalAlign?: VAlign,
	/** A table cell can specify that its contents should remain on a single line, and not wrap. */
	singleLine?: boolean,
	/** A table cell can break very long strings in tight spaces into multiple lines. */
	breakWord?: boolean,
	/** A table cell can keep content on a single line without breaking it to separate lines. */
	noWrap?: boolean,
	/** Smallest device that cell will be displayed with. */
	minDevice?: Device,
	/** Largest device that cell will be displayed with. */
	maxDevice?: Device,
	/** Popup text or object of properties for Popup */
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing. */
	testID: string = 'TableBodyCell'
) {
	const getContent = () => {
		let ret = content;
		if (breakWord) {
			ret = <div className='break-word-wrap break-word'>{content}</div>;
		} else if (singleLine) {
			ret = (
				<div className='break-word-wrap'>
					<div className='overflow-ellipsis'>{content}</div>
				</div>
			);
		}
		return ret;
	};

	const createCell = () => {
		const classes = classNames(
			{
				sorted: sorted && !colSpan,
				nowrap: noWrap,
				active: active,
				off: off,
				disabled: disabled,
				'ignore-off': ignoreOff
			},
			sticky,
			className
		);

		let cell = (
			<Table.Cell
				key={key}
				data-testid={testID}
				className={classes}
				positive={success}
				warning={warning}
				error={error}
				colSpan={colSpan}
				rowSpan={rowSpan}
				collapsing={collapsing}
				selectable={selectable}
				width={width}
				textAlign={textAlign}
				verticalAlign={verticalAlign}
			>
				{getContent()}
			</Table.Cell>
		);

		// $FlowIssue - React statics; Attach popup
		return Popup.attach(popup, cell);
	};

	// Display reponsively
	let component =
		minDevice || maxDevice || hideTouch ? (
			<Responsive minDevice={minDevice} maxDevice={maxDevice} hideTouch={hideTouch}>
				{createCell()}
			</Responsive>
		) : (
			createCell()
		);

	return component;
}

export default (React.memo(TableBodyCell): React.AbstractComponent<React.PropsOf<TableBodyCell>, mixed>);
