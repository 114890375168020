/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Flag, Icon, Image } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

/** Checkbox label - Not designed to be used outside the Checkbox -component. */
component CheckboxLabel(
	/** Label text. */
	text?: React.Node,
	/** Label description. */
	description?: React.Node,
	/** Optional ID for Label. */
	htmlFor?: string,
	/** Image by url or object of props for Image. */
	image?: string,
	/** Icon by name or object of props for Icon. */
	icon?: string,
	/** Name for Flag. Optional Flag to display on the left of the Input. */
	flag?: string,
	/* Label can have additional classes. */
	className?: string,
	/* Test ID for testing. */
	testID: string = 'CheckboxLabel'
) {
	const getContent = () => {
		if (flag) {
			return <Flag name={flag} />;
		} else if (icon) {
			return <Icon name={icon} />;
		} else if (image) {
			return <Image src={image} size={Size.Favicon} inline />;
		}
		return null;
	};

	// Assign classes
	const classes = classNames(
		{
			'has-description': description
		},
		className
	);

	return (
		<label data-testid={testID} htmlFor={htmlFor} className={classes}>
			{getContent()}
			<span className='text'>{text}</span>
			{description ? <span className='description'>{description}</span> : null}
		</label>
	);
}

export default (React.memo(CheckboxLabel): React.AbstractComponent<React.PropsOf<CheckboxLabel>, mixed>);
