/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Validate } from 'liana-ui/definitions';
import { Button as SUIRButton } from 'semantic-ui-react';
import { Icon, IconGroup, Label, Popup, Responsive, Transition, ButtonGroup } from 'liana-ui/components/';
import { Float, Size, Device } from 'liana-ui/types';
import { On } from 'liana-ui/components/popup/Popup';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/button/ */
component Button(
	/** A button can have an id. */
	id?: string,
	/** A button can have a name. */
	name?: string,
	/** A button can have a different type. */
	type?: 'button' | 'submit' | 'cancel' = 'button',
	/**
		A button can have text.
		PROPS[React.Node=/localization/]
	*/
	text?: React.Node,
	/** A button can have a notification number. */
	notification?: number,
	/** A button can be a link. Opens absolute links in new browser tab and internal links via router. */
	link: string = '',
	/** A button can have an icon. PROPS[Icon=/components/labels/icons/icon/] */
	icon?: string | React.PropsOf<Icon>,
	/** A button can have multiple icons combined. PROPS[Icon=/components/labels/icons/icon/] */
	iconGroup?: Array<string> | Array<React.PropsOf<Icon>>,
	/** A button can have icon appear on the left or right of the text. */
	iconPosition?: Float = Float.Left,
	/** Color of the button. */
	color?: 'primary' | 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'grey',
	/** A button can be circular. */
	circular?: boolean = true,
	/** A button can be pronounced by having no borders. */
	basic?: boolean,
	/** A button can show it is currently active user selection */
	active: boolean = false,
	/** A button can show it is currently focused user selection */
	focused: boolean = false,
	/** A button can blink require more attension. */
	blinking: boolean = false,
	/** A button can have selected highlight color. */
	selected?: boolean,
	/** A button can show it is currently unnecessary to be interacted with. */
	off: boolean = false,
	/** A button can show it is currently unable to be interacted with. */
	disabled: boolean = false,
	/** A button can be hidden. */
	hidden: boolean = false,
	/** A button can show a loading indicator. */
	loading: boolean = false,
	/** A button can take the width of its container. */
	fluid: boolean = false,
	/** A button can keep all content on a sigle row withour ever breaking it to multiple lines. */
	noWrap?: boolean,
	/** A button can have no empty space around it. */
	fitted: boolean = false,
	/**  A button can be aligned to the left or right of its container. */
	floated?: Float,
	/** A button can have different sizes. */
	size?: Size,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch: boolean = false,
	/**
		A button can have a aria-label for accessability.
		PROPS[React.Node=/localization/]
	*/
	ariaLabel?: React.Node,
	/** Whether or not button should blur on click */
	blurOnClick: boolean = false,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/* A button can have additional classes. Use for very special features only! */
	className?: string,
	/** Test ID for testing */
	testID: string = 'Button',
	/** Function called on button click. */
	onClick?: (
		event: SyntheticInputEvent<>,
		data: {
			name: string,
			off: boolean
		}
	) => void
) {
	const navigate = useNavigate();

	// Variables and refs
	const buttonRef = useRef(null);

	// Get link type
	let linkType = Validate.linkType(link);

	const handleClick = (event: SyntheticInputEvent<>, data: any) => {
		// Blur button on click
		// $FlowIssuee - Hard to distinguish between object & React.Node
		if (blurOnClick || (popup?.content && popup?.on === On.Hover)) {
			if (buttonRef && buttonRef.current && buttonRef.current.ref && buttonRef.current.ref.current) {
				buttonRef.current.ref.current.blur();
			}
		}

		// Trigger onClick callback funtion
		if (typeof onClick === 'function') {
			onClick(event, handleCallbackData(data));
		}

		// Trigger internal link
		if (linkType && (linkType === 'internal' || linkType === 'anchor')) {
			event.preventDefault();
			if (linkType === 'internal') {
				navigate(link);
			}
			if (linkType === 'anchor') {
				Safely.scroll(link, () => {
					navigate(`${window.location.pathname}${link}`);
				});
			}
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => ({
		name: data.name,
		off: Boolean(off)
	});

	// Get hidden text for better accessability.
	const getAriaLabel = () => {
		let text = ariaLabel
			? ariaLabel
			: typeof popup === 'string' || (typeof popup === 'object' && React.isValidElement(popup))
				? popup
				: // $FlowIssue - Hard to distinguish between object & React.Node
					typeof popup?.text === 'string' || (typeof popup === 'object' && React.isValidElement(popup?.text))
					? popup.text
					: null;
		return typeof text === 'string' ? text : 'Button';
	};

	// Function to generate LianaUI Button
	const createButton = () => {
		// Assign classes
		const classes = classNames(
			{
				notification: notification !== undefined,
				blinking: blinking,
				off: off,
				selected: selected,
				focused: focused,
				fitted: fitted,
				nowrap: noWrap,
				invisible: hidden,
				cancel: type === 'cancel',
				icon: (icon || iconGroup) && !text,
				icons: iconGroup && !text,
				primary: color === 'primary',
				extramini: size === Size.ExtraMini
			},
			className
		);

		// Assign Icon props
		let iconData = typeof icon === 'string' ? { name: icon } : icon;

		// Define Button
		let button = (
			<SUIRButton
				ref={buttonRef}
				id={id}
				name={name}
				aria-label={getAriaLabel()}
				href={link}
				target={linkType === 'external' ? '_blank' : undefined}
				rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
				type={link ? null : type === 'cancel' ? 'button' : type}
				className={classes}
				basic={basic}
				circular={circular}
				color={color && color !== 'primary' ? color : undefined}
				disabled={disabled || loading}
				active={active}
				loading={loading}
				fluid={fluid}
				floated={floated}
				size={size !== Size.ExtraMini ? size : undefined}
				onClick={handleClick}
				data-testid={testID}
			>
				<Transition visible={typeof notification === 'number' && notification > 0} animation='scale'>
					<span className='notification-label'>
						<Label notification size={Size.Mini} text={notification} />
					</span>
				</Transition>
				{typeof iconData === 'object' && iconPosition === Float.Left ? <Icon {...iconData} /> : null}
				{iconGroup && iconGroup.length > 0 && iconPosition === Float.Left ? (
					<IconGroup
						icons={
							typeof iconGroup[0] === 'string' && typeof iconGroup[1] === 'string'
								? [
										{
											name: iconGroup[0],
											inverted: color && !disabled && !off
										},
										{
											name: iconGroup[1]
										}
									]
								: iconGroup
						}
					/>
				) : null}
				{text}
				{typeof iconData === 'object' && iconPosition === Float.Right ? (
					<Icon {...iconData} iconPosition={Float.Right} />
				) : null}
				{iconGroup && iconGroup.length > 0 && iconPosition === Float.Right ? (
					<IconGroup
						iconPosition='right'
						size={text ? undefined : Size.Large}
						icons={
							typeof iconGroup[0] === 'string'
								? // $FlowIssue - Flow doesn't know what it wants
									[
										{
											name: iconGroup[0],
											inverted: color && !disabled && !off
										},
										{
											name: iconGroup[1]
										}
									]
								: iconGroup
						}
					/>
				) : null}
			</SUIRButton>
		);

		// $FlowIssue - React statics; Attach popup
		button = Popup.attach(popup, button);

		// If has content popup add another popup to show also text tooltip popup
		if (popup && typeof popup === 'object' && popup.text && popup.content) {
			button = (
				<Popup
					// $FlowIssue - Impossible type refinement
					text={popup.text}
					// $FlowIssue - Impossible type refinement
					position={popup.position ? popup.position : undefined}
					// $FlowIssue - Impossible type refinement
					size={popup.size ? popup.size : undefined}
					trigger={<span className='inline-block'>{button}</span>}
				/>
			);
		}

		return button;
	};

	// Display reponsively
	let component =
		minDevice || maxDevice || hideTouch ? (
			<Responsive hideTouch={hideTouch} minDevice={minDevice} maxDevice={maxDevice}>
				{createButton()}
			</Responsive>
		) : (
			createButton()
		);

	return component;
}

const MemoComponent = (React.memo(Button): React.AbstractComponent<React.PropsOf<Button>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Group = ButtonGroup;

export default MemoComponent;
