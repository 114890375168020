/**
 * @prettier
 * @flow
 */

import { useRef, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Chart from 'liana-ui/legacy/components/chart/Chart';
import { VAlign, HAlign, Spacing, Size } from 'liana-ui/types';
import {
	Button,
	Card,
	Text,
	Grid,
	List,
	Segment,
	Divider,
	Checkbox,
	Dropdown,
	ItemGroup,
	CardGroup,
	ChartInfo,
	EmptyState,
	Message,
	ActionHeader,
	GridColumn,
	ButtonGroup,
	Item
} from 'liana-ui/components/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/item/#types-items */
component ActionItems(
	/** An action items list must be provided with the total amount of paginated items. */
	totalItems: number,
	/** If an action items has stacks, it must be provided with the total amount of paginated items and items inside stacks. */
	totalDeepItems?: number,
	/** 
	 	An action items list must have items or cards. 
	 	PROPS[Item=/components/items/items/item/, Card=/components/cards/card/]
	*/
	items: Array<React.PropsOf<Item>> | Array<React.PropsOf<Card>>,
	/**
		An action items list may have an action header with various buttons and filters.
		PROPS[ActionHeader=/components/containers/action-header/]
	*/
	actionHeader?: React.PropsOf<ActionHeader>,
	/** An item actions can have different layouts. */
	layout?: 'small' | 'big' = 'big',
	/** 
	  	An items can limit header to maximum amount of lines with ellipsis. 
	 	VALUES[1 - 10]
	*/
	limitHeader?: number,
	/** 
	    An item can limit description to maximum amount of lines with ellipsis. 
		VALUES[1 - 10]			
	*/
	limitDescription?: number,
	/** An items list can appear as a segment. */
	segment?: boolean = true,
	/**
		An action items list can have a chart and chart info.
		PROPS[ChartProps=/components/statistics/charts/, ChartInfo=/components/statistics/charts/filter]
	*/
	chart?:
		| {
				chart: any,
				chartInfo: React.PropsOf<ChartInfo>
		  }
		| React.Node,
	/**
		Represents column count per row in card Grid.
		VALUES['equal' | 1 - 12]
	*/
	columns?: string | number,
	/**
		Represents column count per row for device in card Grid.
		VALUES[{ mobile: 1 - 12, tablet: 1 - 12, computer: 1 - 12, largeScreen: 1 - 12, wideScreen: 1 - 12, hugeScreen: 1 - 12, massiveScreen: 1 - 12 }]
	*/
	responsiveColumns?: {
		mobile?: number,
		tablet?: number,
		computer?: number,
		largeScreen?: number,
		wideScreen?: number,
		hugeScreen?: number,
		massiveScreen?: number
	},
	/**
		A column can specify a width for a mobile device.
		VALUES[1 - 12]
	*/
	mobile?: number,
	/**
		A column can specify a width for a tablet device.
		VALUES[1 - 12]
	*/
	tablet?: number,
	/**
		A column can specify a width for a computer.
		VALUES[1 - 12]
	*/
	computer?: number,
	/**
		A column can specify a width for a large screen device.
		VALUES[1 - 12]
	*/
	largescreen?: number,
	/**
		A column can specify a width for a wide screen device.
		VALUES[1 - 12]
	*/
	widescreen?: number,
	/** 
	 	An action items list can display different components. 
	 	PROPS[Button=/components/buttons/button/]
	*/
	display?: {
		type: string,
		buttons?: Array<React.PropsOf<Button>>
	} = {
		type: 'items'
	},
	/** A item group can vertically align items. */
	verticalAlign?: VAlign = VAlign.Middle,
	/** 
	 	An action items list can allow a user to sort items. 
	 	PROPS[DropdownProps=/components/dropdown/dropdown/]
	*/
	sort?: React.PropsOf<Dropdown>,
	/** An action items list can allow a user to select items. */
	select?: {
		enableSelectAll: boolean,
		selectedItems: Array<string>,
		onSelect: (
			event: SyntheticEvent<>,
			data: {
				name: string,
				value: string | number,
				checked: boolean,
				selected: Array<string>,
				selectedAmount: number
			}
		) => void,
		allListSelected: boolean,
		onSelectAllList: (event: SyntheticEvent<>) => void,
		onClearAllList: (event: SyntheticEvent<>) => void
	},
	/** An action items can allow to paginate rows. */
	paginate?: {
		currentPage: number,
		paginateAmount: number,
		loadingMore: boolean,
		onLoadMore: (
			event: SyntheticEvent<>,
			data: {
				currentPage: number
			}
		) => void
	},
	/** An action items list must have an empty state. PROPS[...EmptyState=/components/feedback/empty-state/empty-state]  */
	emptyState:
		| {
				displayEmptyState: boolean,
				...React.PropsOf<EmptyState>
		  }
		| {} = {},
	/** TODO: Undocumented prop */
	limit?: any,
	/** TODO: Undocumented prop */
	size: Size,
	/** TODO: Undocumented prop */
	renderItem: (data: any, index: number) => React.PropsOf<Item>,
	/** TODO: Undocumented prop */
	loading: boolean = false,
	/** Test ID for testing. */
	testID: string = 'ActionItems',
	/** An action table can override some default translation keys. */
	translations?: {
		totalItemsLabel: string,
		noResults: string,
		selectedOnPage: string,
		selectedOnList: string,
		selectAllAmount: string
	},
	/** An action items should show an animation (glow) to added rows. Must match the item IDs of the items to be animated also by type. */
	addedItems?: Array<number | string>,
	/** An action items list should show an animation (fly away) on deleted items. Must match the item IDs of the rows to be animated also by type. */
	deletedItems?: Array<string>,
	/** Callback on when an delete animation (fly away) is complete. Must match the item IDs of the rows to be animated also by type. Call API to remove the item from database and and re-render list. Remember also to clear deletedItems in this callback. */
	onAfterDeleteItems?: () => void
) {
	// Variables and states
	const [stickyHeight, setStickyHeight] = useState(0);
	const [randomItemsID] = useState(`actionitems-${Date.now()}-${Math.round(Math.random() * 9999)}`);

	// Merge translations
	const mergedTranslations = {
		totalItemsLabel: 'component.action-items.totalItemsLabel',
		noResults: 'component.action-items.noResults',
		selectedOnPage: 'component.action-table.selectedOnPage',
		selectedOnList: 'component.action-table.selectedOnList',
		selectAllAmount: 'component.action-table.selectAllAmount',
		...translations
	};

	// Merge empty state
	const { displayEmptyState, ...rest } = emptyState;

	const mergedEmptyState = {
		image: `${process.env.baseUrl || ''}img/empty-states/empty-default.png`,
		header: <FormattedMessage id='component.action-items.emptyStateHeader' />,
		content: <FormattedMessage id='component.action-items.emptyStateContent' />,
		...rest
	};

	// Internal variables and states
	let INTERNAL_addedItems = useRef(addedItems ? [...addedItems] : []),
		INTERNAL_selectedItems = select && select.selectedItems ? [...select.selectedItems] : [];
	const [selectedItemsAmount, setSelectedItemsAmount] = useState(0);

	const getHeaderLabels = () => {
		let labels: any = [],
			deepItems = totalDeepItems || totalItems;

		if (limit?.limit) {
			let limitLabel = { ...limit, amount: deepItems };
			// Use translations.totalItemsLabel for limit label if limit.tranlations.amount not provided
			if (!limit.translations?.amount) {
				limitLabel.translations = {
					...limitLabel.translations,
					amount: mergedTranslations.totalItemsLabel
				};
			}
			labels.push(limitLabel);
		} else {
			labels.push({
				text: <FormattedMessage id={mergedTranslations.totalItemsLabel} values={{ amount: deepItems }} />
			});
		}

		if (actionHeader?.header?.labels) {
			labels = Array.isArray(actionHeader.header.labels)
				? [...labels, ...actionHeader.header.labels]
				: [...labels, ...[actionHeader.header.labels]];
		}

		return labels;
	};

	const getMassOptions = () => {
		let options = actionHeader?.massOptions?.concat({ divider: true }) || [];

		if (hasAllVisibleItemsSelected() && !select?.allListSelected && hasSelectAllListEnabled()) {
			options = options?.concat({
				text: (
					<FormattedMessage
						id='component.action-items.selectAllAmount'
						values={{ amount: totalDeepItems || totalItems }}
					/>
				),
				icon: 'fa-check',
				onClick: handleSelectAllList
			});
		}

		options = options?.concat([
			{
				text: <FormattedMessage id='component.action-items.clearSelected' />,
				icon: 'fa-remove',
				onClick: (event) => handleSelect(event, { value: 'clear' })
			}
		]);

		return options;
	};

	const formatItems = (items: Array<React.PropsOf<Card> | React.PropsOf<Item>>): Array<any> => {
		let formatted = [];
		items.map((item: React.PropsOf<Card> | React.PropsOf<Item>) => {
			let selected =
					select && Array.isArray(select.selectedItems)
						? INTERNAL_selectedItems.indexOf(item.id) !== -1
							? true
							: false
						: undefined,
				added =
					Array.isArray(addedItems) &&
					addedItems.indexOf(item.id) !== -1 &&
					INTERNAL_addedItems.current.indexOf(item.id) === -1,
				deleted = Array.isArray(deletedItems) && deletedItems.indexOf(item.id) !== -1;

			formatted.push({
				...item,
				selected: selected,
				deleted: deleted,
				added: added,
				onAfterDeleteItems: onAfterDeleteItems
			});

			// Disable added item glow animation after it has completed
			if (Array.isArray(addedItems) && addedItems.indexOf(item.id) !== -1) {
				setTimeout(() => INTERNAL_addedItems.current.push(item.id), 1000);
			}
		});

		return formatted;
	};

	const handleLoadMore = (event: SyntheticEvent<>) => {
		// Trigger onLoadMore callback funtion
		if (paginate && typeof paginate.onLoadMore === 'function') {
			paginate.onLoadMore(event, {
				currentPage: paginate.currentPage + 1
			});
		}
	};

	const handleSelect = (event: SyntheticEvent<>, data: any) => {
		if (data.value === 'all') {
			INTERNAL_selectedItems = data.checked ? items.map((row) => row.id) : [];
		} else if (data.value === 'clear') {
			INTERNAL_selectedItems = [];
		} else if (data.checked) {
			INTERNAL_selectedItems = [...INTERNAL_selectedItems, ...[data.value]];
		} else {
			INTERNAL_selectedItems.splice(INTERNAL_selectedItems.indexOf(data.value), 1);
		}

		let deepSelectedItems = countDeepSelectedItems(INTERNAL_selectedItems);
		setSelectedItemsAmount(deepSelectedItems);

		// Trigger onSelect callback funtion
		if (select && typeof select.onSelect === 'function') {
			select.onSelect(event, {
				...data,
				selected: INTERNAL_selectedItems,
				selectedAmount: deepSelectedItems
			});
		}
	};

	const countDeepSelectedItems = (selectedIds: Array<string>) => {
		let totalSelected = selectedIds.length;
		for (let i = 0; i < selectedIds.length; i++) {
			let selectedItem = items.filter((item) => item.id == selectedIds[i])[0];
			if (typeof selectedItem?.stacked?.stackedItems === 'number') {
				totalSelected = totalSelected + selectedItem.stacked.stackedItems - 1;
			}
		}
		return totalSelected;
	};

	const handleSelectAllList = (event: SyntheticEvent<>) => {
		event.preventDefault();
		handleSelect(event, { value: 'all', checked: true });
		//setAllListSelected(true);
		// Trigger select.onSelectAllList callback funtion
		if (select && typeof select.onSelectAllList === 'function') {
			select.onSelectAllList(event);
		}
	};

	const handleClearAllList = (event: SyntheticEvent<>) => {
		event.preventDefault();
		handleSelect(event, { value: 'clear' });
		// Trigger select.onClearAllList callback funtion
		if (select && typeof select.onClearAllList === 'function') {
			select.onClearAllList(event);
		}
	};

	const hasAllVisibleItemsSelected = () => {
		return select && Array.isArray(select.selectedItems)
			? items.length === select.selectedItems.length
				? true
				: false
			: undefined;
	};

	const hasSomeVisibleItemsSelected = () => {
		return select && Array.isArray(select.selectedItems)
			? select.selectedItems.length > 0 && items.length > select.selectedItems.length
				? true
				: false
			: undefined;
	};

	const hasSelectAllListEnabled = () => {
		return (
			typeof select?.onSelectAllList === 'function' &&
			typeof select?.onClearAllList === 'function' &&
			typeof paginate?.paginateAmount === 'number' &&
			totalItems > paginate.paginateAmount &&
			items.length < totalItems
		);
	};

	// Assign classes
	const itemsWrapperClasses = classNames('actionitems-list-wrapper', {
		'has-header': actionHeader || !isNaN(totalItems)
	});

	let currentPage = paginate ? paginate.currentPage : undefined,
		paginateAmount = paginate ? paginate.paginateAmount : undefined,
		nextMin = 0,
		max = 0,
		nextMax = 0,
		showMore = false;

	if (typeof currentPage == 'number' && typeof paginateAmount === 'number') {
		nextMin = currentPage * paginateAmount;
		max = (currentPage + 1) * paginateAmount;
		nextMax = max > totalItems ? totalItems : max;
		showMore = totalItems > nextMin;
	}

	// Define ActionItems
	let actionItems = displayEmptyState ? (
		<Segment raised padded='very'>
			<EmptyState {...mergedEmptyState} />
		</Segment>
	) : (
		<>
			<Segment
				basic={!segment}
				raised={segment}
				removePaddings={Spacing.All}
				removeMargins={!segment ? Spacing.All : showMore ? Spacing.Bottom : undefined}
				raised
			>
				<div className='actionitems-wrapper' id={randomItemsID} data-testid={testID}>
					{actionHeader || !isNaN(totalItems) ? (
						<ActionHeader
							{...actionHeader}
							massContextMenu={
								// $FlowIgnore[react-rule-unsafe-ref] - undefined is safe to use
								INTERNAL_selectedItems.length > 0
									? {
											text: (
												<FormattedMessage
													id='component.action-items.amountSelected'
													values={{
														amount: select?.allListSelected
															? totalDeepItems || totalItems
															: selectedItemsAmount
													}}
												/>
											),
											options: getMassOptions()
										}
									: undefined
							}
							sticky={!document.querySelector('html')?.classList.contains('mobile')}
							bottomBorder
							header={{
								...actionHeader?.header,
								labels: getHeaderLabels()
							}}
							size={size}
							scrollTo={`#${randomItemsID}`}
							onSizeChange={(element) =>
								!document.querySelector('html')?.classList.contains('mobile')
									? setStickyHeight(element.offsetHeight)
									: setStickyHeight(0)
							}
						/>
					) : null}
					{React.isValidElement(chart) ? (
						<>
							<Segment basic compressed removeMargins={Spacing.All}>
								{/** $FlowIssue - Impossible to refine object vs. React.Node */}
								{chart}
							</Segment>
							<Divider removeMargins={Spacing.All} />
						</>
					) : /** $FlowIssue - Impossible to refine object vs. React.Node */
					chart?.chart ? (
						<>
							<Segment basic compressed removeMargins={Spacing.All}>
								{chart.chartInfo ? (
									<Grid stackable stretched>
										<GridColumn width={9}>
											<Chart isAnimated isBoxed {...chart.chart} />
										</GridColumn>
										<GridColumn width={3}>
											<ChartInfo {...chart.chartInfo} />
										</GridColumn>
									</Grid>
								) : (
									<Chart isAnimated isBoxed {...chart.chart} />
								)}
							</Segment>
							<Divider removeMargins={Spacing.All} />
						</>
					) : null}
					{items.length > 0 && (select?.enableSelectAll || display?.buttons || sort) ? (
						<Segment
							className='actionitems-header'
							style={{ top: stickyHeight }}
							squared
							secondary
							basic
							compressed
							removeMargins={Spacing.All}
							removePaddings={Spacing.Vertical}
						>
							<Segment
								basic
								compressed='very'
								removeMargins={Spacing.All}
								removePaddings={Spacing.Horizontal}
							>
								<Grid compact verticalAlign={VAlign.Middle} columns={2}>
									{select?.enableSelectAll ? (
										<GridColumn collapsing>
											<Checkbox
												name='select-all'
												label={<FormattedMessage id='component.action-items.selectAll' />}
												checked={hasAllVisibleItemsSelected()}
												indeterminate={hasSomeVisibleItemsSelected()}
												value='all'
												disabled={totalItems == 0}
												size={size}
												onChange={handleSelect}
											/>
										</GridColumn>
									) : null}
									{sort ? (
										<GridColumn collapsing>
											<Dropdown {...sort} size={Size.Tiny} />
										</GridColumn>
									) : null}
									{display.buttons ? (
										<GridColumn collapsing>
											<ButtonGroup fitted buttons={display.buttons} size={Size.Mini} />
										</GridColumn>
									) : null}
								</Grid>
							</Segment>
						</Segment>
					) : null}
					{select?.enableSelectAll && hasSelectAllListEnabled() && items.length > 0 ? (
						<Segment
							basic
							compressed
							removeMargins={Spacing.All}
							className={classNames('select-all-info', {
								hidden: !hasAllVisibleItemsSelected()
							})}
						>
							{select?.allListSelected ? (
								<List
									horizontal={true}
									divided={true}
									items={[
										{
											content: (
												<FormattedMessage
													id={mergedTranslations.selectedOnList}
													values={{
														amount: <Text bold>{totalDeepItems || totalItems}</Text>
													}}
												/>
											)
										},
										{
											content: (
												<a href='#' onClick={handleClearAllList}>
													<FormattedMessage id='component.action-items.clearSelected' />
												</a>
											)
										}
									]}
								/>
							) : (
								<List
									horizontal={true}
									divided={true}
									items={[
										{
											content: (
												<FormattedMessage
													id={mergedTranslations.selectedOnPage}
													values={{
														amount: <Text bold>{selectedItemsAmount}</Text>
													}}
												/>
											)
										},
										{
											content: (
												<a href='#' onClick={handleSelectAllList}>
													<FormattedMessage
														id={mergedTranslations.selectAllAmount}
														values={{
															amount: <Text bold>{totalDeepItems || totalItems}</Text>
														}}
													/>
												</a>
											)
										}
									]}
								/>
							)}
						</Segment>
					) : null}
					<>
						{items.length > 0 ? (
							<Segment
								basic
								compressed
								removePaddings={display.type === 'items' ? Spacing.All : undefined}
								removeMargins={Spacing.All}
								className={itemsWrapperClasses}
								loading={loading ? 'scrolling' : undefined}
							>
								{display.type === 'cards' ? (
									<CardGroup
										/** $FlowFixMe - Items can be either <Card> or <Item> */
										items={formatItems(items)}
										renderItem={renderItem}
										layout={layout}
										limitHeader={limitHeader}
										limitDescription={limitDescription}
										columns={columns}
										responsiveColumns={responsiveColumns}
										mobile={mobile}
										tablet={tablet}
										computer={computer}
										largescreen={largescreen}
										widescreen={widescreen}
										onSelect={select ? handleSelect : undefined}
									/>
								) : display.type === 'items' ? (
									<ItemGroup
										/** $FlowFixMe - Items can be either <Card> or <Item> */
										items={formatItems(items)}
										renderItem={renderItem}
										layout={layout}
										limitHeader={limitHeader}
										limitDescription={limitDescription}
										verticalAlign={verticalAlign}
										onSelect={select ? handleSelect : undefined}
									/>
								) : null}
							</Segment>
						) : (
							<Segment basic compressed removeMargins={Spacing.All}>
								<Message
									info
									content={<FormattedMessage id={mergedTranslations.noResults} />}
									icon='fa-info-circle'
								/>
							</Segment>
						)}
					</>
				</div>
			</Segment>
			{showMore ? (
				<Segment
					basic
					compressed
					textAlign={HAlign.Center}
					removeMargins={Spacing.Top}
					className='item-pagination-wrapper'
				>
					<Button
						text={
							<FormattedMessage
								id='component.action-table.showMore'
								values={{ min: nextMin, max: nextMax, total: totalItems }}
							/>
						}
						circular
						icon={{ name: 'fa-chevron-down', solid: true }}
						loading={paginate && paginate.loadingMore}
						onClick={handleLoadMore}
					/>
				</Segment>
			) : null}
		</>
	);

	return actionItems;
}

export default (React.memo(ActionItems): React.AbstractComponent<React.PropsOf<ActionItems>, mixed>);
