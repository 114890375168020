/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Sidebar as SUIRSidebar } from 'semantic-ui-react';
import { Segment, Header, Button } from 'liana-ui/components/';
import { Width, Size, Spacing } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/sidebar/ */
component Sidebar(
	/** Content as children. */
	children: React.Node,
	/** 
	 	A modal can have a header text. 
	 	PROPS[React.Node=/language/localisation/, Header=/components/texts/header]
	*/
	header?: React.Node | React.PropsOf<Header>,
	/** Controls whether or not the sidebar is visible on the page. */
	visible: boolean = false,
	/** Controls whether or not the sidebar has padding. */
	padded: boolean = true,
	/** A panel or modal can have different widths. */
	width?: Width,
	/** Test ID for testing. */
	testID: string = 'Sidebar',
	/** Called before a sidebar begins to animate out. */
	onHide?: (event: SyntheticEvent<>) => void
) {
	const html = document.querySelector('html');
	let isMobile = html ? html.classList.contains('mobile') : false;

	const handleHide = (event: SyntheticEvent<>) => {
		if (typeof onHide === 'function') {
			onHide(event);
		}
	};

	const getHeader = () => {
		if (header) {
			const closeButton = <Button icon='close' size={Size.Mini} fitted circular onClick={handleHide} />;
			if (typeof header === 'string') {
				return (
					<Header
						text={<FormattedMessage id={header} />}
						size={Size.Large}
						dividing
						floatedContent={closeButton}
					/>
				);
			} else if (typeof header === 'object') {
				// $FlowFixMe[cannot-spread-interface] - No solution yet for spread-operators
				return <Header {...header} size='large' dividing floatedContent={closeButton} />;
			}
		} else {
			return null;
		}
	};

	return (
		<SUIRSidebar
			visible={visible}
			width={width}
			animation='overlay'
			direction={isMobile ? 'bottom' : 'right'}
			data-testid={testID}
			onHide={handleHide}
		>
			{padded ? (
				<Segment basic removeMargins={Spacing.Bottom}>
					{getHeader()}
					{children}
				</Segment>
			) : (
				<>
					{getHeader()}
					{children}
				</>
			)}
		</SUIRSidebar>
	);
}

const MemoComponent = (React.memo(Sidebar): React.AbstractComponent<React.PropsOf<Sidebar>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Pushable = Sidebar.Pushable;
// $FlowIssue - prettier-ignore - Static subcomponents
MemoComponent.Pusher = Sidebar.Pusher;

export default MemoComponent;
