/**
 * @prettier
 * @flow
 */

import { Statistic } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/statistic */
component StatisticValue(
	/** An element type to render as */
	as: React.Node,
	/** Link for NavLink */
	to: string,
	/** Statistic value as number. */
	content: number | React.Node,
	/** Test ID for testing. */
	testID: string = 'StatisticValue'
) {
	return (
		<Statistic.Value as={as} to={to} data-testid={testID}>
			{content}
		</Statistic.Value>
	);
}

export default (React.memo(StatisticValue): React.AbstractComponent<React.PropsOf<StatisticValue>, mixed>);
