/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Validate } from 'liana-ui/definitions';
import { Card as SUIRCard } from 'semantic-ui-react';
import {
	Popup,
	Text,
	Header,
	Image,
	Button,
	ContextMenu,
	Checkbox,
	Label,
	LimitLabel,
	List,
	NavLink,
	Transition,
	ButtonGroup,
	LabelGroup,
	Visibility
} from 'liana-ui/components/';
import ListItem from 'liana-ui/components/list/src/ListItem';
import { Size, Float, Target } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/card/ */
component Card(
	/** 
		A card can have a header.
		PROPS[React.Node=/language/localisation]
	*/
	header: React.Node,
	/** A selectable or identifiable card must have an id */
	id: string,
	/**
		A card can have and image.
		PROPS[Image=/components/texts/image]
	*/
	image?: string | React.PropsOf<Image>,
	/** Only the card header can be a link. Opens absolute links in new browser tab and internal links via router. */
	headerLink?: string,
	/** The whole card can be a link. Opens absolute links in new browser tab and internal links via router. */
	itemLink?: string,
	/** 
		A card can have a description.
		PROPS[React.Node=/language/localisation]
	*/
	description?: React.Node | React.Node,
	/** 
		A card can sub info text or content.
		PROPS[ListProps=/components/containers/list]
	*/
	subinfo?: Array<React.Node | React.PropsOf<ListItem>>,
	/**
		A header can have meta labels and/or limit label on top.
		PROPS[Label=/components/labels/labels/label/, LimitLabel=/components/labels/limit-label/]
	*/
	metaLabels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
	/**
		A header can have labels and/or limit label in content.
		PROPS[Label=/components/labels/labels/label/, LimitLabel=/components/labels/limit-label/]
	*/
	labels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
	/** 
		A card can have action buttons or context menus. Max 5 recommended.
		PROPS[Button=/components/buttons/button/, ContextMenu=/components/menus/context-menu] 
	*/
	actions?: Array<React.PropsOf<Button> | React.PropsOf<ContextMenu>>,
	/** 
		A card can have single button. 
		PROPS[Button=/components/buttons/button/]
	*/
	button?: React.PropsOf<Button>,
	/** 
		A card can have a button group. 
		PROPS[ButtonGroup=/components/buttons/button-group/]
	*/
	buttonGroup?: React.PropsOf<ButtonGroup>,
	/** A card can have different layouts. */
	layout?: 'small' | 'big' = 'big',
	/** 
		A card can limit header to maximum amount of lines followed by ellipsis.
		VALUES[1 - 10]
	*/
	limitHeader?: number,
	/** 
		A card can limit description to maximum amount of lines followed by ellipsis.
		VALUES[1 - 10]			
	*/
	limitDescription?: number,
	/** A card can be new. */
	'new' as isNew?: boolean = false,
	/** A card can be in a selected state. */
	selected?: boolean,
	/** A card can be in a viewed state. */
	viewed?: boolean = false,
	/** An item can glow when added. */
	added: boolean = false,
	/** A card can animate (scale away) when deleted. */
	deleted: boolean = false,
	/** A card can take the width of its container. */
	fluid?: boolean,
	/** An card can be stacked and contain multiple stacked items. */
	stacked?:
		| boolean
		| {
				stackedItems: number,
				header?: React.PropsOf<Header>,
				metaLabels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
				onClick?: () => void
		  },
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** A card can have extra description or elements below the textuak description. */
	extraDescription?: React.Node,
	/** A card can have different sizes. */
	size?: Size,
	/** Test ID used for testing. */
	testID: string = 'Card',
	/** Function called on card select. */
	onSelect?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			checked: boolean
		}
	) => void,
	/** Function called on card click. */
	onClick?: (event: SyntheticEvent<>) => void,
	/** Function called on card header click. */
	onHeaderClick?: () => void,
	/** Card's top edge has passed bottom of screen. */
	onTopVisible?: (id: number | string) => void,
	/** Card's bottom edge has passed bottom of screen. */
	onBottomVisible?: (id: number | string) => void,
	/** Callback on when delete animation ends. */
	onAfterDeleteItems?: () => void
) {
	const navigate = useNavigate();

	// Get link type
	let headerLinkType = Validate.linkType(headerLink);
	let itemLinkType = Validate.linkType(itemLink);

	const handleContainerClick = (event: SyntheticEvent<HTMLButtonElement>) => {
		// Trigger onClick callback funtion
		if (typeof onClick === 'function') {
			onClick(event);
		}

		// Trigger internal link
		if (itemLink && itemLinkType && (itemLinkType === 'internal' || itemLinkType === 'anchor')) {
			event.preventDefault();
			if (itemLinkType === 'internal') {
				navigate(itemLink);
			}
			if (itemLinkType === 'anchor') {
				Safely.scroll(itemLink, () => {
					navigate(`${window.location.pathname}${itemLink}`);
				});
			}
		}
	};

	const getActions = () => {
		let ret = [];
		if (Array.isArray(actions)) {
			for (let i = 0; i < actions.length; i++) {
				// $FlowFixMe[prop-missing] - Not possible to distinquish
				if (typeof actions[i].button === 'object') {
					let button = actions[i].button;

					if (typeof button.popup === 'string' || React.isValidElement(button?.popup)) {
						button.popup = {
							text: button.popup,
							delay: 500
						};
					}
					ret.push(
						<div className='column' key={`button-column-${i}`}>
							<Button {...button} basic fitted size={Size.Tiny} noWrap />
						</div>
					);
					// $FlowFixMe[prop-missing] - Not possible to distinquish
				} else if (typeof actions[i].contextMenu === 'object') {
					ret.push(
						<div className='column'>
							<ContextMenu
								{...actions[i].contextMenu}
								fitted
								basic
								size={Size.Tiny}
								direction='left'
								pointing='bottom'
							/>
						</div>
					);
				}
			}
		}
		return ret.length > 0 ? (
			<SUIRCard.Content extra className='item-actions'>
				<div
					className={classNames('item-actions-wrapper', {
						one: ret.length === 1,
						two: ret.length === 2,
						three: ret.length === 3,
						four: ret.length === 4,
						five: ret.length === 5
					})}
				>
					{ret}
				</div>
			</SUIRCard.Content>
		) : null;
	};

	const getButtons = () => {
		return (
			<>
				{button ? (
					<SUIRCard.Content extra>
						<Button {...button} fluid size={Size.Small} />
					</SUIRCard.Content>
				) : null}
				{buttonGroup ? (
					<SUIRCard.Content extra>
						<ButtonGroup {...buttonGroup} fluid size={Size.Small} />
					</SUIRCard.Content>
				) : null}
			</>
		);
	};

	const getmetaLabels = (
		labels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
		labelsSize?: Size = Size.Small
	) => {
		return metaLabels ? (
			<div className='meta-label-container'>
				<LabelGroup as='span' size={labelsSize} labels={labels} limitCharacters={20} />
			</div>
		) : null;
	};

	// Assign image classes
	let imageClasses = classNames('ui image image-wrapper', {
		'image-ratio': typeof image === 'object' && image.ratio,
		'mini right floated': layout === 'small'
	});

	// Count fluid placeholder ratio
	let styles = {};
	if (typeof image === 'object' && typeof image.ratio === 'string' && image.ratio.indexOf('/') > -1) {
		// $FlowIssue[incompatible-use] - Already checked above
		let ratio: Array<number> = layout === 'big' ? image.ratio.split('/').map(Number) : [1, 1];
		styles = {
			paddingTop: (ratio[1] / ratio[0]) * 100 + '%'
		};
	}

	// Delete transition
	let visible = deleted ? !deleted : true,
		transitionOnMount = deleted ? false : added ? true : undefined,
		animation = deleted ? 'fly left' : added ? 'glow' : null,
		duration = deleted ? 750 : added ? 1000 : 0,
		onHide = deleted ? onAfterDeleteItems : undefined,
		style = deleted ? { background: 'yellow' } : undefined;

	let stackMode = typeof stacked === 'object' ? 'nested' : 'flat';

	// Get card
	const getCard = (isParent: boolean = false, isChild: boolean = false): React.Node => {
		// Assign classes
		let classes = classNames({
			'layout-big': !layout || layout === 'big',
			'layout-small': layout === 'small',
			'has-image': image,
			'stacked sideways segment': stacked && isChild && stackMode === 'nested',
			'stacked segment': stacked && stackMode === 'flat',
			selected: selected,
			viewed: viewed,
			fluid: !isChild ? fluid : false
		});

		return (
			<SUIRCard
				key={`card-${id}`}
				style={style}
				className={classes}
				href={itemLink}
				target={itemLinkType === 'external' ? '_blank' : undefined}
				rel={itemLinkType === 'external' ? 'noopener noreferrer' : undefined}
				onClick={
					typeof onClick === 'function'
						? handleContainerClick
						: isChild && typeof stacked?.onClick === 'function'
							? stacked.onClick
							: undefined
				}
				data-testid={testID}
			>
				{!isParent && image && (!layout || layout === 'big') ? (
					<div className={imageClasses} style={styles}>
						{typeof image === 'string' ? (
							<Image key={`image-${id}`} src={image} />
						) : (
							<Image key={`image-${id}`} {...image} />
						)}
						{getmetaLabels(metaLabels)}
						{stacked && isChild ? <div className='image-faker' /> : null}
					</div>
				) : null}
				{!isChild && (isNew || typeof onSelect === 'function') ? (
					<div className='state-container'>
						{typeof onSelect === 'function' ? (
							<span className='select-checkbox'>
								<Checkbox checked={selected} name='item' value={id} onChange={onSelect} fitted />
							</span>
						) : null}
						{isParent && typeof stacked === 'object' && stacked?.header ? (
							<span className='header'>
								<Header as='h3' singleLine onClick={stacked?.onClick} {...stacked.header} />
							</span>
						) : null}
						{isNew ? (
							<span className='new-label'>
								<Label
									text={
										typeof isNew === 'number' ? (
											<FormattedMessage
												id='component.label.newAmount'
												values={{
													amount: isNew
												}}
											/>
										) : (
											<FormattedMessage id='component.label.new' />
										)
									}
									size={Size.Tiny}
									notification
									fitted
								/>
							</span>
						) : null}
					</div>
				) : null}
				<SUIRCard.Content>
					{(!isParent && !isChild) || (stacked && isChild) ? (
						<>
							{layout === 'small' && image ? (
								<div className='ui image mini right floated'>
									<div className={imageClasses} style={styles}>
										{typeof image === 'string' ? (
											<Image
												key={`image-${id}`}
												src={image}
												size={Size.Mini}
												floated={Float.Right}
											/>
										) : (
											<Image
												key={`image-${id}`}
												{...image}
												size={Size.Mini}
												floated={Float.Right}
											/>
										)}
									</div>
								</div>
							) : null}
							<SUIRCard.Header
								as={size === Size.Small ? 'h4' : 'h3'}
								className={classNames('item-header break-word', {
									[`text-lines-${limitHeader || ''}`]: limitHeader
								})}
							>
								{!isChild && headerLink ? (
									<NavLink
										to={headerLink}
										target={headerLinkType === 'external' ? Target.Blank : undefined}
										rel={headerLinkType === 'external' ? 'noopener noreferrer' : undefined}
										onClick={onHeaderClick}
									>
										{header}
									</NavLink>
								) : (
									header
								)}
							</SUIRCard.Header>
							{(layout !== 'big' || !image) && metaLabels ? getmetaLabels(metaLabels) : null}
							{subinfo ? (
								<SUIRCard.Meta>
									<List horizontal divided size={Size.Small} items={subinfo} />
								</SUIRCard.Meta>
							) : null}

							<SUIRCard.Description>
								<Text as='p' size={size} limitLines={limitDescription} breakWord>
									{description}
								</Text>
								{extraDescription ? <p className='extra-description'>{extraDescription}</p> : null}
								{!isChild && labels ? (
									<LabelGroup size={Size.Tiny} labels={Array.isArray(labels) ? labels : [labels]} />
								) : null}
							</SUIRCard.Description>
						</>
					) : (
						<div className='stacked-content-container'>
							<div className='stacked-cards-container'>{getCard(false, true)}</div>
							{isParent && typeof stacked === 'object' && stacked?.metaLabels
								? getmetaLabels(stacked.metaLabels, Size.Tiny)
								: null}
						</div>
					)}
				</SUIRCard.Content>
				{(!isParent && !isChild) || (stacked && isParent) ? (
					<>
						{getActions()}
						{getButtons()}
					</>
				) : null}
			</SUIRCard>
		);
	};

	// Define Card
	let card: Transition | Visibility = (
		<Transition
			visible={visible}
			transitionOnMount={transitionOnMount}
			unmountOnHide={true}
			animation={animation}
			duration={duration}
			onHide={onHide}
			reactKey={`animation-${id}`}
		>
			{stackMode === 'flat' ? getCard() : getCard(stacked !== undefined ? true : false, false)}
		</Transition>
	);

	// Visibility
	if (typeof onTopVisible === 'function' || typeof onBottomVisible === 'function') {
		card = (
			<Visibility
				onTopVisible={typeof onTopVisible === 'function' ? () => onTopVisible(id) : undefined}
				onBottomVisible={typeof onBottomVisible === 'function' ? () => onBottomVisible(id) : undefined}
			>
				{card}
			</Visibility>
		);
	}

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, card);
}

export default (React.memo(Card): React.AbstractComponent<React.PropsOf<Card>, mixed>);
