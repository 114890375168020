/**
 * @prettier
 * @flow
 */

import { MessageContent as SUIRMessageContent } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message */
component MessageContent(
	/** Detail as children. */
	children: React.Node,
	/** Test ID for testing */
	testID: string = 'MessageContent'
) {
	return <SUIRMessageContent data-testid={testID}>{children}</SUIRMessageContent>;
}

export default (React.memo(MessageContent): React.AbstractComponent<React.PropsOf<MessageContent>, mixed>);
