/**
 * @prettier
 * @flow
 */
import { ListIcon as SUIRListIcon } from 'semantic-ui-react';
import { Icon } from 'liana-ui/components/';
import { VAlign } from 'liana-ui/types';

type Props = {
	...React.PropsOf<Icon>,
	verticalAlign: VAlign
};

const ListIcon: React.AbstractComponent<Props, mixed> = React.memo((props: Props) => {
	return <SUIRListIcon {...props} />;
});

export default ListIcon;
