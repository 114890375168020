/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { FormattedNumber, FormattedTime, FormattedDate } from 'react-intl';
import { Icon, Popup, Text } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

component StatisticTrend(
	/** A trend must have trend/change as number. No value will show an indeterminate info icon. */
	value: number,
	/** A trend can display an icon. */
	icon: boolean = true,
	/** A trend can display as label. */
	label: boolean = false,
	/**
		Numbr description text or, react-intl component.
		PROPS[React.Node=/localization/]
	*/
	popup?: React.Node,
	/** A trend popoup can have a start date in ISO 8601. VALUES[YYYY-MM-DDTHH:MM:SS] */
	startDate?: string,
	/** A trend popoup can have a end date in ISO 8601. VALUES[YYYY-MM-DDTHH:MM:SS] */
	endDate?: string,
	/** A trend popoup can display only date or date and time. */
	displayTime: boolean = true,
	/** A trend can have diffrent notation and formatting. */
	notation?: 'percentage' | 'compact',
	/** Test ID for testing */
	testID: string = 'StatisticTrend'
) {
	let trendIcon = 'arrow ',
		rotate?: number,
		statistic = null;

	const getRange = () => {
		let range: React.Node;
		let common = { day: 'numeric', month: 'short', year: 'numeric' };

		range = displayTime ? (
			<>
				<FormattedTime value={startDate} {...common} /> &ndash; <FormattedTime value={endDate} {...common} />
			</>
		) : (
			<>
				<FormattedDate value={startDate} {...common} /> &ndash; <FormattedDate value={endDate} {...common} />
			</>
		);
		return range;
	};

	// Catch out-of-bounds and indeterminant values
	if (isNaN(value) || typeof value !== 'number') {
		trendIcon = 'circle info';
		statistic = '-';
	} else if (!notation || notation === 'compact') {
		if (value < 0) {
			trendIcon += 'down';
			rotate = -45;
		} else if (value > 0) {
			trendIcon += 'up';
			rotate = 45;
		} else if (value === 0) {
			trendIcon += 'right';
		}
		statistic =
			notation === 'compact' ? (
				<FormattedNumber value={value} notation='compact' compactDisplay='short' />
			) : (
				<FormattedNumber value={value} />
			);
	} else if (notation === 'percentage') {
		let percentage = Math.round(value),
			char = '';

		// Determine trend icon
		switch (true) {
			case percentage <= -100:
				trendIcon += 'down';
				break;
			case percentage <= -5 && percentage > -100:
				trendIcon += 'down';
				rotate = -45;
				break;
			case percentage > -5 && percentage < 5:
				trendIcon += 'right';
				break;
			case percentage >= 5 && percentage < 100:
				trendIcon += 'up';
				rotate = 45;
				break;
			case percentage >= 100:
				trendIcon += 'up';
				break;
		}

		if (percentage > 999) {
			char = '>';
			percentage = 999;
		} else if (percentage < -999) {
			char = '<';
			percentage = -999;
		}

		statistic = (
			<>
				{char} <FormattedNumber value={percentage / 100} style='percent' />
			</>
		);
	}

	if (icon) {
		statistic = (
			<>
				{statistic} <Icon name={trendIcon} rotate={rotate} />
			</>
		);
	}

	statistic = (
		<span
			data-testid={testID}
			className={classNames('statistic-trend nowrap', {
				'ui label': label,
				'cursor-help': popup
			})}
		>
			{statistic}
		</span>
	);

	if (popup && value !== undefined) {
		const trendPopup = {
			content: (
				<table className='statistic-tooltip'>
					<tr>
						<td className='statistic-tooltip-text'>
							{popup}
							{startDate && endDate ? (
								<Text as='div' size={Size.Small} className='statistic-tooltip-date'>
									{getRange()}
								</Text>
							) : null}
						</td>
						<td className='statistic-tooltip-value'>{statistic}</td>
					</tr>
				</table>
			)
		};
		// $FlowIssue - React statics; Attach popup
		return Popup.attach(trendPopup, statistic);
	}

	return statistic;
}

export default (React.memo(StatisticTrend): React.AbstractComponent<React.PropsOf<StatisticTrend>, mixed>);
