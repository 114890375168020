/**
 * @prettier
 * @flow
 */

import { LabelGroup as SUIRLabelGroup } from 'semantic-ui-react';
import { Label, LimitLabel } from 'liana-ui/components/';
import { Size } from 'liana-ui/types/';

// Note: Trying to load LabelGroup separately without Label will cause a cyclic dependency error
/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/label/#groups */
component LabelGroup(
	/**
		A label group can have multiple label components.
		PROPS[Label=/components/labels/labels/label/, LimitLabel=/components/labels/limit-label/]
	*/
	labels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
	/** A label group can be rendered as different HTML elements. */
	as?: 'div' | 'p' | 'span' = 'div',
	/** A label group can be limited to maximum amount of charachrets followed by ellipsis. */
	limitCharacters?: number,
	/** A label group can have different sizes. */
	size?: Size = Size.Small,
	/** Test ID for testing */
	testID: string = 'LabelGroup'
) {
	if (labels) {
		return Array.isArray(labels) ? (
			<SUIRLabelGroup as={as} size={size} data-testid={testID}>
				{labels && Array.isArray(labels)
					? labels.map((label, index) => {
							const testID = label?.testID || `LabelGroup::Label::${index}`;
							return label?.upgradeLink || (label?.limit && label?.onClick) ? (
								<LimitLabel {...label} key={index} size={size} testID={testID} />
							) : (
								// $FlowFixMe - Spread props leak
								<Label
									{...label}
									limitCharacters={limitCharacters}
									key={index}
									size={size}
									testID={testID}
								/>
							);
						})
					: null}
			</SUIRLabelGroup>
		) : (
			// $FlowFixMe - Impossible type refinement
			<Label {...labels} testID={testID} />
		);
	}
	return null;
}

export default (React.memo(LabelGroup): React.AbstractComponent<React.PropsOf<LabelGroup>, mixed>);
