/**
 * @prettier
 * @flow
 */

import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'liana-ui/components/';
import FeedbackForm from './src/FeedbackForm';
import { Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/modal/ */
component FeedbackModal(
	/** A modal can be open. */
	open?: boolean = false,
	/** You can manually specify the modal parent container */
	mountNode?: HTMLElement | null,
	/** Test ID for testing */
	testID: string = 'FeedbackModal',
	/** Called on modal submit. */
	onSubmit?: (values: any, done: () => void) => void,
	/** Called on modal close. */
	onClose?: () => void,
	/**  Passed as second param for onSubmit. */
	onComplete?: () => void
) {
	let form = useRef<null | any>(null); // Legacy Form component
	let [modified, setModified] = useState(false);
	let [loading, setLoading] = useState(false);

	const reset = () => {
		setLoading(false);
		setModified(false);
	};

	// Turn off loading if the modal is closed prematurely
	useEffect(() => {
		if (open === false && (loading === true || modified === true)) {
			reset();
		}
	}, [open]);

	const handleChange = () => setModified(true);
	const handleSubmit = () => {
		if (form.current && form.current.validate()) {
			setLoading(true);
			if (form.current && typeof onSubmit === 'function') {
				onSubmit(form.current.values(), handleComplete);
			}
		}
	};
	const handleClose = () => {
		reset();
		if (typeof onClose === 'function') {
			onClose();
		}
	};
	const handleComplete = () => {
		reset();
		if (typeof onComplete === 'function') {
			onComplete();
		}
	};

	return (
		<Modal
			open={open}
			mountNode={mountNode}
			type='form'
			size={Size.Tiny}
			header={<FormattedMessage id='component.support-menu.sendFeedback.title' />}
			testID={testID}
			onClose={handleClose}
			content={
				<FeedbackForm
					ref={form}
					modified={modified}
					loading={loading}
					onChange={handleChange}
					onSubmit={handleSubmit}
					onClose={handleClose}
				/>
			}
		/>
	);
}

export default (React.memo(FeedbackModal): React.AbstractComponent<React.PropsOf<FeedbackModal>, mixed>);
