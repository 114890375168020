/**
 * @prettier
 * @flow
 */
import { ListContent as SUIRListContent } from 'semantic-ui-react';

component ListContent(
	/** Detail as children. */
	children: React.Node,
	/** An list content can be floated left or right. */
	floated?: 'left' | 'right'
) {
	return <SUIRListContent floated={floated}>{children}</SUIRListContent>;
}

export default (React.memo(ListContent): React.AbstractComponent<React.PropsOf<ListContent>, mixed>);
