/**
 * @prettier
 * @flow
 */

import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Label as SUIRLabel } from 'semantic-ui-react';
import { BrowserUtils } from 'liana-ui/definitions/';
import { Text, Icon, Image, Flag, Popup, Responsive, Transition } from 'liana-ui/components/';
import LabelDetail from './src/LabelDetail';
import LabelGroup from './LabelGroup';
import LimitLabel from './LimitLabel';
import { Device, Size } from 'liana-ui/types';

type Detail = {
	content: React.Node,
	icon: string | React.ElementConfig<Icon>,
	popup: React.Node | React.ElementConfig<Popup>,
	onClick: (event: SyntheticEvent<>, data?: any) => void,
	...
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/label */
component Label(
	/**
		A label must have text as tring or react-intl component.
		PROPS[React.Node=/localization/]
	*/
	text?: React.Node,
	/** An element type to render as. */
	as: 'span' | 'div' | 'a' = 'div',
	/** A label can have name. */
	name?: string,
	/**
		A label can have detail text.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/, Icon=/components/labels/icons/icon/]
	*/
	detail?: string | React.Node | Detail,
	/** A label can be circular. */
	circular: boolean = false,
	/** A label can be an empty square or circular dot. */
	empty: boolean = false,
	/**
		A label can have an icon.
		PROPS[Icon=/components/labels/icons/icon/]
	*/
	icon?: string | React.PropsOf<Icon>,
	/**
		A label can have an image.
		PROPS[Image=/components/texts/image/]
	*/
	image?: string | React.PropsOf<Image>,
	/** A label can have a flag */
	flag?: string,
	/** A label can have a small favicon. Requires image property. */
	favicon: boolean = false,
	/** A label can have fixed width with two letter language label. */
	langCode?: boolean = false,
	/** A label can have a unique color for notifications. */
	notification?: boolean = false,
	/** A label can be formatted to display a positive or negative rating.  */
	rating?: 'positive' | 'negative',
	/** A label can be formatted to display a success, warning or error message.  */
	message?: 'info' | 'success' | 'warning' | 'error',
	/** A label can point to content next to it. */
	pointing?: boolean | 'above' | 'below' | 'left' | 'right',
	/** A label can position itself in the corner of an element. */
	corner?: 'left' | 'right',
	/** A label can blink to require more attension. */
	blinking: boolean = false,
	/**
		A label can have different color or hex color.
		VALUES['red', 'orange', 'yellow', 'green', 'blue', '#FF0000', '#...']
	*/
	color?: string,
	/** A label can reduce its complexity by looking more like basic text. */
	textual: boolean = false,
	/** A label can float to left or right. */
	floated?: 'left' | 'right',
	/** A label can have no margins. */
	fitted?: boolean = false,
	/** A label can always keep all content on a single line. */
	singleLine?: boolean = false,
	/** A label can be limited to maximum amount of charachrets followed by ellipsis. */
	limitCharacters?: number,
	/** A label can be a link. Opens absolute links in new browser tab and internal links via router. */
	link?: boolean,
	/** A label can be disabled */
	disabled: boolean = false,
	/** A label can be visible */
	visible: boolean = true,
	/** A label can have different sizes. */
	size?: Size = Size.Small,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Stop click events from bubbling to its parent */
	stopPropagation?: boolean,
	/** Test ID for testing */
	testID: string = 'Label',
	/** Function called on label click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name?: string
		}
	) => void
) {
	const intl = useIntl();

	// Function to generate LianaUI Button
	const createLabel = () => {
		// Called on checkbox click.
		const handleClick = (event: SyntheticEvent<>) => {
			if (stopPropagation && 'stopPropagation' in event) {
				event.stopPropagation();
				event.preventDefault();
			}
			if (typeof onClick === 'function') {
				onClick(event, { name: name });
			}
		};

		// Assign classes
		let classes = classNames(
			{
				image: image,
				'favicon-label': favicon,
				'lang-label': langCode,
				'link-label': link,
				clickable: as === 'a' || onClick,
				fitted: fitted,
				nowrap: singleLine,
				info: message === 'info',
				success: message === 'success' || rating === 'positive',
				warning: message === 'warning',
				error: message === 'error' || rating === 'negative',
				outlined: BrowserUtils.isLowContrast(color, 242) || color === 'white',
				textual: textual,
				disabled: disabled,
				blinking: blinking,
				primary: color === 'primary'
			},
			floated ? `float-${floated}` : false
		);

		// Assign color
		let labelColor,
			labelStyle,
			labelIcon = typeof icon === 'string' ? { name: icon } : icon;
		if (notification) {
			labelColor = 'purple'; // Notification color
		} else if (typeof color === 'string') {
			if (color[0] === '#') {
				labelStyle = {
					background: color,
					color: BrowserUtils.isLowContrast(color, 180) ? '#000' : '#fff'
				};
				labelColor = undefined;
			} else {
				labelColor = color && color !== 'primary' ? color : undefined;
			}
		}

		// Label icons
		if (rating) {
			labelIcon =
				rating === 'positive'
					? {
							// Positive rating message
							name: typeof icon === 'string' ? icon : 'fa-smile',
							color: 'green'
						}
					: {
							// Negative rating message
							name: typeof icon === 'string' ? icon : 'fa-frown',
							color: 'red'
						};
		} else if (message) {
			switch (message) {
				case 'info':
					// Success message
					labelIcon = {
						name: typeof icon === 'string' ? icon : 'fa-info-circle',
						color: 'purple'
					};
					break;
				case 'success':
					// Success message
					labelIcon = {
						name: typeof icon === 'string' ? icon : 'fa-check',
						color: 'green'
					};
					break;
				case 'warning':
					// Warning message
					labelIcon = {
						name: typeof icon === 'string' ? icon : 'fa-circle-exclamation',
						color: 'yellow'
					};
					break;
				case 'error':
					// Error message
					labelIcon = {
						name: typeof icon === 'string' ? icon : 'fa-triangle-exclamation',
						color: 'red'
					};
					break;
			}
		}

		let labelText = text && typeof text === 'string' ? intl.formatMessage({ id: text }) : text;

		let content = (
			<>
				{labelIcon ? <Icon {...labelIcon} /> : null}
				{flag ? <Flag name={flag} /> : null}
				{favicon && !image ? <Icon name='globe' size={size} /> : null}
				{image ? (
					typeof image === 'string' ? (
						<Image src={image} size={favicon ? Size.Favicon : undefined} />
					) : (
						<Image {...image} size={favicon ? Size.Favicon : undefined} />
					)
				) : null}
				{limitCharacters ? <Text limit={limitCharacters}>{labelText}</Text> : labelText}
				{detail ? (
					typeof detail === 'string' || React.isValidElement(detail) ? (
						// $FlowFixMe - Flow doesn't know if detail is a React.Node
						<LabelDetail content={detail} />
					) : (
						// $FlowFixMe - Detail can be a number here because of React.Node
						<LabelDetail {...detail} />
					)
				) : null}
			</>
		);

		let label = (
			<SUIRLabel
				as={onClick || link ? 'a' : as}
				name={name}
				className={classes}
				color={labelColor}
				style={labelStyle}
				circular={circular || notification}
				empty={empty}
				pointing={pointing}
				corner={corner}
				size={size ? size : !textual ? 'small' : undefined}
				onClick={handleClick}
				data-testid={testID}
			>
				{content}
			</SUIRLabel>
		);

		// Attach popup
		let labelPopup = popup;
		if (limitCharacters && !popup) {
			labelPopup = { content: text, delay: 750, size: Size.Mini };
		}
		// $FlowIssue - React statics; Attach popup
		label = Popup.attach(labelPopup, label);

		// Define transition
		label = (
			<Transition visible={visible} animation='scale' duration={250}>
				{label}
			</Transition>
		);

		return label;
	};

	// Display reponsively
	return minDevice || maxDevice || hideTouch ? (
		<Responsive minDevice={minDevice} maxDevice={maxDevice} hideTouch={hideTouch}>
			{createLabel()}
		</Responsive>
	) : (
		createLabel()
	);
}

const MemoComponent = (React.memo(Label): React.AbstractComponent<React.PropsOf<Label>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Group = LabelGroup;
// $FlowIssue - Static subcomponents
MemoComponent.Limit = LimitLabel;

export default MemoComponent;
