/**
 * @prettier
 * @flow
 */

import {
	ItemGroup,
	Header,
	Image,
	ListItem,
	Label,
	LimitLabel,
	Button,
	ContextMenu,
	ButtonGroup
} from 'liana-ui/components/';
import { VAlign, Size } from 'liana-ui/types';

// prettier-ignore
type Props = {
	/** 
	 	An item can have a header. 
	 	PROPS[React.Node=/language/localisation]
	*/
	header: React.Node,
	/** A selectable or ideantifiable item must have an id */
	id: string,
	/**
	 	An item can have and image. 
	 	PROPS[React.PropsOf<Image>=/components/texts/image]
	*/
	image?: string | React.PropsOf<Image>,
	/** Only the card header can be a link. Opens absolute links in new browser tab and internal links via router. */
	headerLink?: string,
	/** The whole card can be a link. Opens absolute links in new browser tab and internal links via router. */
	itemLink?: string,
	/** 
	 	An item can have a description. 
	 	PROPS[React.Node=/language/localisation]
	*/
	description?: React.Node,
	/* An item can have extra description or elements below the textuak description. */
	extraDescription?: React.Node,
	/** 
	 	An item can sub info text or content. 
	 	PROPS[ListProps=/components/containers/list]
	*/
	subinfo?: Array<React.Node | React.PropsOf<ListItem>>,
	/**
		An item can have meta labels and/or limit label on top.
		PROPS[Label=/components/labels/labels/label/, LimitLabel=/components/labels/limit-label/]
	*/
	metaLabels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
	/**
		An item can have labels and/or limit label.
		PROPS[Label=/components/labels/labels/label/, LimitLabel=/components/labels/limit-label/]
	*/
	labels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
	/** 
	 	An item can have action buttons or context menus. Max 5 recommended.
		PROPS[Button=/components/buttons/button/, ContextMenu=/components/menus/context-menu] 
	*/
	actions?: Array<{ button?: React.PropsOf<Button>, contextMenu?: React.PropsOf<ContextMenu> }>,
	/** 
		An item can have single button. 
		PROPS[Button=/components/buttons/button/]
	*/
	button?: React.PropsOf<Button>,
	/** 
		An item can have a button group. 
		PROPS[ButtonGroup=/components/buttons/button-group/]
	*/
	buttonGroup?: React.PropsOf<ButtonGroup>,
	/** An item can have different layouts. */
	layout?: 'small' | 'big',
	/** An item can be more fitted by removing left and right paddings */
	fitted?: boolean,
	/** An item can vertically align items. */
	verticalAlign?: VAlign,
	/** 
	  	An item can limit header to maximum amount of lines with ellipsis. 
	 	VALUES[1 - 10]
	*/
	limitHeader?: number,
	/** 
	    An item can limit description to maximum amount of lines with ellipsis. 
		VALUES[1 - 10]			
	*/
	limitDescription?: number,
	/** An item can be in a selected state. */
	selected?: boolean,
	/** An item can be in a viewed state. */
	viewed?: boolean,
	/** An item can glow when added. */
	added: boolean,
	/** An item can animate (scale away) when deleted. */
	deleted: boolean,
	/** An item can be new */
	new: boolean,
	/** An item can be stacked and contain multiple stacked items. */
	stacked?: boolean | {
		stackedItems: number,
		header?: React.PropsOf<Header>,
		metaLabels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
		onClick?: () => void
	},
	/** An item can have different sizes. */
	size?: Size,
	/** Test ID for testing */
	testID?: string,
	/** Function called on item select. */
	onSelect?: (
		event: SyntheticEvent<>,
		data: {
			selected: boolean
		}
	) => void,
	/** Function called on card click. */
	onClick?: (
		event: SyntheticEvent<>
	) => void,
	/** Function called on card header click. */
	onHeaderClick?: () => void,
	/** Items's top edge has passed bottom of screen. */
	onTopVisible?: (id: number | string) => void,
	/** Items's bottom edge has passed bottom of screen. */
	onBottomVisible?: (id: number | string) => void,
	/** Callback on when delete animation ends. */
	onAfterDeleteItems?: () => void
};

const DEFAULTS = {
	verticalAlign: 'middle',
	new: false,
	viewed: false,
	added: false,
	deleted: false,
	singleItem: true
};

// Note: An "Item" is just an ItemGroup with a single element
/** COMPONENT BASED ON: https://react.semantic-ui.com/views/item/ */
const Item: React.AbstractComponent<Props, mixed> = React.memo((props: Props) => {
	// $FlowIssue - Flow can't handle spread merges
	let merged: React.PropsOf<Component> = { ...DEFAULTS, ...props };
	return (
		<ItemGroup
			items={[{ ...merged }]}
			layout={merged.layout}
			verticalAlign={merged.verticalAlign}
			limitHeader={merged.limitHeader}
			limitDescription={merged.limitDescription}
			fitted={merged.fitted}
			size={merged.size}
			testID={merged.testID}
		/>
	);
});

export default Item;
