/**
 * @prettier
 * @flow
 */

import { PopupContent as SUIRPopupContent } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/popup/ */
component PopupContent(
	/** Content as children. */
	children: React.Node,
	/** Test ID for testing. */
	testID: string = 'PopupContent'
) {
	return <SUIRPopupContent data-testid={testID} content={children} />;
}

export default (React.memo(PopupContent): React.AbstractComponent<React.PropsOf<PopupContent>, mixed>);
