/**
 * @prettier
 * @flow
 */

import { StepContent as SUIRStepContent } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
component StepContent(
	/** Detail as children. */
	children: React.Node,
	/** Test ID for testing. */
	testID: string = 'StepContent'
) {
	return <SUIRStepContent data-testid={testID}>{children}</SUIRStepContent>;
}

export default (React.memo(StepContent): React.AbstractComponent<React.PropsOf<StepContent>, mixed>);
