/**
 * @prettier
 * @flow
 */

import { useState, useEffect } from 'react';
import { Dropdown, Input, Popup } from 'liana-ui/components';
import { Size } from 'liana-ui/types';
import type { Option } from 'liana-ui/components/dropdown/Dropdown';

type Part = 'prefix' | 'infix' | 'postfix';

type Parts = {
	prefix?: string,
	infix: string,
	postfix?: string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/divider */
component PrefillInput(
	/** A prefill input must have an input name. */
	name: string,
	/**
		A prefill input can have a placeholder text.
		PROPS[React.Node=/localization/]
	*/
	/** Text input placeholder */
	placeholder?: React.Node,
	/** Initial value for input. Use for uncontrolled components only. */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. */
	value?: string,
	/** A prefill input can have prefix options in a dropdown. */
	prefix: Array<string> = [],
	/** A prefill input can have postfix options in a dropdown. */
	postfix: Array<string> = [],
	/** A prefill input can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean,
	/** A prefill input can be  disabled. */
	disabled?: boolean,
	/** A prefill input can be different size. */
	size?: Size,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'PrefillInput',
	/** Function called on input change. */
	onChange?: (
		event: null | SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void
) {
	const parsePrefix = (val: string, replace: boolean = false) => {
		if (typeof val === 'string' && val.length > 0 && Array.isArray(prefix) && prefix.length > 0) {
			for (let i of prefix) {
				if (val.indexOf(i) === 0) {
					return replace === true ? val.substring(i.length) : i;
				}
			}
		}
		return replace === true ? val : '';
	};

	const parsePostfix = (val: string, replace: boolean = false) => {
		let idx;
		if (typeof val === 'string' && val.length > 0 && Array.isArray(postfix) && postfix.length > 0) {
			for (let j of postfix) {
				idx = val.lastIndexOf(j);
				if (idx > -1 && idx === val.length - j.length) {
					return replace === true ? val.substring(0, val.length - j.length) : j;
				}
			}
		}
		return replace === true ? val : '';
	};

	const parseInfix = (val: string, asData: boolean = false): string | Parts => {
		let idx = -1,
			prefix = parsePrefix(val),
			postfix = parsePostfix(val),
			data: Parts = { infix: val };
		if (prefix) {
			data.prefix = prefix;
			if (data.infix && val.indexOf(prefix) === 0) {
				// $FlowIssue[incompatible-use] - Flow doesn't understand that infix is defined
				data.infix = data.infix.substring(prefix.length);
			}
		}
		if (postfix) {
			data.postfix = postfix;
			idx = val.lastIndexOf(postfix);
			if (idx > -1 && idx === val.length - postfix.length) {
				// $FlowIssue[incompatible-use] - Flow doesn't understand that infix is defined
				data.infix = data.infix.substring(0, data.infix.length - postfix.length);
			}
		}
		return asData ? data : data.infix;
	};

	let currentValue = value || defaultValue || '';

	let [parts, setParts] = useState({
		infix: parseInfix(currentValue),
		prefix: parsePrefix(currentValue) || prefix[0] || '',
		postfix: parsePostfix(currentValue) || postfix[0] || ''
	});

	const handleValue = (event: null | SyntheticEvent<>, val?: string | Parts, part: Part) => {
		let ret = '',
			// $FlowIssue[invalid-computed-prop]
			newParts = typeof val === 'object' ? val : { [part]: val };

		newParts = { ...parts, ...newParts };
		setParts(newParts);
		ret = `${newParts.prefix}${newParts.infix}${newParts.postfix}`;

		if (part && typeof onChange === 'function') {
			onChange(event, { name: name, value: ret });
		}
	};

	const generateOptions = (data: Array<string>): Array<Option> => {
		let opts = [];
		if (Array.isArray(data) && data.length > 0) {
			for (let i of data) {
				opts.push({ text: i, value: i });
			}
		}
		// $FlowIssue - Nonsense Flow freakout
		return opts;
	};

	const generatePrefill = (type: 'prefix' | 'postfix'): React.Node => {
		let prefill;
		const fixOpts = {
			fluid: false,
			disabled: disabled || locked,
			locked: locked,
			size: size
		};

		if (type === 'prefix' && Array.isArray(prefix) && prefix.length > 0) {
			prefill =
				prefix.length === 1 ? (
					prefix[0]
				) : (
					<Dropdown
						name={name + '-prefix'}
						value={parts.prefix}
						options={prefixOptions}
						onChange={handlePrefix}
						{...fixOpts}
					/>
				);
		} else if (type === 'postfix' && Array.isArray(postfix) && postfix.length > 0) {
			prefill =
				postfix.length === 1 ? (
					<div className='ui small label right'>
						<span>
							<span className='ui text'>{postfix[0]}</span>
						</span>
					</div>
				) : (
					<Dropdown
						name={name + '-postfix'}
						className='label right'
						value={parts.postfix}
						options={postfixOptions}
						onChange={handlePostfix}
						{...fixOpts}
					/>
				);
		}

		return prefill;
	};

	const prefixOptions = generateOptions(prefix),
		postfixOptions = generateOptions(postfix),
		handleInfix = (event: SyntheticEvent<>, data: { name?: string, value: string }) =>
			handleValue(event, parseInfix(data.value, true), 'infix'),
		handlePrefix = (event: SyntheticEvent<>, data: any) => handleValue(event, data.value, 'prefix'),
		handlePostfix = (event: SyntheticEvent<>, data: any) => handleValue(event, data.value, 'postfix');

	// Handle new values supplied via props
	useEffect(() => {
		const current = typeof parts.infix === 'string' ? `${parts.prefix}${parts.infix}${parts.postfix}` : '';
		if (typeof value === 'string' && value !== current) {
			handleValue(null, parseInfix(value, true), 'infix');
		}
	}, [value]);

	// SUIR actions & labels
	// https://github.com/Semantic-Org/Semantic-UI-React/issues/2148
	return (
		<>
			<Input
				placeholder={placeholder}
				value={parts.infix ? String(parts.infix) : ''}
				onChange={handleInfix}
				disabled={disabled}
				locked={locked ? 'input' : undefined}
				popup={popup}
				size={size}
				label={generatePrefill('prefix')}
				action={generatePrefill('postfix')}
				testID={testID}
			/>
			<input
				className='hidden'
				name={name}
				value={parts.infix ? `${parts.prefix}${String(parts.infix)}${parts.postfix}` : ''}
				disabled={disabled}
			/>
		</>
	);
}

export default (React.memo(PrefillInput): React.AbstractComponent<React.PropsOf<PrefillInput>, mixed>);
