/**
 * @prettier
 * @flow
 */

import { useRef } from 'react';
import { Segment, Navigation } from 'liana-ui/components';
import Sticky from 'liana-ui/legacy/components/sticky/Sticky';
import { Spacing } from 'liana-ui/types';
import type { Page } from 'liana-ui/components/navigation/Navigation';

component SidePanel(
	/** A side panel must have navigation data for main navigation. DATA[json/navigation/navigation.json] */
	navigation: Array<Page>,
	/** A side panel can have extra tools for application. */
	tools?: React.Node,
	/** A side panel can have a different sticky context element id. */
	stickyContext: string = 'side-panel',
	/** Test ID for testing. */
	testID: string = 'SidePanel'
) {
	let stickyRef = useRef<any | null>(null);

	/* istanbul ignore next */
	let handleAnimationEnd = () => {
		if (stickyRef.current) {
			stickyRef.current.refresh();
		}
	};

	let content: any = (
		<>
			{tools ? (
				<Segment basic compressed removeMargins={Spacing.All}>
					{tools}
				</Segment>
			) : null}
			<Navigation navigation={navigation} onAnimationEnd={handleAnimationEnd} />
		</>
	);

	if (stickyContext) {
		content = (
			<Sticky context={`#${stickyContext}`} ref={stickyRef}>
				{content}
			</Sticky>
		);
	}

	return (
		<section className='side-panel' id={stickyContext} data-testid={testID}>
			{content}
		</section>
	);
}

export default (React.memo(SidePanel): React.AbstractComponent<React.PropsOf<SidePanel>, mixed>);
