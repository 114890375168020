/**
 * @prettier
 * @flow
 */

import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Browser } from 'liana-ui/definitions';
import { loadIntl, loadPolyfills, loadLocalData, loadRemoteData, flatten } from './src/Loader';

component Component(
	children: React.Node, // Routes
	language: string = 'en',
	locales: { [string]: string } = { en: 'en-US' },
	url: string,
	path: string
) {
	const setupLocal = () => {
		return !url ? loadLocalData(language, locales, path) : { formats: [], messages: {} };
	};

	// https://formatjs.io/docs/react-intl/components#intlprovider
	const [i18n, setTranslations] = useState<{ formats: Array<string> | [], messages: any }>(setupLocal());

	// $FlowIgnore - Async hook details missing
	useEffect(async () => {
		if (!global.Intl || Browser.is.IE(true)) {
			loadIntl(language, locales); // Load all relevant polyfills
		}
		// Load actual language data (remotely or locally)
		let translations =
			typeof url === 'string' && url.length > 0
				? await loadRemoteData(language, locales, url)
				: loadLocalData(language, locales, path);

		setTranslations(translations);

		// Load all language polyfills
		loadPolyfills(language, locales);
		// Update <html> with language
		if (document.documentElement?.getAttribute('lang') !== language) {
			document.documentElement?.setAttribute('lang', language);
		}
	}, [language]);

	return (
		<IntlProvider
			defaultLocale='en'
			key={language}
			locale={language}
			formats={i18n.formats}
			messages={flatten(i18n.messages)}
		>
			{children}
		</IntlProvider>
	);
}

export default (React.memo(Component): React.AbstractComponent<React.PropsOf<Component>, mixed>);
