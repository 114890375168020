/**
 * @prettier
 * @flow
 */

import { Segment, Header, Divider } from 'liana-ui/components/';
import { Spacing, Size } from 'liana-ui/types/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/ */
component SuggestedItem(
	/** Item can be a link to the item. */
	link?: string,
	/** Item should have have a type. */
	type: string,
	/** Item should have a header. */
	header: React.Node,
	/** Item can have a sub header. */
	subheader?: React.Node,
	/** Item can have a sub header. */
	subinfo?: React.Node | Array<React.Node>,
	/** Item content. */
	content?: React.Node
) {
	let item = (
		<Segment raised removePaddings={Spacing.All} link={link}>
			<Segment basic compressed size={Size.Small} removeMargins={Spacing.All} className='content'>
				{header && <Header as='h3' text={header} subheader={subheader} subinfo={subinfo} />}
				{content}
			</Segment>
			<Divider removeMargins={Spacing.All} />
			<Segment basic compressed size={Size.Small} removeMargins={Spacing.All} className='type'>
				{type}
			</Segment>
		</Segment>
	);

	return item;
}

export default (React.memo(SuggestedItem): React.AbstractComponent<React.PropsOf<SuggestedItem>, mixed>);
