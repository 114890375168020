/**
 * @prettier
 * @flow
 */

import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Loader as SUIRLoader } from 'semantic-ui-react';
import { Dimmer } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';
import Utils from './LoaderUtils';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/loader/ */
component Loader(
	/** A loader can have text. */
	text?: React.Node,
	/** A loader can be active. */
	active?: boolean = false,
	/** A loader can be formatted to appear on dark backgrounds. */
	inverted?: boolean = false,
	/** A loader can appear inline with content. */
	inline?: boolean | 'centered',
	/** A loader can overlay containers or full screen page. */
	dimmer?: boolean | 'page' = false,
	/** A loader can be different speeds. */
	speed?: 'slow' | 'fast',
	/** A loader can be different sizes. */
	size?: Size,
	/** A loader can be placed in the middle of ampty space. */
	centerInEmptySpace?: boolean = false,
	/** Test ID for testing. */
	testID: string = 'Loader'
) {
	const WRAPPER_OFFSET = 400;

	let [hasListeners, setHasListeners] = useState(false);
	let loaderWrapperRef = useRef<null | HTMLDivElement>(null);
	let icon: HTMLElement | null = null;
	let container: HTMLElement | null = null;

	// Case centerInEmptySpace is set inline needs to be false
	let computedInline = inline === 'centered' ? inline : centerInEmptySpace || dimmer ? false : true;

	// componentDidUpdate
	useEffect(() => {
		if (loaderWrapperRef?.current) {
			icon = loaderWrapperRef.current.querySelector('.loader');
		}
		if (loaderWrapperRef?.current?.parentNode instanceof HTMLElement) {
			container = loaderWrapperRef.current.parentNode;
		}
		if (typeof dimmer === 'boolean' && !computedInline) {
			if (centerInEmptySpace) {
				setWrapperHeight();
			}
			Utils.center(container, icon, Boolean(dimmer));
			if (!hasListeners) {
				window.addEventListener('scroll', callback);
				window.addEventListener('resize', callback);
				setHasListeners(true);
			}
		}
	});

	// componentWillUnmount
	useEffect(() => {
		return () => {
			window.removeEventListener('scroll', callback);
			window.removeEventListener('resize', callback);
		};
	}, []);

	const setWrapperHeight = () => {
		if (loaderWrapperRef.current && container) {
			let loaderWrapperHeight =
				window.document.body.scrollHeight -
				container.getBoundingClientRect().top -
				Utils.getPadding(container, Boolean(dimmer));

			// $FlowIssue[incompatible-use] - Already accounted for above
			loaderWrapperRef.current.style.height =
				loaderWrapperHeight < WRAPPER_OFFSET ? `${WRAPPER_OFFSET}px` : `${loaderWrapperHeight}px`;
		}
	};

	let callback = debounce(() => Utils.center(container, icon, Boolean(dimmer)), 5); // Throttle

	let loader: any = (
		<>
			<SUIRLoader
				className={speed}
				content={!computedInline && text ? text : undefined}
				active={active}
				inverted={inverted}
				inline={computedInline}
				size={size}
				data-testid={testID}
			/>
			{computedInline && text ? text : null}
		</>
	);

	if (dimmer) {
		const isFullScreen = dimmer === 'page';
		loader = (
			<Dimmer active={active} inverted={isFullScreen ? false : true} page={isFullScreen}>
				{loader}
			</Dimmer>
		);
	}

	return (
		<div
			ref={loaderWrapperRef}
			className={centerInEmptySpace ? 'loader-wrapper relative' : 'loader-wrapper'}
			style={{
				display: active ? (inline ? 'inline-block' : 'block') : 'none',
				opacity: (centerInEmptySpace && hasListeners) || !centerInEmptySpace ? 1 : 0
			}}
		>
			{loader}
		</div>
	);
}

export default (React.memo(Loader): React.AbstractComponent<React.PropsOf<Loader>, mixed>);
