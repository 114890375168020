/**
 * @prettier
 * @flow
 */

import { StepTitle as SUIRStepTitle } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
component StepTitle(
	/** Title as string. */
	text: React.Node,
	/** Test ID for testing. */
	testID: string = 'StepTitle'
) {
	return <SUIRStepTitle data-testid={testID}>{text}</SUIRStepTitle>;
}

export default (React.memo(StepTitle): React.AbstractComponent<React.PropsOf<StepTitle>, mixed>);
