/* istanbul ignore file */
/**
 * @prettier
 * @flow
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//let Component: React.AbstractComponent<void> = React.memo(() => {

component AppScroll() {
	// https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe
	const CONFIG = {
		childList: true,
		subtree: true
	};

	const { pathname, hash } = useLocation();
	const findLink = (hash: string) => document.getElementById(hash.replace('#', ''));

	useEffect(() => {
		let observer = null,
			link = null;
		if (hash) {
			link = findLink(hash);
			if (link) {
				link.scrollIntoView(); // Element visible - Scroll to it
			} else {
				// Element not visible - Set up a Mutation Observer - https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
				observer = new MutationObserver((mutations, instance) => {
					link = findLink(hash);
					if (link) {
						link.scrollIntoView();
						instance.disconnect();
						return true;
					}
				});
				if (document.body) {
					observer.observe(document.body, CONFIG); // Start observing
				}
			}
		} else {
			if (observer) {
				observer.disconnect();
				observer = null;
			}
			window.scrollTo(0, 0);
		}
	}, [pathname, hash]);

	return null;
};

export default (React.memo(AppScroll): React.AbstractComponent<any, mixed>);
