/**
 * @prettier
 * @flow
 */

import CountUp from 'countup.js'; // https://github.com/inorganik/countUp.js
import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

type CountUpOptions = {
	delay: number,
	useEasing: boolean,
	useGrouping: boolean,
	separator: string,
	decimal: string,
	formattingFn?: (number) => string | number
};

/** COMPONENT BASED ON: https://github.com/inorganik/countUp.js */
component Count(
	/** Initial value. */
	start?: number | React.Node = 0,
	/** Target value. */
	end: number | React.Node,
	/** Delay between animation starting. */
	delay?: number = 250,
	/** Animation duration in seconds. */
	duration?: number = 1,
	/** Is the Counter visible on animation start. */
	visible?: boolean = true,
	/** Test ID for testing. */
	testID: string = 'Count',
	/** Callback when count up animation is ready */
	onComplete?: () => void
) {
	const intl = useIntl();
	const ref = useRef<HTMLSpanElement | null>(null);
	const sample = intl.formatNumber(1234.45); // Hack: Get language decimal separator since ECMA-402 doesn't have an API for this
	let timeout = null;
	let instance = null;
	let isFormatted = (val: any) => React.isValidElement(val) && val.type.displayName === 'FormattedNumber';

	const formatValue = (val: string | React.Node) => {
		// $FlowIssue - val of FormattedNumber
		let formatted = Number(isFormatted(val) ? val.props.value : val);
		return formatted;
	};

	const formatNumber = (val: number) => {
		/* $FlowIgnore - Intl stuff - istanbul ignore else */
		if (end.props && end.props.style === 'percent' && val >= 1) {
			val = val / 100;
		}
		// $FlowIgnore - Intl stuff
		return intl.formatNumber(val, end.props);
	};

	const handleComplete = () => {
		// Trigger onComplete callback funtion
		if (typeof onComplete === 'function') {
			onComplete();
		}
	};

	// CountUp defaults
	let COUNTUP_OPTIONS: CountUpOptions = {
		delay: delay,
		useEasing: true,
		useGrouping: true,
		separator: sample.charAt(1),
		decimal: sample.charAt(5)
	};

	useEffect(() => {
		/* istanbul ignore else */
		if (ref.current && visible === true) {
			if (duration > 0) {
				let countStart = formatValue(start);
				let countEnd = formatValue(end);
				if (isFormatted(end)) {
					COUNTUP_OPTIONS.formattingFn = formatNumber;
				}
				instance = new CountUp(
					ref.current, // Instance
					countStart, // Start number
					countEnd, // End number
					(String(countEnd).split('.')[1] || []).length, // Decimals
					duration, // Animation duration
					COUNTUP_OPTIONS // CountUp options
				);
				if (delay > 0) {
					// $FlowIgnore - Delay before starting animation
					timeout = setTimeout(() => instance.start(handleComplete), delay);
				} else {
					instance.start(handleComplete);
				}
			} else {
				handleComplete();
			}
		}
	}, [visible, end]);

	useEffect(() => {
		return () => clearTimeout(timeout); // Component unmount
	}, []);

	return (
		<span data-testid={testID} ref={ref}>
			{duration > 0 ? start : isFormatted(end) ? end : intl.formatNumber(end)}
		</span>
	);
}

export default (React.memo(Count): React.AbstractComponent<React.PropsOf<Count>, mixed>);
