/**
 * @prettier
 * @flow
 */

import { useEffect, useState } from 'react';
import classNames from 'classnames';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import DateRange from 'liana-ui/legacy/components/date-range/DateRange';
import {
	Segment,
	Header,
	Button,
	Dropdown,
	Search,
	Checkbox,
	Filter,
	ContextMenu,
	Grid,
	GridColumn,
	ButtonGroup
} from 'liana-ui/components/';
import { Size, Spacing, VAlign, Float } from 'liana-ui/types/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon */
component ActionHeader(
	/**
		An action header can have a header.
		PROPS[Header=/components/texts/header/]
	*/
	header?: React.PropsOf<Header>,
	/**
		Array of mass action options.
	*/
	massContextMenu?: React.PropsOf<ContextMenu>,
	/**
		An action header can have a single or multiple action buttons.
		PROPS[Button=/components/buttons/button/]
	*/
	buttons?: React.PropsOf<Button> | Array<React.PropsOf<Button>>,
	/**
		An action header can have a single or multiple button groups.
		PROPS[React.PropsOf<ButtonGroup>=/components/buttons/button-group/]
	*/
	buttonGroup?: React.PropsOf<ButtonGroup> | Array<React.PropsOf<ButtonGroup>>,
	/**
		An action header can have a single or multiple context menus.
		PROPS[ContextMenu=/components/menus/context-menu/]
	*/
	contextMenu?: React.PropsOf<ContextMenu> | Array<React.PropsOf<ContextMenu>>,
	/**
		An action header can have a dropdown for a single filter. Multiple filters (filter property) should be added to filter sdebar using filter property.
		PROPS[DropdownProps=/components/forms/dropdowns/dropdown/]
	*/
	dropdown?: React.PropsOf<Dropdown>,
	/**
		An action header can have a date filter.
		PROPS[Button=/components/buttons/button, DateRangeProps=/components/forms/pickers/date-range/]
	*/
	dateFilter?: {
		color: 'primary',
		buttons: Array<React.PropsOf<Button>>,
		dateRange: any
	},
	/**
		An action header can have a search field.
		PROPS[SearchProps=/components/forms/search/]
	*/
	search?: React.PropsOf<Search>,
	/**
		An action header can have a checkbox or toggle.
		PROPS[Checkbox=/components/forms/checkbox/]
	*/
	checkbox?: React.PropsOf<Checkbox>,
	/**
		An action header can have multiple filters in a side panel. An action header can not have a single filter dropdown (dropdown property) at the same time.
		PROPS[FilterProps=/components/forms/filter/]
	*/
	filter?: React.PropsOf<Filter>,
	/** An action header can be a page level header */
	page?: boolean = false,
	/** An action header can have a divider border at the bottom */
	dividing?: boolean = false,
	/** An action header can be compressed */
	compressed?: boolean = true,
	/** An action header can be smaller in size. */
	size?: Size,
	/** An action header can be stackable. */
	stackable?: boolean = true,
	/** An action header can be sticky. */
	sticky?: boolean = false,
	/** An action header can scroll to an element (CSS selector) on search and filter. */
	scrollTo?: string,
	/** TODO: Undocumented prop */
	massOptions?: any,
	/** TODO: Undocumented prop */
	bottomBorder?: any,
	/** Test ID used for testing. */
	testID: string = 'ActionHeader',
	/** Function called when header changes sticky. */
	onStickyChange?: (boolean) => void,
	/** Function called when header changes sticky. */
	onSizeChange?: (element: HTMLElement) => void
) {
	// Variables and states
	let [randomHeaderID] = useState(`actionheader-${Date.now()}-${Math.round(Math.random() * 9999)}`);
	let [randomStickyObserverID] = useState(`stickyobserver-${Date.now()}-${Math.round(Math.random() * 9999)}`);
	let [stickyActive, setStickyActive] = useState(false);

	// Component mount
	useEffect(() => {
		if (sticky) {
			// Observe when header is actually fixed
			const observer = new IntersectionObserver(
				(entries) => {
					let active = entries[0].intersectionRatio === 0 ? true : false;
					if (typeof onStickyChange === 'function') {
						onStickyChange(active);
					}
					setStickyActive(active);
				},
				{ threshold: [0, 1] }
			);
			const sticky = document.querySelector(`#${randomStickyObserverID}`);

			if (observer && sticky) {
				observer.observe(sticky);
			}
		}
		// Observe when header changes height
		let element = document.getElementById(randomHeaderID);
		new ResizeSensor(element, () => {
			if (typeof onSizeChange === 'function' && element) {
				onSizeChange(element);
			}
		});
	}, []);

	// Scroll view to the top when searching
	useEffect(() => {
		scrollUp();
	}, [search?.value]);

	const getMasContextMenu = () => {
		// Check if exists and no other filters
		if (!massContextMenu) {
			return null;
		}

		return (
			<GridColumn collapsing>
				<ContextMenu {...massContextMenu} selected />
			</GridColumn>
		);
	};

	const getButtons = () => {
		// Check if exists
		if (!buttons && !contextMenu) {
			return null;
		}

		let ret: Array<React.Node> = [];
		if (Array.isArray(buttons) && buttons.length > 0) {
			buttons.map((button, index) => {
				ret.push(
					<Button
						circular={button?.circular === false ? false : true}
						size={button?.size ? button.size : size}
						fitted={!contextMenu && buttons.length === index + 1}
						{...button}
						key={`button-${index}`}
					/>
				);
			});
		} else if (!Array.isArray(buttons) && typeof buttons === 'object') {
			ret.push(
				<Button
					circular={buttons?.circular === false ? false : true}
					size={buttons?.size ? buttons.size : size}
					fitted={!contextMenu}
					{...buttons}
					key='button'
				/>
			);
		}

		if (Array.isArray(contextMenu) && contextMenu.length > 0) {
			contextMenu.map((ctx, index) => {
				ret.push(
					<ContextMenu
						size={size}
						fitted={contextMenu.length === index + 1}
						key={`contextmenu-${index}`}
						{...ctx}
					/>
				);
			});
		} else if (!Array.isArray(contextMenu) && typeof contextMenu === 'object') {
			ret.push(<ContextMenu size={size} fitted {...contextMenu} key='context-menu' />);
		}

		return <GridColumn collapsing>{ret}</GridColumn>;
	};

	const getDropdown = () => {
		// Check if exists and no other filters
		if (!dropdown || filter) {
			return null;
		}

		return (
			<GridColumn collapsing>
				<Dropdown
					size={size}
					icon='options'
					onChange={(event, data) => handleDropdownChange(event, data)}
					{...dropdown}
				/>
			</GridColumn>
		);
	};

	const getDateFilter = () => {
		// Check if exists
		if (!dateFilter) {
			return null;
		}

		let content = null,
			buttons = [],
			dateRange = null;

		if (dateFilter.buttons && dateFilter.buttons.length > 0) {
			dateFilter.buttons.map((button, index) => {
				buttons.push(
					<Button
						{...button}
						onClick={(event, data) => {
							scrollUp();
							if (typeof button.onClick === 'function') {
								button.onClick(event, data);
							}
						}}
						key={`date-filter-button-${index}`}
					/>
				);
			});
		}

		if (dateFilter.dateRange) {
			dateRange = (
				<DateRange
					{...dateFilter.dateRange}
					hideInput={dateFilter.buttons && dateFilter.buttons.length > 0}
					buttonClasses={dateFilter.dateRange.buttonClasses}
					tooltip={
						dateFilter.buttons && dateFilter.buttons.length > 0
							? { 'data-content': dateFilter.dateRange.popup, 'data-variation': 'tiny' }
							: undefined
					}
					onChange={(start, end, name, id) => {
						scrollUp();
						if (typeof dateFilter.dateRange.onChange === 'function') {
							dateFilter.dateRange.onChange(start, end, name, id);
						}
					}}
				/>
			);
		}

		if (buttons.length) {
			content = (
				<ButtonGroup fitted fluid size={size}>
					{buttons}
					{dateRange}
				</ButtonGroup>
			);
		} else {
			content = dateRange;
		}

		return (
			<GridColumn collapsing fluid>
				{content}
			</GridColumn>
		);
	};

	const getSearch = () => {
		// Check if exists
		if (!search) {
			return null;
		}

		return (
			<GridColumn collapsing fluid>
				<Search
					size={size}
					fluid
					onSearchChange={(event, data) => handleSearchChange(event, data)}
					{...search}
				/>
			</GridColumn>
		);
	};

	const getCheckbox = () => {
		// Check if exists
		if (!checkbox) {
			return null;
		}

		return (
			<GridColumn collapsing fluid>
				<Checkbox size={size} {...checkbox} />
			</GridColumn>
		);
	};

	const getButtonGroup = () => {
		// Check if exists and no other filters
		if (!buttonGroup) {
			return null;
		}

		let buttonGroups = [];
		if (Array.isArray(buttonGroup) && buttonGroup.length > 0) {
			buttonGroup.map((group, index) => {
				buttonGroups.push(
					<GridColumn collapsing key={`buttoncolumn-${index}`}>
						<ButtonGroup fitted size={size} {...group} key={index} />
					</GridColumn>
				);
			});
		} else if (!Array.isArray(buttonGroup) && typeof buttonGroup === 'object') {
			buttonGroups.push(
				<GridColumn collapsing key='buttoncolumn'>
					<ButtonGroup fitted size={size} {...buttonGroup} key='button-group' />
				</GridColumn>
			);
		}

		return buttonGroups;
	};

	const getFilter = () => {
		// Check if exists
		if (!filter) {
			return null;
		}

		return (
			<GridColumn collapsing>
				<Filter
					fitted
					size={size}
					{...filter}
					onClear={() => {
						if (typeof filter?.onClear === 'function') {
							filter.onClear();
						}
						scrollUp();
					}}
					onSubmit={() => {
						if (typeof filter?.onSubmit === 'function') {
							filter.onSubmit();
						}
						scrollUp();
					}}
				/>
			</GridColumn>
		);
	};

	// Called on search change.
	const handleSearchChange = (event: SyntheticEvent<>, data: any) => {
		scrollUp();
		// Trigger onSearchChange callback
		if (typeof search?.onSearchChange === 'function') {
			search.onSearchChange(event, data);
		}
	};

	// Called on dropdown change.
	const handleDropdownChange = (event: SyntheticEvent<>, data: any) => {
		scrollUp();
		// Trigger onChange callback
		if (typeof dropdown?.onChange === 'function') {
			dropdown.onChange(event, data);
		}
	};

	// Called on dropdown change.
	const scrollUp = () => {
		if (sticky && stickyActive && scrollTo) {
			Safely.scroll(scrollTo);
		}
	};

	// Assign classes
	const classes = classNames('actionheader-wrapper', {
		dividing: dividing,
		'has-sticky': sticky,
		'is-sticky': stickyActive,
		'border-bottom': bottomBorder
	});

	let hasActions =
		buttons || buttonGroup || contextMenu || massOptions || search || checkbox || dateFilter || dropdown || filter;

	return (
		<>
			{sticky ? <div id={randomStickyObserverID} className='actionheader-sticky-observer'></div> : null}
			<Segment
				id={randomHeaderID}
				basic
				compressed={!page && compressed}
				removeMargins={Spacing.All}
				removePaddings={page ? Spacing.Bottom : undefined}
				className={classes}
				testID={testID}
			>
				<Grid columns={2} stackable={stackable}>
					<GridColumn fluid verticalAlign={VAlign.Middle}>
						{/** $FlowIssue[prop-missing] - Flow can't handle spread props */}
						<Header as='h3' singleLine={!page} size={page ? Size.Massive : size} {...header} />
					</GridColumn>
					{hasActions ? (
						<GridColumn collapsing verticalAlign={VAlign.Top} floated={Float.Right}>
							<Grid stackable={stackable} verticalAlign={VAlign.Middle} compact>
								{getCheckbox()}
								{getMasContextMenu()}
								{getButtons()}
								{getButtonGroup()}
								{getDateFilter()}
								{getFilter()}
								{getDropdown()}
								{getSearch()}
							</Grid>
						</GridColumn>
					) : null}
				</Grid>
			</Segment>
		</>
	);
}

export default (React.memo(ActionHeader): React.AbstractComponent<React.PropsOf<ActionHeader>, mixed>);
