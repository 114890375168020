/**
 * @prettier
 * @flow
 */

import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Transition as SUIRTransition } from 'semantic-ui-react';
import TransitionGroup from './TransitionGroup';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/transition/ */
component Transition(
	/** Key */
	reactKey?: string,
	/** Primary content. */
	children: React.Node,
	/** Show the component. Triggers the enter or exit animation. */
	visible?: boolean = false,
	/** Run the enter animation when the component mounts, if it is initially shown. */
	transitionOnMount?: boolean = false,
	/** Unmount the component (remove it from the DOM) when it is not shown. */
	unmountOnHide?: boolean,
	/** Named animation event to used. Must be defined in CSS. */
	animation?:
		| null
		| 'height'
		| 'fade'
		| 'fade up'
		| 'fade down'
		| 'fly left'
		| 'fly right'
		| 'scale'
		| 'slide up'
		| 'slide down'
		| 'pulse'
		| 'glow' = 'fade',
	/** Duration of the CSS transition animation in milliseconds. */
	duration?: number = 250,
	/** Delay of the CSS transition animation in milliseconds. */
	delay?: number = 0,
	/** Callback on each transition that changes visibility to hidden. */
	onHide?: () => void,
	/** Callback on each transition that changes visibility to shown. */
	onShow?: () => void,
	/** Callback on each transition that is complete. */
	onComplete?: (any) => void
) {
	let [delayedVisible, setDelayedVisible] = useState(false);

	// componentDidMount
	useEffect(() => {
		/* istanbul ignore else */
		if (delay) {
			setTimeout(() => setDelayedVisible(true), delay);
		}
	}, []);

	return animation === 'height' ? (
		<AnimateHeight
			key={reactKey}
			animateOpacity
			easing='ease-in-out'
			duration={duration}
			delay={delay}
			height={visible ? 'auto' : 0}
			onAnimationEnd={onComplete}
		>
			{children}
		</AnimateHeight>
	) : (
		<SUIRTransition
			key={reactKey}
			visible={delay ? delayedVisible : visible}
			transitionOnMount={transitionOnMount}
			unmountOnHide={unmountOnHide}
			animation={animation}
			duration={duration}
			delay={delay}
			onHide={onHide}
			onShow={onShow}
			onComplete={onComplete}
		>
			{children}
		</SUIRTransition>
	);
}

const MemoComponent = (React.memo(Transition): React.AbstractComponent<React.PropsOf<Transition>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Group = TransitionGroup;

export default MemoComponent;
