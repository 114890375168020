/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import TooltipSlider from './src/SliderTooltip';
import classNames from 'classnames';
import { Input, Popup } from 'liana-ui/components/';

/** COMPONENT BASED ON: https://slider-react-component.vercel.app */
component NumberSlider(
	/** A number slider must have an input name */
	name: string,
	/** Initial value for number slider. Use for uncontrolled components only. */
	defaultValue?: number = 50,
	/** Current value for number slider. Use for controlled components only. */
	value?: number,
	/** A number slider can have minimum value. */
	min?: number = 0,
	/** A number slider can have maximum value. */
	max?: number = 100,
	/** A number slider can have steps between slider value points. */
	step?: number = 1,
	/** A number slider can have different unit. VALUES['%', '€', '$', '...'] */
	unit?: string,
	/** A number slider can have dots to reprecent steps when step if greater than 1. */
	dots?: string,
	/** A number slider can be disabled. */
	disabled?: boolean = false,
	/** A number slider can be locked. */
	locked?: boolean = false,
	/** An input can be different size. */
	size?: 'small' | 'large',
	/**
			Popup text or, react-intl component or object of properties for Popup component.
			PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
		 */
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'NumberSlider',
	/** Function called when slider drag is started. */
	onChange?: (data: { name: string, value: number }) => void
) {
	// Internal states
	let [internalValue, setInternalValue] = useState(defaultValue);
	let currentValue = value === undefined ? internalValue : value;

	const handleChange = (val: number) => {
		if (typeof onChange === 'function') {
			onChange({
				name: name,
				value: val
			});
		}
		setInternalValue(val);
	};

	let classes = classNames(
		'number-slider-wrapper',
		{
			locked: locked
		},
		size
	);

	let containerClasses = classNames('number-slider-container', {
		disabled: disabled
	});

	let slider = (
		<div className={classes} data-testid={testID}>
			<Input
				name={name}
				value={String(currentValue)}
				type='number'
				min={min}
				max={max}
				locked={locked}
				disabled={disabled}
				onChange={(event, data) => handleChange(Number(data.value))}
			/>

			<div className={containerClasses}>
				<div className='number-slider-amount-min'>
					{min}
					{unit}
				</div>
				<TooltipSlider
					value={currentValue}
					step={step}
					min={min}
					max={max}
					unit={unit}
					dots={dots}
					disabled={disabled || locked}
					onChange={handleChange}
				/>
				<div className='number-slider-amount-max'>
					{max}
					{unit}
				</div>
			</div>
		</div>
	);

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, slider);
}

export default (React.memo(NumberSlider): React.AbstractComponent<React.PropsOf<NumberSlider>, mixed>);
