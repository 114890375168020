/**
 * @prettier
 * @flow
 */

import { StepDescription as SUIRStepDescription } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
component StepDescription(
	/** Description as text. */
	text: React.Node,
	/** Test ID for testing. */
	testID: string = 'StepDescription'
) {
	return <SUIRStepDescription data-testid={testID}>{text}</SUIRStepDescription>;
}

export default (React.memo(StepDescription): React.AbstractComponent<React.PropsOf<StepDescription>, mixed>);
