/**
 * @prettier
 * @flow
 */

import { GridRow as SUIRGridRow } from 'semantic-ui-react';
import { Responsive } from 'liana-ui/components/';
import { Device, HAlign, VAlign } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/grid/ */
component GridRow(
	/** Content as children. */
	children: React.Node,
	/** Represents column count per line in Row. Possible values: 1 - 12 */
	columns?: string | number,
	/** A row can have its columns centered. */
	centered?: boolean,
	/** A grid can specify its text alignment. */
	textAlign?: HAlign,
	/** A grid can specify its vertical alignment to have all its columns vertically aligned. */
	verticalAlign?: VAlign,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Test ID for testing */
	testID: string = 'GridRow'
) {
	// Function to generate LianaUI GridColumn
	const createRow = () => {
		return (
			<SUIRGridRow
				columns={columns}
				centered={centered}
				textAlign={textAlign}
				verticalAlign={verticalAlign}
				data-testid={testID}
			>
				{children}
			</SUIRGridRow>
		);
	};

	// Display reponsively
	let row =
		minDevice || maxDevice ? (
			<Responsive minDevice={minDevice} maxDevice={maxDevice}>
				{createRow()}
			</Responsive>
		) : (
			createRow()
		);

	return row;
}

export default (React.memo(GridRow): React.AbstractComponent<React.PropsOf<GridRow>, mixed>);
