/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { NavLink } from 'liana-ui/components';
import { Tab, TabPane, MenuItem } from 'semantic-ui-react';
import { Icon, Label } from 'liana-ui/components';
import { Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/button/ */
component Tabs(
	/** Index of the currently active tab. */
	activeIndex?: number,
	/** The initial activeIndex. */
	defaultActiveIndex?: number,
	/** An array of panes. */
	panes: Array<{
		id: string,
		menuItem: {
			name: React.Node,
			icon: string | React.PropsOf<Icon>,
			upsell: boolean,
			label: React.PropsOf<Label>,
			link: string,
			disabled: boolean,
			testID?: string
		},
		pane: {
			content: React.Node,
			loading: boolean,
			removeBorders: boolean,
			removePaddings: boolean,
			testID?: string
		}
	}>,
	/** A tabs is full width with even width centerd tabs. */
	fluid?: boolean,
	/** A tabs can display a loading indicator. */
	loading?: boolean,
	/** A basic tabs can remove borders around the content panel. */
	removeBorders?: boolean,
	/** A tabs can remove borders around the content panel. */
	removePaddings?: boolean,
	/** TODO: Undocumented prop */
	tabular?: boolean = false,
	/** Test ID for testing */
	testID: string = 'Tabs',
	/** Function called on tab change. */
	onTabChange?: (
		event: SyntheticEvent<>,
		data: {
			activeIndex: number,
			id: string
		}
	) => void
) {
	const handleTabChange = (event: SyntheticEvent<>, data: any) => {
		if (typeof onTabChange === 'function') {
			onTabChange(event, handleCallbackData(data));
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => ({
		activeIndex: data.activeIndex,
		id: panes[data.activeIndex]?.id
	});

	const getTabContent = (menuItem: any) => {
		return (
			<>
				{menuItem?.icon ? (
					typeof menuItem.icon === 'string' ? (
						<Icon name={menuItem.icon} />
					) : (
						<Icon {...menuItem.icon} />
					)
				) : null}
				{menuItem?.name}
				{menuItem?.upsell ? <Icon squared={true} name='fa-gem' color='purple' size={Size.Small} /> : null}
				{menuItem?.label ? <Label {...menuItem?.label} /> : null}
			</>
		);
	};

	const getPanes = () => {
		let ret = [];
		if (panes) {
			for (let i = 0; i < panes.length; i++) {
				let menuItem = panes[i]?.menuItem,
					pane = panes[i]?.pane,
					commonProps = {
						key: `tab-${i}`,
						className: menuItem?.disabled ? 'disabled' : undefined,
						'data-testid': menuItem.testID || `Tab::MenuItem::${i}`
					};
				if (menuItem?.link) {
					commonProps = {
						...commonProps,
						as: NavLink,
						to: menuItem.link,
						end: true,
						activeClassName: 'active'
					};
				}
				ret.push({
					menuItem: menuItem?.link ? (
						<MenuItem active={false} {...commonProps}>
							{getTabContent(menuItem)}
						</MenuItem>
					) : (
						<MenuItem {...commonProps}>{getTabContent(menuItem)}</MenuItem>
					),
					render: () =>
						pane?.content ? (
							<TabPane
								loading={loading || pane.loading}
								attached={tabular ? 'bottom' : undefined}
								basic={removeBorders || pane.removeBorders}
								className={removePaddings || pane.removePaddings ? 'remove-paddings' : ''}
								data-testid={pane.testID || `Tab::TabPane::${i}`}
							>
								{pane.content}
							</TabPane>
						) : null
				});
			}
		}
		return ret;
	};

	const classes = classNames('tabs', {
		'remove-borders': removeBorders
	});

	const menuClasses = classNames({
		fluid: fluid
	});

	let tabs = (
		<Tab
			className={classes}
			activeIndex={activeIndex}
			defaultActiveIndex={defaultActiveIndex}
			menu={{
				secondary: !tabular,
				tabular: tabular,
				attached: tabular,
				className: menuClasses
			}}
			panes={getPanes()}
			data-testid={testID}
			onTabChange={handleTabChange}
		/>
	);

	return tabs;
}

export default (React.memo(Tabs): React.AbstractComponent<React.PropsOf<Tabs>, mixed>);
