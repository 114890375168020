/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { BrowserUtils } from 'liana-ui/definitions/';
import { Icon as SUIRIcon } from 'semantic-ui-react';
import { Label, Popup, Responsive } from 'liana-ui/components/';
import { Device, Float, Size } from 'liana-ui/types';
import IconGroup from './IconGroup';

type Style = {
	color?: string,
	background?: string,
	transform?: string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon */
component Icon(
	/**
		An icon must have a icon name as string.
		DATA[https://react.semantic-ui.com/elements/icon/]
	*/
	name: string,
	/** An icon can be solid instead outlined. */
	solid?: boolean = false,
	/** An icon can be a brand icon. */
	brand?: boolean = false,
	/** An icon can have a notification label with a number. */
	notification?: number | false = false,
	/** An icon can have an number on top of it */
	number?: number,
	/** An icon can appear on the left or right of the text. */
	iconPosition?: Float = Float.Left,
	/** An icon can formatted to appear circular. */
	circular?: boolean = false,
	/** An icon can formatted to appear squared. */
	squared?: boolean = false,
	/* An icon can be formatted to appear as an avatar. */
	avatar?: boolean,
	/** An icon can have its colors inverted for dark backgrounds. */
	inverted?: boolean = false,
	/** An icon can be fitted without space to left or right of icon. */
	fitted?: boolean = false,
	/**
		An icon can be different predefined color or hex color.
		VALUES['primary', 'red', 'orange', 'yellow', 'green', 'blue', 'grey', 'black', 'automation', 'mailer', 'press', 'cms', 'commerce', 'monitor', 'account', 'admin', '#FF0000', '#...']
	*/
	color?: string,
	/** An icon can be aligned to the left or right of its container. */
	floated?: Float,
	/** An icon can be different size. */
	size?: 'medium-size' | Size,
	/** An icon can have a spinning animation. */
	spinning?: boolean = false,
	/** An icon can be rotated by amount of degrees. */
	rotate?: number,
	/**
		An icon can be a smaller conrner icon.
		VALUES['top left', 'top right', 'bottom left', 'bottom right']
	*/
	corner?: boolean | string,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/* A button can have additional classes. Use for very special features only! */
	className?: string,
	/** Function called on click. */
	onClick?: (event: SyntheticEvent<>, data: { name: string }) => void,
	/* DO NOT USE dataAttributes! Will be removed as soon as top panel is refactored. */
	dataAttributes?: { [string]: string },
	/** Test ID for testing */
	testID: string = 'Icon'
) {
	const handleClick = (event: SyntheticEvent<>, data: any) => {
		// Trigger onClick callback funtion
		if (typeof onClick === 'function') {
			onClick(event, handleCallbackData(data));
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => {
		return {
			name: data.name
		};
	};

	// Function to generate LianaUI Button
	const createIcon = () => {
		// Assign classes
		let classes = classNames(
			{
				'fa-solid': solid,
				'fa-brands': brand,
				squared: squared,
				label: squared,
				avatar: avatar,
				notification: notification,
				primary: color === 'primary',
				outlined: BrowserUtils.isLowContrast(color, 240) || color === 'white',
				loading: spinning,
				'float-right': floated === Float.Right,
				'float-left': floated === Float.Left,
				'icon-right': iconPosition === Float.Right,
				'number-icon': !isNaN(number),
				'cursor-pointer': onClick
			},
			className
		);

		// Assign color
		let style: Style = {};
		if (typeof color === 'string' && color[0] === '#') {
			style = circular && inverted ? { color: '#FFF', background: color } : { color };
		}

		// Assign rotate
		if (rotate) {
			style.transform = 'rotate(' + rotate + 'deg)';
		}

		let icon = (
			/** $FlowExpectedError[cannot-spread-indexer] */
			<SUIRIcon
				className={classes}
				circular={circular || squared}
				inverted={inverted}
				fitted={fitted}
				color={!style.color && color !== 'primary' ? color : undefined}
				corner={corner}
				name={name}
				size={size}
				onClick={handleClick}
				style={{ ...style }}
				data-testid={testID}
				{...dataAttributes} /** DO NOT USE dataAttributes! Will be removed as soon as top panel is refactored. */
			>
				{notification ? (
					<Label size={Size.Tiny} notification circular blinking text={String(notification)} />
				) : null}
				{!isNaN(number) ? <span className='number'>{number}</span> : null}
			</SUIRIcon>
		);

		// $FlowIssue - React statics; Attach popup
		return Popup.attach(popup, icon);
	};

	// Display reponsively
	let component =
		minDevice || maxDevice || hideTouch ? (
			<Responsive minDevice={minDevice} maxDevice={maxDevice} hideTouch={hideTouch}>
				{createIcon()}
			</Responsive>
		) : (
			createIcon()
		);

	return component;
}

const MemoComponent = (React.memo(Icon): React.AbstractComponent<React.PropsOf<Icon>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Group = IconGroup;

export default MemoComponent;
