/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Divider as SUIRDivider } from 'semantic-ui-react';
import { Icon, Popup } from 'liana-ui/components/';
import { Spacing } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/divider */
component Divider(
	/** Element to render as */
	as: 'div' | 'label' = 'div',
	/** Divider text */
	text?: React.Node,
	/** Align divider can be stronger by being darker. */
	strong?: 'left',
	/** Align divider text. */
	textAlign?: 'left' = 'center',
	/** Add an Icon by name. */
	icon?: string,
	/** Divider can segment content vertically. */
	vertical?: boolean = false,
	/** Divider can have a help popup. */
	help?: React.Node | React.PropsOf<Popup>,
	/** Remove margins around the divider. */
	removeMargins?: Spacing,
	/** Test ID used for testing. */
	testID: string = 'Divider'
) {
	// Assign classes
	let classes = classNames({
		strong: strong,
		'no-text': !text,
		'left aligned': textAlign === 'left',
		'remove-margins': removeMargins === Spacing.All,
		'remove-top-margin': removeMargins === Spacing.Top,
		'remove-bottom-margin': removeMargins === Spacing.Bottom
	});

	let divider = (
		<SUIRDivider
			as={as}
			className={classes}
			vertical={vertical}
			horizontal={Boolean(!vertical && text)}
			data-testid={testID}
		>
			{icon ? <Icon name={icon} /> : null}
			<span className='text'>{text}</span>
			{help ? <Icon name='fa-question-circle cursor-help' color='primary' popup={help} /> : null}
		</SUIRDivider>
	);
	return divider;
}

export default (React.memo(Divider): React.AbstractComponent<React.PropsOf<Divider>, mixed>);
