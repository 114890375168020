/**
 * @prettier
 * @flow
 */

import { Zendesk, Gainsight } from 'liana-ui/definitions';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { Icon, Popup } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
component SupportMenu(
	/** If is open */
	open: boolean = false,
	/** Support menu data. Support object: DATA[json/support/support.json] */
	support?: {
		site: { active: boolean, link: boolean },
		chat: { active: boolean },
		knowledgeBase: { active: boolean },
		feedback: { active: boolean }
	},
	/** Position, with and heigh of dropdown menu */
	style?: { [string]: string | number },
	/** Object of properties for Popup */
	popup?: React.PropsOf<Popup>,
	/** Test ID used for testing. */
	testID: string = 'UserMenu',
	/** Called on feedback submit. */
	onFeedbackOpen?: () => void,
	/** Called on menu open. */
	onOpen?: (event: SyntheticEvent<>, data: { id: string }) => void,
	/** Called on menu close. */
	onClose?: (event: Event, data: { id: string }) => void
) {
	// Render nothing if empty
	if (!support) {
		return null;
	}

	const ID = 'support';

	const isMobile =
		document.querySelector('html')?.classList.contains('mobile') ||
		document.querySelector('html')?.classList.contains('tablet');

	const getSupport = () => {
		let options = [];

		if (support.site && support.site.active && support.site.link) {
			options.push(
				<Dropdown.Item
					as='a'
					href={support.site.link}
					target='_blank'
					icon='fa-memo-circle-info'
					text={<FormattedMessage id='component.support-menu.site.title' />}
				/>
			);
		}

		if (support.knowledgeBase && support.knowledgeBase.active && Gainsight.getKey()) {
			options.push(
				<Dropdown.Item
					icon='fa-question-circle'
					text={<FormattedMessage id='component.support-menu.knowledgeBase.title' />}
					onClick={() => {
						Zendesk.closeChat();
						Gainsight.openBot();
					}}
				/>
			);
		}

		if (support.chat && support.chat.active && Zendesk.getKey()) {
			options.push(
				<Dropdown.Item
					icon='fa-comments-question'
					text={<FormattedMessage id='component.support-menu.chat.title' />}
					onClick={() => {
						Gainsight.closeBot();
						Zendesk.openChat();
					}}
				/>
			);
		}

		if (support.feedback && support.feedback.active) {
			options.push(<Dropdown.Divider />);
			options.push(
				<Dropdown.Item
					icon='fa-send'
					text={<FormattedMessage id='component.support-menu.sendFeedback.title' />}
					onClick={onFeedbackOpen}
				/>
			);
		}

		return options;
	};

	const handleOpen = (event: SyntheticEvent<>, data: any) => {
		if (typeof onOpen === 'function') {
			onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: Event, data: any) => {
		if (typeof onClose === 'function') {
			onClose(event, handleCallbackData(data));
		}
	};

	// Handle data returned by onClose callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	let menu = (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={open}
			item
			icon={
				<Icon
					name={isMobile && open ? 'fa-close' : 'fa-question'}
					size={isMobile && open ? undefined : Size.Large}
					circular={isMobile && open ? true : false}
				/>
			}
			testID={testID}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<Dropdown.Menu id={`${ID}Dropdown`} style={style}>
				{open ? getSupport() : null}
			</Dropdown.Menu>
		</Dropdown>
	);

	// $FlowIssue - React statics; Attach popup
	return !open ? Popup.attach({ text: <FormattedMessage id='component.support-menu.name' />, ...popup }, menu) : menu;
}

export default (React.memo(SupportMenu): React.AbstractComponent<React.PropsOf<SupportMenu>, mixed>);
