/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Placeholder as SUIRPlaceholder } from 'semantic-ui-react';
import { Visibility, Text } from 'liana-ui/components';
import { HAlign, Size } from 'liana-ui/types';
import PlaceholderImage from './PlaceholderImage';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/placeholder/ */
component Placeholder(
	/** Primary content. */
	children: React.Node,
	/** Additional classes. */
	className?: string,
	/** A placeholder can have an error. */
	error?: boolean = false,
	/** 
			 A placeholder can have error text. 
			 PROPS[React.Node=/language/localisation/]
		*/
	errorText?: React.Node,
	/** Test ID used for testing. */
	testID: string = 'Placeholder',
	/** Element's top edge has passed bottom of screen. */
	onTopVisible: () => void
) {
	// Assign placeholder
	let classes = classNames(
		{
			error: error
		},
		className
	);

	let placeholder = (
		<SUIRPlaceholder className={classes} data-testid={testID}>
			{children}
			{error && errorText ? (
				<Text size={Size.Small} as='div' color='grey' textAlign={HAlign.Center} className='error-text'>
					{errorText}
				</Text>
			) : null}
		</SUIRPlaceholder>
	);

	// Add responsive wrapper
	if (typeof onTopVisible === 'function') {
		placeholder = (
			<Visibility as={React.Fragment} onTopVisible={onTopVisible}>
				{placeholder}
			</Visibility>
		);
	}

	return placeholder;
}

const MemoComponent = (React.memo(Placeholder): React.AbstractComponent<React.PropsOf<Placeholder>, mixed>);
// $FlowIssue - Static subcomponents
MemoComponent.Image = PlaceholderImage;

export default MemoComponent;
