/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import classNames from 'classnames';
import { Device } from 'liana-ui/types/';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Label, LimitModal } from 'liana-ui/components/';
import { Size } from 'liana-ui/types/';

const DefaultTranslations = {
	amount: 'component.limit-label.amount',
	upgrade: 'component.limit-label.upgrade',
	limit: 'component.limit-label.limit',
	limitedFeature: 'component.limit-label.limitedFeature',
	limitReached: 'component.limit-label.limitReached',
	modalHeader: 'component.limit-label.modalHeader',
	messageHeader: 'component.limit-label.messageHeader',
	close: 'component.modal.close'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/label */
component LimitLabel(
	/** A default limit label/modal must have upgrade page url */
	upgradeLink: string,
	/** A limit label can have current amount of items. */
	amount?: number = 0,
	/** A limit label can have limit amount of items. */
	limit?: number = 0,
	/** A limit label can hide the current items amount. */
	hideAmount?: boolean = false,
	/**
		A limit label modal image can be changed
	*/
	image?: string,
	/**
		A limit label modal description can be changed.
		PROPS[React.Node=/localization/]
	*/
	description?: React.Node,
	/** A limit label can have different sizes. */
	size?: Size = Size.Small,
	/** Smallest device that component will be displayed with. */
	minDevice?: Device,
	/** Largest device that component will be displayed with. */
	maxDevice?: Device,
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/** Custom translations. */
	translations: { [string]: string } = DefaultTranslations,
	/** Test ID for testing */
	testID: string = 'LimitLabel',
	/** Function called on link click. Will override the opening of the default LimitModal. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name?: string,
			off: boolean
		}
	) => void
) {
	// $FlowIssue - Merge translations
	let merged: { [string]: string } = { ...DefaultTranslations, ...translations };

	// Modal state
	let [modalOpen, setModalOpen] = useState(false);

	const handleClick = (event: SyntheticEvent<>, data: any) => {
		if (upgradeLink) {
			setModalOpen(true);
		}
		if (typeof onClick === 'function') {
			onClick(event, data);
		}
	};

	// Init
	const percentage = (amount / limit) * 100,
		icon = {
			name: 'fa-gem',
			color: 'purple'
		},
		limitText =
			percentage >= 100 ? (
				<FormattedMessage id={merged.limitReached} />
			) : (
				<>
					<FormattedMessage id={merged.limit} />:{' '}
					<FormattedNumber value={limit} notation='compact' compactDisplay='short' />
				</>
			),
		detailClasses = classNames({
			'remove-left-margin': hideAmount
		}),
		limitDetail =
			amount && limit !== undefined
				? {
						content: limitText,
						className: detailClasses,
						icon: icon.color ? icon : undefined,
						onClick: handleClick,
						popup: {
							text: <FormattedMessage id={merged.upgrade} />,
							offset: [0, 5],
							size: 'mini'
						}
					}
				: {
						content: <FormattedMessage id={merged.limitedFeature} />,
						className: 'remove-left-margin',
						icon: icon.color ? icon : undefined,
						onClick: handleClick,
						popup: {
							text: <FormattedMessage id={merged.upgrade} />,
							offset: [0, 5],
							size: 'mini'
						}
					};
	return (
		<>
			<Label
				text={
					!hideAmount && amount ? <FormattedMessage id={merged.amount} values={{ amount: amount }} /> : null
				}
				// $FlowFixMe - Impossible type refinement
				detail={limitDetail}
				size={size}
				maxDevice={maxDevice}
				minDevice={minDevice}
				hideTouch={hideTouch}
				testID={testID}
			/>
			{upgradeLink ? (
				<LimitModal
					open={modalOpen}
					image={image}
					description={description}
					translations={merged}
					upgradeLink={upgradeLink}
					onClose={() => setModalOpen(false)}
				/>
			) : null}
		</>
	);
}

export default (React.memo(LimitLabel): React.AbstractComponent<React.PropsOf<LimitLabel>, mixed>);
