/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Placeholder } from 'semantic-ui-react';
import { Size } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/placeholder/#content-image */
component PlaceholderImage(
	/** An image may appear at different sizes. */
	size?: Size,
	/** Width/Height ratio for fluid responsive image placeholder loaders. */
	ratio?: string = '1/1',
	/** Max height of responsive images */
	maxHeight?: string,
	/** Test ID used for testing. */
	testID: string = 'PlaceholderImage'
) {
	// Assign placeholder image
	let placeholderImageClasses = classNames('ui', size ? size : '');

	// Count fluid placeholder ratio
	let placeholderPadding = '100%';
	if (ratio && typeof ratio === 'string' && ratio.indexOf('/') > -1) {
		let parsed: any = ratio.split('/').map(Number);
		placeholderPadding = `${(parsed[1] / parsed[0]) * 100}%`;
	}

	let styles = {
		paddingTop: maxHeight ? maxHeight : placeholderPadding
	};

	return <Placeholder.Image className={placeholderImageClasses} style={styles} data-testid={testID} />;
}

export default (React.memo(PlaceholderImage): React.AbstractComponent<React.PropsOf<PlaceholderImage>, mixed>);
