/**
 * @prettier
 * @flow
 */

import { useState, useRef } from 'react';
import { Input, Label, Grid, Button, GridColumn } from 'liana-ui/components';
import { VAlign, Size, On, Position } from 'liana-ui/types';
import SketchPicker from 'react-color/lib/components/sketch/Sketch';

// Component render conditions. True if should not render.
const EQUALS = (prevProps: React.PropsOf<ColorInput>, nextProps: React.PropsOf<ColorInput>) =>
	prevProps.value === nextProps.value &&
	prevProps.disabled === nextProps.disabled &&
	prevProps.locked === nextProps.locked;

/** COMPONENT BASED ON: https://casesandberg.github.io/react-color/ */
component ColorInput(
	/** A color input must have an input name */
	name: string,
	/** Initial value for input. Use for uncontrolled components only. VALUES['#FF0000', '#FFF...'] */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. VALUES['#FF0000', '#FFF...'] */
	value?: string,
	/** An color input can have a placeholder text. Use intl.formatMessage() for translated strings. */
	placeholder?: string,
	/** A color input can be clearable */
	clearable: boolean = false,
	/** A color input can be locked to indicate that the field is in use but can not be edited. */
	locked: boolean = false,
	/** An color input can be  disabled. */
	disabled: boolean = false,
	/** A color input can be different size. */
	size?: Size,
	/** A color input can enable alpha (opacity) channel. */
	enableAlpha: boolean = false,
	/** Test ID for testing */
	testID: string = 'ColorInput',
	/** Function called on any color input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void
) {
	// Variables and refs
	const inputRef = useRef<HTMLInputElement | null>(null);
	const presetColors = [
		'#4d8931',
		'#f5c400',
		'#f18e1c',
		'#e32322',
		'#6d398b',
		'#2a71b0',
		'#006f47',
		'#425b12',
		'#cb6306',
		'#892e0f',
		'#62013b',
		'#202549',
		'#03465b',
		'#263238',
		'#546e7a',
		'#78909c'
	];

	// Internal states
	let [internalValue, setInternalValue] = useState(defaultValue);
	const currentValue = value === undefined ? internalValue : value;

	// Called on input change.
	const handleChange = (data: any, event: SyntheticEvent<>) => {
		// Set current value internally
		setInternalValue(data.hex);

		// Trigger onChange callback with formatted data
		if (typeof onChange === 'function') {
			onChange(event, handleCallbackData(data));
		}

		// Trigger Form onChange on value change
		if (inputRef.current) {
			inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => ({
		name: name,
		value: data.hex
	});

	const handleClear = (event: SyntheticEvent<>) => {
		handleChange({ hex: '' }, event);
	};

	const isClearButtonShown = clearable && !(!currentValue || disabled || locked);

	// Field clear button
	const clearButton = isClearButtonShown ? (
		<Button
			icon={{ name: 'fa-remove', solid: true }}
			name='color-input-reset-button'
			circular
			size={Size.ExtraMini}
			onClick={handleClear}
		/>
	) : null;

	let input = (
		<Grid verticalAlign={VAlign.Middle} testID={testID}>
			<GridColumn collapsing>
				<Label
					empty
					fitted
					circular
					size={Size.Massive}
					floated='left'
					color={currentValue ? currentValue : undefined}
				/>
			</GridColumn>
			<GridColumn fluid>
				<div className='color-input-wrapper'>
					{clearButton}
					<Input
						/** $FlowFixMe - Don't know what's the correct ref here */
						ref={inputRef}
						name={name}
						placeholder={placeholder}
						value={currentValue}
						locked={locked}
						disabled={disabled || locked}
						readOnly
						size={size}
						icon='eyedropper'
						popup={{
							content: (
								<SketchPicker
									disableAlpha={!enableAlpha}
									presetColors={presetColors}
									color={currentValue ? currentValue : ''}
									onChange={handleChange}
								/>
							),
							on: On.Click,
							position: Position.BottomLeft
						}}
					/>
				</div>
			</GridColumn>
		</Grid>
	);

	return input;
}

export default (React.memo(ColorInput, EQUALS): React.AbstractComponent<React.PropsOf<ColorInput>, mixed>);
