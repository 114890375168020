/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Validate } from 'liana-ui/definitions';
import { Search as SUIRSearch } from 'semantic-ui-react';
import { Button, Popup } from 'liana-ui/components';
import { Size } from 'liana-ui/types';
import SearchResult from './src/SearchResult';

type callbackType = (
	event: SyntheticEvent<>,
	data: {
		name?: string,
		value?: string,
		minCharacters?: number
	}
) => void;

type Result = {
	as?: string,
	href?: string,
	title?: string,
	icon?: string,
	description?: string,
	link?: string,
	showMoreLink?: boolean,
	onClick?: (event: SyntheticEvent<>, data: any) => void
};

// Component render conditions. True if should not render.
const EQUALS = (prevProps: React.PropsOf<Search>, nextProps: React.PropsOf<Search>) =>
	prevProps.value === nextProps.value &&
	prevProps.loading === nextProps.loading &&
	prevProps.disabled === nextProps.disabled &&
	prevProps.locked === nextProps.locked &&
	isEqual(prevProps.results, nextProps.results);

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/search/ */
component Search(
	ref: React.RefSetter<HTMLElement>,
	/** A search can have an input name */
	name?: string,
	/** Initial value for input. Use for uncontrolled components only. */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. */
	value?: string,
	/** An search can have a placeholder text. Use intl.formatMessage() for translated strings. */
	placeholder: React.Node,
	/**
		A search can have search results.
		DATA[json/search/autocomplete-with-links.json]
	*/
	results: Array<Result> | false = false,
	/**
		A search can have a custom message to display when there are no results.
		PROPS[React.Node=/localization/]
	*/
	noResultsMessage?: React.Node = <FormattedMessage id='component.search-input.noResults' />,
	/** A search can be loading when updating results. */
	loading?: boolean = false,
	/** An input can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean = false,
	/** A search can be disabled. */
	disabled?: boolean = false,
	/** A search can take on the size of its container. */
	fluid?: boolean = false,
	/** A search can be clearable. */
	clearable?: boolean = true,
	/** A search can delay onSearchChangeDelay callback. */
	delay?: number = 400,
	/** A search can have maximum amount of results to display. */
	maxResults?: number,
	/** A search can have minimum characters required to query and show results. */
	minCharacters?: number = 1,
	/** A search can have a 'Show more' link if results amount is bigger than maxResults. */
	showMoreLink?: string,
	/** A search can have a Url to submit the search query to. */
	submitUrl?: string,
	/** A search can be different size. */
	size?: Size,
	/** Number that will be shown on 'Show all results' button */
	total?: number,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing. */
	testID: string = 'Search',
	/** Function called on search focus. */
	onFocus?: callbackType,
	/** Function called on search submit. */
	onSubmit?: callbackType,
	/** Function called on search input change. */
	onSearchChange?: callbackType,
	/** Function called on search input change after delay. Use delay property to control time. */
	onSearchChangeDelay?: callbackType,
	/** Function called on search resukt select. */
	onResultSelect?: callbackType
) {
	const intl = useIntl();
	const navigate = useNavigate();
	let timeoutRef = useRef<TimeoutID | null>(null);

	// Variables and refs
	let searchWrapperRef = useRef<HTMLDivElement | null>(null);

	// Internal states
	let [internalValue, setInternalValue] = useState(defaultValue);
	const currentValue = value === undefined ? internalValue : value;

	// Handle data returned by callbacks.
	const handleCallbackData = (data: { value: string }) => ({
		name: name,
		value: data.value,
		minCharacters: minCharacters
	});

	// Function called on clear button click
	const handleClear = (event: SyntheticEvent<>) => {
		handleChange(event, handleCallbackData({ value: '' }));
		searchWrapperRef.current?.querySelector('input')?.focus();
	};

	// Function called on input focus.
	const handleFocus = (event: SyntheticInputEvent<>) => {
		if (typeof onFocus === 'function') {
			onFocus(event, handleCallbackData({ value: event.target.value }));
		}
	};

	// Function called on input change.
	const handleChange = (event: SyntheticEvent<>, data: any) => {
		// Set current value internally
		setInternalValue(data.value);

		// Trigger onChange callback with formatted data
		if (typeof onSearchChange === 'function') {
			onSearchChange(event, handleCallbackData(data));
		}

		// Function called on input change with set delay.
		if (typeof onSearchChangeDelay === 'function') {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
			if (data.value) {
				timeoutRef.current = setTimeout(() => {
					onSearchChangeDelay(event, handleCallbackData(data));
				}, delay);
			} else {
				onSearchChangeDelay(event, handleCallbackData(data));
			}
		}
	};

	// Called result select.
	const handleSelect = (event: SyntheticEvent<>, data: any) => {
		// Set result title as selected result
		data.value = data.result.title;

		if (data.result.link) {
			removeValue();
			if (event.type !== 'click') {
				redirect(data.result.link);
			}
		} else {
			// Set current value internally
			setInternalValue(data.value);

			// Trigger Form onChange to animate submit button into view etc.
			if (typeof event === 'object' && event.target && 'dispatchEvent' in event) {
				event.target.dispatchEvent(new Event('change', { bubbles: true }));
			}
		}

		if (typeof onResultSelect === 'function') {
			onResultSelect(event, handleCallbackData(data));
		}
	};

	// Called result click.
	const handleClick = (event: SyntheticEvent<>, data: any) => {
		if (data.link) {
			event.preventDefault();
			redirect(data.link);
		}
	};

	// Called field submit.
	const handleSubmit = (event?: SyntheticInputEvent<>) => {
		// $FlowIssue - Submit on button click and keyboard Enter
		if (submitUrl && event && (event.type === 'click' || (event.type === 'keypress' && event.key === 'Enter'))) {
			event.preventDefault();
			event.stopPropagation();
			if (typeof onSubmit === 'function') {
				onSubmit(event, handleCallbackData({ value: event.target.value }));
			}
			navigate(`${submitUrl}?q=${currentValue ? encodeURIComponent(currentValue) : ''}`);
			removeValue();
		}
	};

	// Remove input value
	const removeValue = () => {
		// $FlowFixMe[incompatible-call] - This was formerly null; a bad hack
		handleChange(new Event('change'), { value: '' });
		searchWrapperRef.current?.querySelector('input')?.blur();
	};

	// Redirect to link
	const redirect = (link: string) => {
		// Check link type
		let linkType = Validate.linkType(link);

		// Trigger internal link
		if (linkType) {
			if (linkType === 'external') {
				window.open(link, '_blank').focus();
			}
			if (linkType === 'internal') {
				navigate(link);
			}
			if (linkType === 'anchor') {
				Safely.scroll(link, () => {
					navigate(`${window.location.pathname}${link}`);
				});
			}
		}
	};

	let options = Array.isArray(results) ? [...results] : [];

	// Limit results
	let limitedOptions = options && options.length > 0 && maxResults ? options.slice(0, maxResults) : options;

	// Add "Show More" -link as last item if more results than maxResults to list
	if (
		showMoreLink &&
		typeof maxResults === 'number' &&
		(options.length > maxResults || (total && total > options.length))
	) {
		let count = total || options.length;
		if (limitedOptions.length > 0) {
			limitedOptions.push({
				showMoreLink: true,
				title: intl.formatMessage({ id: 'component.search-input.viewAllResults' }, { count: count }),
				description: '',
				icon: 'arrow right',
				link: `${showMoreLink}?q=${currentValue || ''}`
			});
		}
	}

	// Format options
	limitedOptions.forEach((option, index) => {
		if (option.link) {
			limitedOptions[index] = {
				...option,
				as: 'a',
				href: option.link
			};
		}
	});

	// Set loading
	let isLoading = Boolean(loading && !disabled && !locked && currentValue && currentValue.length >= minCharacters);

	// Field clear button
	const clearButton =
		clearable && !isLoading ? (
			<Button
				icon='close'
				circular
				size={Size.ExtraMini}
				hidden={!currentValue || disabled || locked}
				onClick={handleClear}
			/>
		) : null;

	// Field submit button
	const submitButton = (
		<Button
			type='submit'
			icon='search'
			ariaLabel='Search'
			circular={false}
			loading={isLoading}
			disabled={disabled || locked}
			size={size}
			onClick={handleSubmit}
		/>
	);

	// Search field
	let search = (
		<div
			className={classNames('search-wrapper', {
				fluid: fluid,
				'has-button': submitUrl
			})}
			ref={searchWrapperRef}
		>
			<div>{clearButton}</div>
			<SUIRSearch
				type='search'
				ref={ref}
				name={name}
				data-default={defaultValue}
				value={currentValue}
				placeholder={placeholder || intl.formatMessage({ id: 'component.search-input.placeholder' })}
				className={classNames({
					locked: locked
				})}
				minCharacters={minCharacters}
				fluid={fluid}
				input={{
					fluid: fluid,
					action: submitUrl ? submitButton : null
				}}
				icon={submitUrl ? null : 'search'}
				loading={isLoading}
				size={size}
				disabled={disabled || locked}
				results={limitedOptions ? limitedOptions : undefined}
				noResultsMessage={noResultsMessage}
				resultRenderer={(rendererProps) => (
					<SearchResult
						key={rendererProps.id}
						title={rendererProps.title}
						description={rendererProps.description}
						icon={rendererProps.icon}
						link={rendererProps.link}
						showMoreLink={rendererProps.showMoreLink}
						testID={rendererProps.testID || `Search::SearchResult::${rendererProps.id}`}
						onClick={handleClick}
					/>
				)}
				showNoResults={results !== false && !loading}
				data-testid={testID}
				onFocus={handleFocus}
				onSearchChange={handleChange}
				onResultSelect={handleSelect}
				onKeyPress={handleSubmit}
				onClick={(e) => e.stopPropagation()} // Make it also work in popups and dropdowns
			/>
		</div>
	);

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, search);
}

export default (React.memo(Search, EQUALS): React.AbstractComponent<React.PropsOf<Search>, mixed>);
