/**
 * @prettier
 * @flow
 */

import { ListList as SUIRList } from 'semantic-ui-react';
import ListItem from './ListItem';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/list/ */
component ListList(
	/** Array of strings, React Intl components or prop objects for ListItem. */
	items: Array<React.Node> | Array<React.PropsOf<ListItem>>
) {
	return items ? (
		<SUIRList>
			{items && Array.isArray(items)
				? items.map((item, index) =>
						typeof item === 'string' || React.isValidElement(item) ? (
							// $FlowIssue[incompatible-type] - Flow doesn't recogonize React.isValidElement
							<ListItem key={index} content={item} />
						) : (
							// $FlowFixMe - Can't determine spread props
							<ListItem key={index} {...item} />
						)
					)
				: null}
		</SUIRList>
	) : null;
}

export default (React.memo(ListList): React.AbstractComponent<React.PropsOf<ListList>, mixed>);
