/**
 * @prettier
 * @flow
 */
import { ListDescription as SUIRListDescription } from 'semantic-ui-react';

component ListDescription(
	/** Detail as children. */
	children: React.Node
) {
	return <SUIRListDescription>{children}</SUIRListDescription>;
}

export default (React.memo(ListDescription): React.AbstractComponent<React.PropsOf<ListDescription>, mixed>);
