/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import { filter, escapeRegExp } from 'lodash';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, Segment, Popup, Label } from 'liana-ui/components';
import { Size } from 'liana-ui/types';

// Component render conditions. True if should not render.
const EQUALS = (prevProps: React.PropsOf<Textarea>, nextProps: React.PropsOf<Textarea>) =>
	prevProps.value === nextProps.value &&
	prevProps.disabled === nextProps.disabled &&
	prevProps.locked === nextProps.locked &&
	prevProps.height === nextProps.height;

type Option = {
	tag: string,
	description: string,
	header: string,
	disabled?: boolean,
	label?: string
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/addons/text-area/ */
component Textarea(
	/** Forwarded reference */
	ref: React.RefSetter<HTMLElement>,
	/** A textarea must have an input name */
	name: string,
	/** Initial value for input. Use for uncontrolled components only. */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. */
	value?: string,
	/** An input can have a placeholder text. Use intl.formatMessage() for translated strings. */
	placeholder?: string,
	/** A textarea can have initial rows set. VALUES[1, 2, 3, 4, ...] */
	rows: number = 6,
	/** A textarea can have height set in pixes */
	height?: number,
	/** A textare can limit possible characters to maximum amount. */
	maxLength?: number,
	/** An textarea can be locked to indicate that the field is in use but can not be edited. */
	locked: boolean = false,
	/** A textarea can be locked. */
	disabled: boolean = false,
	/** A textarea can be read only. */
	readOnly: boolean = false,
	/** An textarea can be different size. */
	size?: 'small' | 'large',
	/** A textarea can have autocomplete placeholder tags. */
	autocomplete?: {
		trigger: string,
		options: Array<Option>,
		translations?: {
			noResults?: string
		},
		onSearch: string
	},
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID used for testing. */
	testID: string = 'Textarea',
	/** Function called on input focus. */
	onFocus?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void,
	/** Function called on input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void
) {
	// Count amount of characters
	const getLength = (val: any) => (typeof val === 'string' ? val.length : 0);

	// Internal states
	let [search, setSearch] = useState();
	let [internalValue, setInternalValue] = useState(defaultValue);
	let val = value === undefined ? internalValue : value;
	let defaultVal = defaultValue;

	const filterOptions = (rawOptions: Array<Option>, search: string) => {
		const re = new RegExp(escapeRegExp(search && search.toLowerCase()), 'i');
		const isMatch = (result: Option) => re.test(result.tag) || re.test(result.description);
		let filteredOptions = filter(rawOptions, isMatch);
		return filteredOptions.every((el) => el.header) ? [] : filteredOptions;
	};

	// Limit value to maxLength
	if (maxLength && getLength(val) > maxLength && typeof val === 'string') {
		val = val.substring(0, maxLength);
	}

	// Called on input focus
	const handleFocus = (event: SyntheticInputEvent<>) => {
		if (typeof onFocus === 'function') {
			onFocus(event, handleCallbackData({ value: event.target.value }));
		}
	};

	// Called on input change.
	const handleChange = (event: SyntheticEvent<>, data: any) => {
		// Set current value internally
		setInternalValue(data.value);

		// Trigger onChange callback with formatted data
		if (typeof onChange === 'function') {
			onChange(event, handleCallbackData(data));
		}

		// Trigger Form onChange on value change
		if (typeof event === 'object' && event.target && 'dispatchEvent' in event.target) {
			event.target.dispatchEvent(new Event('change', { bubbles: true }));
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => ({
		name: name,
		value: data.value
	});

	const wrapperClasses = classNames(
		'textarea',
		{
			'has-counter': maxLength && !disabled && !locked
		},
		size
	);

	const textareaClasses = classNames({
		disabled: disabled,
		locked: locked
	});

	const listClasses = classNames('ui vertical menu compact tiny', {
		filtered: search
	});

	if (val && defaultVal) {
		defaultVal = undefined; // Having both will render defaultValue as textContent
	}

	const style = height ? { height: `${height}px` } : {};

	let textarea = (
		<div className={wrapperClasses} data-default={defaultVal} data-testid={testID}>
			<ReactTextareaAutocomplete
				// $FlowIssue[prop-missing] - Can't reproduce this issue elsewhere
				innerRef={(element) => (ref ? (ref.current = element) : null)}
				name={name}
				style={style}
				className={textareaClasses}
				listClassName={listClasses}
				placeholder={placeholder}
				value={val}
				rows={rows}
				maxLength={maxLength}
				disabled={disabled}
				readOnly={readOnly || locked}
				tabIndex={readOnly || locked ? -1 : undefined}
				onFocus={handleFocus}
				onChange={(event) => handleChange(event, { value: event.target.value })}
				minChar={0}
				movePopupAsYouType
				loadingComponent={() => <span>Loading</span>}
				trigger={{
					// $FlowIssue[invalid-computed-prop] - Flow can't handle computed props
					[autocomplete?.trigger]: {
						dataProvider: (token) => {
							const noOptions = [{ no_results: true }];
							if (autocomplete) {
								const filteredOptions = filterOptions(autocomplete.options, token);
								setSearch(token);
								if (autocomplete && typeof autocomplete.onSearch === 'function') {
									autocomplete.onSearch(token);
								}
								return filteredOptions.length ? filteredOptions : noOptions;
							}

							return noOptions;
						},
						component: ({ entity: { tag, description, header, disabled, label, no_results } }) => {
							if (no_results) {
								return (
									<a className='item no-results cursor-default'>
										<Text as='div' color='grey'>
											{autocomplete?.translations?.noResults || (
												<FormattedMessage id='component.search-input.noResultsFound' />
											)}
										</Text>
									</a>
								);
							}

							return header ? (
								<div className='item header-wrapper text-uppercase'>
									<div className='header'>{header}</div>
								</div>
							) : (
								<a
									className={classNames('item', {
										disabled
									})}
								>
									<Text as='div'>
										{tag} {label ? <Label text={label} /> : null}
									</Text>
									{description ? (
										<Text as='div' color='grey' size={Size.Small}>
											{description}
										</Text>
									) : null}
								</a>
							);
						},
						output: (item) => {
							return !item.disabled && !item.no_results ? item.tag || item.header : '';
						}
					}
				}}
			/>
			{maxLength && !disabled && !locked ? (
				<Segment className='max-length-container' size={Size.Tiny} raised compressed='very'>
					&nbsp;&nbsp;
					<FormattedMessage id='component.textarea.characters' />:{' '}
					<Text bolder color={getLength(val) >= maxLength ? 'red' : undefined}>
						{getLength(val) + '/' + maxLength.toString()}
					</Text>
				</Segment>
			) : null}
		</div>
	);

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, textarea);
}

export default (React.memo(Textarea, EQUALS): React.AbstractComponent<React.PropsOf<Textarea>, mixed>);
