/**
 * @prettier
 * @flow
 */

import { Segment, Card, Grid, Item, GridColumn } from 'liana-ui/components/';
import { Spacing } from 'liana-ui/types';

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/card/#types-groups */
component CardGroup(
	/** A card group must have card items. */
	items: Array<React.PropsOf<Card>>,
	/** A card group can have different layouts. */
	layout?: 'small' | 'big' = 'big',
	/** 
			  A card can limit header to maximum amount of lines with ellipsis. 
			 VALUES[1 - 10]
		*/
	limitHeader?: number,
	/** 
			A card can limit description to maximum amount of lines with ellipsis. 
			VALUES[1 - 10]			
		*/
	limitDescription?: number,
	/**
			Represents column count per row in card Grid.
			VALUES['equal' | 1 - 12]
		*/
	columns?: string | number = 4,
	/**
		Represents column count per row for device in card Grid.
		VALUES[{ mobile: 1 - 12, tablet: 1 - 12, computer: 1 - 12, largeScreen: 1 - 12, wideScreen: 1 - 12, hugeScreen: 1 - 12, massiveScreen: 1 - 12 }]
	*/
	responsiveColumns?: Object,
	/**
			A column can specify a width for a mobile device.
			VALUES[1 - 12]
		*/
	mobile?: number,
	/**
			A column can specify a width for a tablet device.
			VALUES[1 - 12]
		*/
	tablet?: number,
	/**
			A column can specify a width for a computer.
			VALUES[1 - 12]
		*/
	computer?: number,
	/**
			A column can specify a width for a large screen device.
			VALUES[1 - 12]
		*/
	largescreen?: number,
	/**
			A column can specify a width for a wide screen device.
			VALUES[1 - 12]
		*/
	widescreen?: number,
	/** Test ID for testing */
	testID: string = 'CardGroup',
	/** Mapped over any data provided for items property. Should return Card object for items. */
	renderItem?: (data: any, index: number) => React.PropsOf<Item>,
	/** Function called on item select. */
	onSelect?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			checked: boolean
		}
	) => void
) {
	// Factory function for transforming data
	let renderItems: any = [];
	if (items && items.length > 0) {
		if (typeof renderItem === 'function') {
			for (let i = 0; i < items.length; i++) {
				renderItems[i] = renderItem(items[i], i);
			}
		} else {
			renderItems = items;
		}
	}

	return (
		<Segment basic removePaddings={Spacing.All} testID={testID}>
			<Grid
				stretched
				doubling={!responsiveColumns && !mobile && !tablet && !computer && !largescreen && !widescreen}
				stackable={!responsiveColumns && !mobile && !tablet && !computer && !largescreen && !widescreen}
				columns={columns}
				responsiveColumns={responsiveColumns}
			>
				{renderItems.map((item: React.PropsOf<Card> | React.PropsOf<Item>, index: number) => {
					return (
						<GridColumn
							mobile={mobile}
							tablet={tablet}
							computer={computer}
							largescreen={largescreen}
							widescreen={widescreen}
							key={`cardcolumn-${index}`}
						>
							{/** $FlowIgnore - Flow can't handle spread attributes */}
							<Card
								key={`card-${index}`}
								fluid
								id={item.id}
								layout={layout}
								limitHeader={limitHeader}
								limitDescription={limitDescription}
								onSelect={onSelect}
								testID={item.testID || `CardGroup::Card::${index}`}
								{...item}
							/>
						</GridColumn>
					);
				})}
			</Grid>
		</Segment>
	);
}

export default (React.memo(CardGroup): React.AbstractComponent<React.PropsOf<CardGroup>, mixed>);
