/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { AccordionContent as SUIRAccordionContent } from 'semantic-ui-react';
import AccordionAccordion from './AccordionAccordion';
import { Transition } from 'liana-ui/components/';
import type { Panel } from '../Accordion';

component AccordionContent(
	/** Primary content. */
	children?: React.Node,
	/** An element type to render as (string or function). */
	as?: any,
	/** Index of the currently active panel. When exclusive is false, defaultActiveIndex should be an array of Strings. */
	defaultActiveIndex?: string | Array<string>,
	/** Whether or not the content is visible. */
	active?: boolean = false,
	/** Only allow one panel open at a time. */
	exclusive?: boolean = false,
	/** Required when rendered as Form.field */
	required?: boolean,
	/** Parent index.  */
	parentIndex?: string | number,
	/** Nested level.  */
	level: number = 1,
	/** Set maximum levels of accordion. */
	maxLevels?: number,
	/** Array of props for AccordionPanel. Example: [{key: 'panel1', title: 'Panel title', content: 'Panel content', panels: Array<AccordionPanelType> }] */
	panels?: Array<Panel>,
	/** Test ID used for testing. */
	testID: string = 'AccordionContent',
	/** Called on link click only. */
	onLinkClick?: (event: SyntheticEvent<>) => void,
	// Do not print out to docs. For testing purposes only
	onAnimationEnd?: (newHeight: number) => void
) {
	const handleAnimationEnd = (newHeight: number) => {
		if (typeof onAnimationEnd === 'function') {
			onAnimationEnd(newHeight);
		}
	};
	const classes = classNames({
		required,
		'last-level': maxLevels && level === maxLevels - 1
	});
	return (!maxLevels || level < maxLevels) && (children || panels) ? (
		<Transition animation='height' visible={active} onComplete={handleAnimationEnd}>
			<SUIRAccordionContent as={as} active={active} className={classes} data-testid={testID}>
				{children}
				{panels ? (
					<AccordionAccordion
						defaultActiveIndex={defaultActiveIndex}
						exclusive={exclusive}
						panels={panels}
						parentIndex={parentIndex}
						level={level}
						maxLevels={maxLevels}
						onLinkClick={onLinkClick}
						onAnimationEnd={onAnimationEnd}
					/>
				) : null}
			</SUIRAccordionContent>
		</Transition>
	) : null;
}

export default (React.memo(AccordionContent): React.AbstractComponent<React.PropsOf<AccordionContent>, mixed>);
