/**
 * @prettier
 * @flow
 */

import { Step as SUIRStep } from 'semantic-ui-react';
import StepTitle from './StepTitle';
import StepContent from './StepContent';
import StepDescription from './StepDescription';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/step/#groups-ordered */
component Step(
	/** A step can have an ID. */
	id: string,
	/** A step can have a title as text. */
	title: string,
	/** A step can have description as text. */
	description: string,
	/** A step can be highlighted as active. */
	active?: boolean = false,
	/** A step can show that a user has completed it. */
	completed?: boolean = false,
	/** Test ID for testing */
	testID: string = 'Step',
	/** Function called on click. When passed, the component will render as an `a` tag by default instead of a `div`.  */
	onClick: (
		event: SyntheticEvent<>,
		data: {
			id: string,
			active: boolean,
			completed: boolean
		}
	) => void
) {
	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => ({
		id: data.id,
		active: data.active,
		completed: data.completed
	});

	return (
		<SUIRStep
			as='div'
			id={id}
			active={active}
			completed={completed}
			className={onClick ? 'has-link' : undefined}
			data-testid={testID}
			onClick={(event, data) => onClick(event, handleCallbackData(data))}
		>
			<StepContent>
				{title ? <StepTitle text={title} /> : null}
				{description ? <StepDescription text={description} /> : null}
			</StepContent>
		</SUIRStep>
	);
}

export default (React.memo(Step): React.AbstractComponent<React.PropsOf<Step>, mixed>);
