/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import Validate from 'liana-ui/definitions/component/Validate';
import { AccordionTitle as SUIRAccordionTitle } from 'semantic-ui-react';
import { Label, LimitLabel, Icon } from 'liana-ui/components/';
import { Size } from 'liana-ui/types';

component AccordionTitle(
	/** Primary content. */
	children?: React.Node,
	/** An element type to render as */
	as?: any,
	/** Link for NavLink */
	to?: string,
	/** If link should only open toggle and not redirect */
	disableLink?: boolean,
	/** Whether or not the title is in the open state. */
	active?: boolean = false,
	/** Whether or not the title is upsell feature. */
	upsell?: boolean = false,
	/** AccordionTitle index inside Accordion. */
	index: string | number,
	/** Shorthand for Icon. */
	icon: string | boolean | React.PropsOf<Icon> = 'fa-chevron-right fa-solid',
	/** Array of Label props objects. Eq. { text: 'Text', icon: 'folder' } */
	labels?: Array<React.PropsOf<Label> | React.PropsOf<LimitLabel>>,
	/** Accordion title can vary in size. */
	size?: 'large' | 'big',
	/* Accordion title can have additional classes. */
	className?: string,
	/** Test ID used for testing. */
	testID: string = 'AccordionTitle',
	/** Called on any click. */
	onClick?: (event: SyntheticEvent<>, data: any) => void,
	/** Called on link click only. */
	onLinkClick?: (event: SyntheticEvent<>) => void
) {
	// Get link type
	let linkType = Validate.linkType(to);

	const classes = classNames(
		className,
		{
			'last-link': !disableLink
		},
		size
	);

	// Space
	let content: React.Node = labels ? (
		<span className='text'>
			{children} {/* $FlowFixMe[prop-missing] - Can't fix this yet; Need to move away from dot notation */}
			<Label.Group labels={labels} />
		</span>
	) : (
		<span className='text'>{children}</span>
	);

	if (upsell) {
		content = (
			<>
				{content}{' '}
				<span className='upsell-icon'>
					<Icon squared fitted name='fa-gem' color='purple' size={Size.Small} />
				</span>
			</>
		);
	}

	// $FlowFixMe - Unknown data parameter
	const handleClick = (event: SyntheticEvent<>, data: any) => {
		if (disableLink) {
			event.preventDefault();
		}
		if (typeof onClick === 'function') {
			onClick(event, data);
		}
		if (typeof onLinkClick === 'function' && !disableLink && to) {
			onLinkClick(event);
		}
	};

	return (
		<SUIRAccordionTitle
			as={linkType === 'external' ? 'a' : as}
			to={linkType === 'internal' ? to : undefined}
			href={linkType === 'external' ? to : undefined}
			target={linkType === 'external' ? '_blank' : undefined}
			rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
			activeClassName={to ? 'current' : undefined}
			active={active}
			index={index}
			content={content}
			icon={icon}
			className={classes}
			onClick={handleClick}
			data-testid={testID}
		/>
	);
}

export default (React.memo(AccordionTitle): React.AbstractComponent<React.PropsOf<AccordionTitle>, mixed>);
