/**
 * @prettier
 * @flow
 */

import { ModalHeader as SUIRModalHeader } from 'semantic-ui-react';

component ModalHeader(children: React.Node, testID: string = 'ModalHeader') {
	return (
		<SUIRModalHeader data-testid={testID} className='overflow-ellipsis'>
			{children}
		</SUIRModalHeader>
	);
}

export default (React.memo(ModalHeader): React.AbstractComponent<React.PropsOf<ModalHeader>, mixed>);
