/**
 * @prettier
 * @flow
 */

import { Statistic } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/statistic */
component StatisticLabel(
	/** Statistic label as text. */
	content: React.Node,
	/** Test ID for testing. */
	testID: string = 'StatisticLabel'
) {
	return <Statistic.Label data-testid={testID}>{content}</Statistic.Label>;
}

export default (React.memo(StatisticLabel): React.AbstractComponent<React.PropsOf<StatisticLabel>, mixed>);
