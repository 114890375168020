/**
 * @prettier
 * @flow
 */

import { List as SUIRList } from 'semantic-ui-react';
import { Size, Float } from 'liana-ui/types';
import ListItem from './src/ListItem';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/list/ */
component List(
	/**
		A list must have items as array of strings, rect-intl components or props object for items.
		PROPS[Icon=/components/labels/icons/icon/, Image=/components/texts/image/, Popup=/components/modals/popup/, React.Node=/language/localisation/]
	*/
	items?: Array<React.Node | React.PropsOf<ListItem>>,
	/* A list can have content as children as alternative to items property. */
	children?: React.Node,
	/** A list can mark items with a bullet. */
	bulleted?: boolean = false,
	/** A list can be ordered numerically. */
	ordered?: boolean = false,
	/** A list can show divisions between content. */
	divided?: boolean = false,
	/** A list can divide its items into cells. */
	celled?: boolean = false,
	/** A list can relax its padding to provide more negative space. */
	relaxed?: boolean | 'very' = false,
	/** A list can be formatted to have items appear horizontally. */
	horizontal?: boolean = false,
	/** A list can be floated left or right. */
	floated?: Float,
	/** A list can vary in size. */
	size?: Size,
	/** Test ID for testing */
	testID: string = 'List'
) {
	const getItems = () => {
		return items && Array.isArray(items)
			? items.map((item, index) => {
					if (typeof item === 'string' || React.isValidElement(item)) {
						// $FlowIssue[incompatible-type] - Impossible type refinement
						return <ListItem key={index} testID={`List::ListItem::${index}`} content={item} />;
					} else if (item && typeof item === 'object') {
						// $FlowFixMe[cannot-spread-interface] - Can't determine spread props
						return <ListItem key={index} testID={item.testID || `List::ListItem::${index}`} {...item} />;
					}
				})
			: null;
	};

	let list =
		items || children ? (
			<SUIRList
				bulleted={bulleted}
				ordered={ordered}
				divided={divided}
				celled={celled && !divided}
				relaxed={relaxed}
				horizontal={horizontal}
				floated={floated}
				size={size}
				data-testid={testID}
			>
				{items ? getItems() : children}
			</SUIRList>
		) : null;

	return list;
}

export default (React.memo(List): React.AbstractComponent<React.PropsOf<List>, mixed>);
