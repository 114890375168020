/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Loader } from 'liana-ui/components/';
import { ModalContent as SUIRModalContent } from 'semantic-ui-react';

component ModalContent(
	/**  Primary content as children. */
	children: React.Node,
	/** Remove paddings around the scontent. */
	removePaddings?: boolean,
	/** A modal content can center its text alignment. */
	textAlign?: 'center',
	/** Controls whether or not the ModalContant is displayed as loading. */
	loading?: boolean,
	/** Additional classes. */
	className?: string,
	/** Test ID for testing. */
	testID: string = 'ModalContent'
) {
	// Assign classes
	const classes = classNames(
		{
			'remove-paddings': removePaddings,
			'text-center': textAlign === 'center'
		},
		className
	);

	return (
		<SUIRModalContent data-testid={testID} className={classes}>
			{loading ? <Loader active={loading} dimmer /> : null}
			{children}
		</SUIRModalContent>
	);
}

export default (React.memo(ModalContent): React.AbstractComponent<React.PropsOf<ModalContent>, mixed>);
