/**
 * @prettier
 * @flow
 */

import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import NotificationMenu from './src/NotificationMenu';
import SupportMenu from './src/SupportMenu';
import ApplicationMenu from './src/ApplicationMenu';
import UserMenu from './src/UserMenu';
import NavigationMenu from './src/NavigationMenu';
import { Image, Label, Responsive, FeedbackModal } from 'liana-ui/components/';
import { Size, Device } from 'liana-ui/types';
import type { Page } from 'liana-ui/components/navigation/Navigation';

type Organization = {
	name?: string,
	id?: string,
	section?: number | null,
	image?: string,
	links?: {
		manageMenu: string,
		addSolution: string
	},
	solutions?: Array<{
		name: string,
		additionalName: string,
		id: string,
		applications: Array<{
			name: string,
			id: string,
			image: string,
			url: string
		}>
	}>
};

type User = {
	id?: string,
	selectedOrganization?: string,
	selectedSolution?: string,
	name?: string,
	email?: string,
	image?: string,
	organizations?: Array<Organization>,
	links?: {
		userSettings: string,
		organizationSettings: string,
		logout: string,
		privacyPolicy: string,
		termsOfService: string
	},
	...
};

type Notification = {
	title?: string,
	id?: string,
	publish_time?: string,
	viewed?: boolean,
	summary?: string,
	content?: string,
	link?: string
};

// Hack to get around broken SUIR event stack for Web Components
// https://github.com/Semantic-Org/Semantic-UI-React/issues/4184
let UNSAFE_outsideClickListener: ?(event: Event) => void;

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
component TopPanel(
	/** A top panel must have an application logo. */
	logo?: string,
	/** A top panel must have an application name. */
	appName?: string,
	/* Depracated. Remove ones not in use anymore! */
	siteUrl?: string,
	/** Redirect to a given link instead of root when clicking on logo */
	appUrl?: string,
	/** A top panel must have user data for user menu. DATA[json/user/user.json] */
	user: User,
	/** Navigation menu should be the same as navigation for main navigation in SidePanel on desktop. DATA[json/navigation/navigation.json] */
	navigation?: Array<Page>,
	/** A top panel can have tools for mobile navigation menu. Should be the same as tools for main navigation in SidePanel on desktop. */
	tools?: React.Node,
	/** A top panel can have a notification menu. DATA[json/notifications/notifications.json] */
	notifications?: Array<Notification>,
	/** A top panel can have a support menu. DATA[json/support/support.json] */
	support?: {
		site: {
			active: boolean,
			link: boolean
		},
		chat: {
			active: boolean
		},
		knowledgeBase: {
			active: boolean
		},
		feedback: {
			active: boolean
		}
	},
	/** A top panel can have an application menu. DATA[json/applications/applications.json] */
	applications?: {
		organizations: Array<Organization>,
		links: { manageMenu: string, addSolution: boolean }
	},
	/** Navigation translations will be treated as plain text instead of trying to translate them */
	useRawTranslations: boolean = false,
	/* Set TopPanel to  web component mode for non LianaUI apps. Affect links etc. */
	webComponent?: boolean = false,
	/** Test ID used for testing. */
	testID: string = 'TopPanel',
	/** Function called on feedback submit. */
	onFeedback?: (data: any, done: () => void) => void,
	/** Function that enables organization change in user menu and is called on organization change. NOTICE! Should only be used in LianaAccount app. */
	onOrganizationChange?: (customerId: string) => void,
	/** Function called on clicking mobile menu links */
	onLinkClick?: () => void,
	/** Function called on logout click in user menu. */
	onLogout?: (logoutUrl: ?string) => void,
	/** Function called on any top panel menu open. */
	onOpenMenu?: (
		event: SyntheticEvent<>,
		data: {
			id: string
		}
	) => void,
	/** Function called on any top panel menu open. */
	onCloseMenu?: (
		event: Event,
		data: {
			id: string
		}
	) => void
) {
	const [open, setOpen] = useState(false);
	const [mounted, setMounted] = useState(false);
	const [style, setStyle]: [{ [string]: string | number }, any] = useState({});
	const [feedbackOpen, showFeedback] = useState(false);

	const ref = useRef<HTMLElement | null>(null);
	const modalMount = useRef<HTMLDivElement | null>(null);
	const popupMount = useRef<HTMLDivElement | null>(null);

	const removeClickListener = () => {
		if (UNSAFE_outsideClickListener) {
			document.removeEventListener('click', UNSAFE_outsideClickListener);
			UNSAFE_outsideClickListener = undefined;
		}
	};

	useEffect(() => setMounted(true), []);

	const POPUP_SETTINGS = {
		// $FlowIgnore[react-rule-unsafe-ref] - undefined is safe to use
		mountNode: modalMount.current,
		size: Size.Tiny
	};

	const getSelectedSolution = () => {
		if (
			!user ||
			!applications ||
			!applications.organizations ||
			!user.selectedOrganization ||
			!user.selectedSolution
		) {
			return null;
		}

		let organization = applications.organizations.filter(
			(organization) => user.selectedOrganization === organization.id
		)[0];

		let solution = organization.solutions?.filter((solution) => user.selectedSolution === solution.id)[0];

		return solution;
	};

	const openFeedback = () => showFeedback(true);
	const hideFeedback = () => showFeedback(false);

	const handleOpenMenu = (event: SyntheticEvent<>, data: any) => {
		const list = document.querySelector('html')?.classList,
			isMobile = list?.contains('mobile') || list?.contains('tablet'),
			dropdown = ref.current?.querySelector(`#${data.id}`),
			menu = ref.current?.querySelector(`#${data.id.replace('Menu', 'Dropdown')}`);
		let viewportOffset = 0,
			dropdownOffset = 0;

		if (isMobile) {
			let content = document.getElementById('app-content');
			if (content) {
				content.style.height = window.innerHeight + 'px';
				content.style.overflow = 'hidden';
			}
			viewportOffset = parseInt(document.documentElement?.scrollHeight, 10);
			dropdownOffset = parseInt(dropdown?.getBoundingClientRect().height, 10);
			setStyle({
				width: `${document.documentElement?.clientWidth || '0'}px`,
				left: `-${dropdown?.getBoundingClientRect().x || '0'}px`,
				maxHeight: `${viewportOffset - dropdownOffset}px`,
				overflowY: 'auto'
			});
		} else {
			viewportOffset = parseInt(document.documentElement?.clientWidth, 10);
			dropdownOffset = parseInt(dropdown?.getBoundingClientRect().x, 10);
			setStyle({
				width: `${viewportOffset - dropdownOffset}px`,
				maxHeight: `${window.innerHeight - menu?.getBoundingClientRect().top}px`,
				overflowY: 'auto'
			});
		}

		if (!UNSAFE_outsideClickListener) {
			UNSAFE_outsideClickListener = (event: Event) => {
				event.stopImmediatePropagation(); // Prevent event queue / memory leak
				// Web Components: https://developer.mozilla.org/en-US/docs/Web/API/Event/composedPath
				let path = event.composedPath();
				// $FlowIgnore - Node is close enough to EventTarget here
				if (!dropdown?.contains(path[0])) {
					handleCloseMenu(event, data);
				}
			};
			document.addEventListener('click', UNSAFE_outsideClickListener);
		}
		setOpen(data.id);
		if (typeof onOpenMenu === 'function') {
			onOpenMenu(event, data);
		}
	};

	const handleCloseMenu = (event: Event, data: any) => {
		let content = document.getElementById('app-content');
		if (content) {
			content.style.left = 'auto';
			content.style.maxHeight = 'none';
			content.style.overflow = 'unset';
		}
		if (data && typeof onCloseMenu === 'function') {
			onCloseMenu(event, data);
		}
		removeClickListener();
		setOpen(false);
	};

	// Triggers on successful validation
	const handleSubmitFeedbackModal = (data: any, done: () => void) => {
		if (typeof onFeedback === 'function') {
			onFeedback(data, () => {
				done();
				showFeedback(false);
			});
		} else {
			showFeedback(false);
		}
	};

	// Get app logo and link
	const getLogo = () => {
		let ret = null,
			url = appUrl || siteUrl,
			image = (
				<Image
					src={logo}
					alt={appName ? appName : 'Logo'}
					squared
					maxHeight='2.2rem'
					lazyLoad={false}
					ratio='300/89'
					style={{ margin: '1em 1.42857143em' }}
				/>
			);
		if (logo) {
			ret = webComponent ? (
				<a className='site-logo' href={url ? url : '/'}>
					{image}
				</a>
			) : (
				<Link className='site-logo' to={url ? url : '/'}>
					{image}
				</Link>
			);
		}
		return ret;
	};

	let selectedSolution = getSelectedSolution();

	// Note: Modal/popup containers need to properly mount before rendering anything relying on them
	let panel = (
		<header className='top-panel clearfix' id='top-panel' ref={ref} data-testid={testID}>
			{getLogo()}
			{logo && selectedSolution?.additionalName ? (
				<Responsive minDevice={Device.Tablet}>
					<div className='additional-name'>
						<Label limitCharacters={40} text={selectedSolution.additionalName} />
					</div>
				</Responsive>
			) : null}
			{mounted ? (
				<Menu floated='right'>
					<NotificationMenu
						open={open === 'notificationMenu'}
						notifications={notifications}
						style={style}
						popup={POPUP_SETTINGS}
						onOpen={handleOpenMenu}
						onClose={handleCloseMenu}
					/>
					<SupportMenu
						open={open === 'supportMenu'}
						support={support}
						style={style}
						popup={POPUP_SETTINGS}
						onFeedbackOpen={openFeedback}
						onOpen={handleOpenMenu}
						onClose={handleCloseMenu}
					/>
					<ApplicationMenu
						open={open === 'applicationMenu'}
						user={user}
						applications={applications}
						style={style}
						popup={POPUP_SETTINGS}
						onOpen={handleOpenMenu}
						onClose={handleCloseMenu}
					/>
					<UserMenu
						open={open === 'userMenu'}
						user={user}
						style={style}
						popup={POPUP_SETTINGS}
						onOpen={handleOpenMenu}
						onClose={handleCloseMenu}
						onOrganizationChange={onOrganizationChange}
						onLogout={onLogout}
					/>
					<Responsive maxDevice={Device.Tablet}>
						<NavigationMenu
							open={open === 'navigationMenu'}
							tools={tools}
							navigation={navigation}
							popup={POPUP_SETTINGS}
							style={style}
							raw={useRawTranslations}
							onLinkClick={onLinkClick}
							onOpen={handleOpenMenu}
							// $FlowIssue - Event vs. SyntheticEvent
							onClose={handleCloseMenu}
						/>
					</Responsive>
				</Menu>
			) : null}
			<div id='modal-mount' ref={modalMount} />
			<div id='popup-mount' ref={popupMount} />
			<div id='kc-bot-launcher' />
			<FeedbackModal
				open={feedbackOpen}
				onSubmit={handleSubmitFeedbackModal}
				onClose={hideFeedback}
				// $FlowIgnore[react-rule-unsafe-ref] - undefined is safe to use
				mountNode={modalMount.current}
			/>
		</header>
	);

	return panel;
}

export type { User, Organization, Notification };

export default (React.memo(TopPanel): React.AbstractComponent<React.PropsOf<TopPanel>, mixed>);
