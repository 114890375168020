/**
 * @prettier
 * @flow
 */

import { Segment, Statistic, Table, StatisticTrend, ListItem } from 'liana-ui/components/';
import { Spacing, HAlign, VAlign, Size } from 'liana-ui/types/';

component ChartInfo(
	/** Main statistic number */
	value?: number,
	/** Label for main statistic number. */
	label?: React.Node,
	/** Notation of main statistic number. */
	notation?: 'compact' | 'percentage',
	/** Array of prop objects for ListItem. */
	items: Array<{ text: string, ...React.PropsOf<ListItem> }> = [],
	/** Animation duration in seconds. */
	duration?: number,
	/** Test ID for testing */
	testID: string = 'ChartInfo'
) {
	return (
		<Segment removeMargins={Spacing.All} testID={testID}>
			<div className='chart-info'>
				{value !== undefined ? (
					<Statistic fluid value={value} label={label} notation={notation} duration={duration} />
				) : null}
				<Table
					size={Size.Small}
					padded
					basic
					fitted
					definition
					bodyRows={items.map((item, idx) => ({
						id: String(idx),
						cells: [
							{
								content: item.text
							},
							{
								textAlign: HAlign.Right,
								verticalAlign: VAlign.Top,
								collapsing: true,
								// $FlowFixMe - Spread props leak
								content: <StatisticTrend {...item} />
							}
						]
					}))}
				/>
			</div>
		</Segment>
	);
}

export default (React.memo(ChartInfo): React.AbstractComponent<React.PropsOf<ChartInfo>, mixed>);
