/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useState } from 'react';
import { handleRef } from 'liana-ui/definitions';
import { Input as SUIRInput } from 'semantic-ui-react';
import { Flag, Text, Label, Button, Icon, Popup } from 'liana-ui/components/';
import { Size } from 'liana-ui/types/';

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/input */
component Input(
	/** Forwarded React ref */
	ref: React.RefSetter<React.Node>,
	/** An input must have an input name. */
	name?: string,
	/** An input can have diffrent HTML input type. */
	type: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'hidden' = 'text',
	/** Initial value for input. Use for uncontrolled components only. */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. */
	value?: string,
	/**
		An input can have a placeholder text.
		PROPS[React.Node=/localization/]
	*/
	placeholder?: React.Node,
	/** An input can have a minimum value for number type inputs. */
	min?: number,
	/** An input can have a maximum value for number type inputs. */
	max?: number,
	/** An input can have an icon. */
	icon?: string,
	/** An input can have a flag. */
	flag?: string,
	/** An icon or flag can appear inside an input on the left or right. */
	iconPosition?: 'left' | 'right' = 'left',
	/** An input can have a label. */
	label?: React.Node,
	/** An label can appear inside an input on the left or right. */
	labelPosition?: 'left' | 'right' = 'left',
	/**
		An input can have a action element. You provide Button props or any other element.
		PROPS[Button=/components/buttons/button/]
	*/
	action?: React.Node | React.PropsOf<Button>,
	/** An input can limit possible characters to maximum amount. */
	maxLength?: number,
	/** An input can take on the size of its container. */
	fluid: boolean = false,
	/** An input can be locked to indicate that the field is in use but can not be edited. Use `input` to lock only input field but not action button. */
	locked: boolean | 'input' = false,
	/** An input can be  disabled. */
	disabled: boolean = false,
	/** An input can be be read only. */
	readOnly: boolean = false,
	/** An input can be different size. */
	size?: Size,
	/**
		Popup text or, react-intl component or object of properties for Popup component.
		PROPS[React.Node=/language/localisation/, Popup=/components/modals/popup/]
	*/
	popup?: React.Node | React.PropsOf<Popup>,
	/** Test ID for testing */
	testID: string = 'Input',
	/** Function called on input focus. */
	onFocus?: (
		event: SyntheticEvent<>,
		data: {
			name?: string,
			value: string
		}
	) => void,
	/** Function called on input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name?: string,
			value: string
		}
	) => void
) {
	// Count amount of charachters
	const getLength = (val?: string): number => (typeof val === 'string' ? val.length : 0);

	// Internal states
	let [internalValue, setInternalValue] = useState(defaultValue || '');
	let currentValue = value === undefined ? internalValue : value;

	// Limit value to maxLength
	if (typeof maxLength === 'number' && getLength(currentValue) > maxLength) {
		currentValue = currentValue.substring(0, maxLength);
	}

	// Called on input focus.
	const handleFocus = (event: SyntheticInputEvent<>) => {
		if (typeof onFocus === 'function') {
			onFocus(event, handleCallbackData({ value: event.target.value }));
		}
	};

	// Called on input change.
	const handleChange = (event: SyntheticInputEvent<>, data: any) => {
		// Set current value internally
		setInternalValue(data.value);

		// Trigger onChange callback with formatted data
		if (typeof onChange === 'function') {
			onChange(event, handleCallbackData(data));
		}

		// Trigger Form onChange on value change
		if (typeof event === 'object' && event.target && 'dispatchEvent' in event.target) {
			event.target.dispatchEvent(new Event('change', { bubbles: true }));
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => {
		return {
			name: name,
			value: data.value
		};
	};

	// Assign classes
	let classes = classNames({
		locked: locked === true,
		hidden: type === 'hidden',
		limited: maxLength,
		'input-locked': locked === 'input'
	});

	// Field icon or flag
	let fieldIcon;
	if (icon) {
		fieldIcon = <Icon name={icon} />;
	} else if (flag) {
		fieldIcon = (
			<i className='icon'>
				<Flag key={flag} name={flag} />
			</i>
		);
	}

	// Field label
	let fieldLabel =
		typeof maxLength === 'number' ? (
			<Label
				key='label'
				text={
					<Text color={getLength(currentValue) >= maxLength ? 'red' : undefined}>
						{typeof label === 'string' ? label : getLength(currentValue) + '/' + maxLength.toString()}
					</Text>
				}
			/>
		) : (
			label
		);

	// Field button
	let fieldAction;
	if (action) {
		fieldAction = React.isValidElement(action) ? (
			action
		) : (
			<Button
				// $FlowIssue - Impossible type refinement
				text={action.text ? action.text : undefined}
				// $FlowIssue - Impossible type refinement
				icon={action.icon ? action.icon : undefined}
				// $FlowIssue - Impossible type refinement
				iconPosition={action.iconPosition ? action.iconPosition : undefined}
				disabled={disabled}
				// $FlowIssue - Impossible type refinement
				onClick={action.onClick ? action.onClick : () => {}}
			/>
		);
	}

	// Input field
	let input = (
		<SUIRInput
			ref={handleRef(ref, 'inputRef')}
			name={name}
			className={classes}
			value={currentValue}
			placeholder={placeholder}
			min={min}
			max={max}
			type={type}
			action={fieldAction}
			label={fieldLabel}
			icon={fieldIcon}
			iconPosition={flag || icon ? iconPosition : undefined}
			labelPosition={label || maxLength ? (maxLength ? 'right' : labelPosition) : undefined}
			maxLength={maxLength}
			fluid={fluid}
			disabled={disabled}
			readOnly={readOnly || locked}
			tabIndex={readOnly || locked ? -1 : undefined}
			size={size}
			autoComplete='off'
			onFocus={handleFocus}
			onChange={handleChange}
			data-default={defaultValue}
			data-testid={testID}
		/>
	);

	// $FlowIssue - React statics; Attach popup
	return Popup.attach(popup, input);
}

export default (React.memo(Input): React.AbstractComponent<React.PropsOf<Input>, mixed>);
