/**
 * @prettier
 * @flow
 */

import { useRef, useEffect } from 'react';
import Slider from 'rc-slider';
import raf from 'rc-util/lib/raf';
import Tooltip from 'rc-tooltip';

component HandleTooltip(value: number, visible?: boolean, unit?: string, children: React.Node) {
	const tipFormatter = () => (unit && value ? `${value} ${unit}` : value);
	const tooltipRef = useRef(null);
	const rafRef = useRef();

	const cancelKeepAlign = () => {
		raf.cancel(!rafRef.current);
	};

	const keepAlign = () => {
		rafRef.current = raf(() => {
			if (tooltipRef.current && 'forcePopupAlign' in tooltipRef.current) {
				tooltipRef.current.forcePopupAlign();
			}
		});
	};

	useEffect(() => {
		if (visible) {
			keepAlign();
		} else {
			cancelKeepAlign();
		}
		return cancelKeepAlign;
	}, [value, visible]);

	return (
		<Tooltip
			placement='top'
			overlay={tipFormatter}
			overlayInnerStyle={{ minHeight: 'auto' }}
			ref={tooltipRef}
			visible={visible}
		>
			{children}
		</Tooltip>
	);
}

type Props = {
	value?: number,
	step: number,
	min: number,
	max: number,
	unit?: string,
	dots?: string,
	disabled?: boolean,
	onChange?: (val: number) => void
};

type HandleProps = {
	value: number,
	dragging: boolean,
	unit?: string
};

component SliderTooltip(...props: Props) {
	const tipHandleRender = (node: React.Node, handleProps: HandleProps) => (
		<HandleTooltip value={handleProps.value} visible={handleProps.dragging} unit={handleProps.unit}>
			{node}
		</HandleTooltip>
	);

	return <Slider {...props} handleRender={tipHandleRender} />;
}

export default (React.memo(SliderTooltip): React.AbstractComponent<React.PropsOf<SliderTooltip>, mixed>);
