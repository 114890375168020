/**
 * @prettier
 * @flow
 */

import { Breadcrumb } from 'semantic-ui-react';

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/breadcrumb/ */
component BreadcrumbDivider(testID: string = 'BreadcrumbDivider') {
	return <Breadcrumb.Divider icon='fa-chevron-right' data-testid={testID} />;
}

export default (React.memo(BreadcrumbDivider): React.AbstractComponent<React.PropsOf<BreadcrumbDivider>, mixed>);
